import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../store';
import { loadDefaultBranch } from '../../../../state/branch/branchActions';
import { isNothing } from '../../../../common/utils';
import MasterResource from '../detail/Master';

const Connect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultBranch = useSelector(
    (state: RootState) => state.branch.active.selected
  );
  const defaultFiles = useSelector(
    (state: RootState) => state.file.active.data
  );
  const params = useParams();
  const fileId = Number(params.fileId);
  const branchId = Number(params.branchId);
  const tableId = Number(params.tableId);
  const hasFile = !isNothing(fileId);
  const hasBranch = !isNothing(branchId);
  const hasTable = hasBranch && !isNothing(tableId);

  const selectDefaultBranch = useCallback(() => {
    if (hasTable) return;
    if (hasBranch) return;
    if (isNothing(defaultBranch)) return;
    const isMaster = defaultBranch?.name === 'master';
    const branchType = !isMaster ? 'drafts' : 'masters';
    const filePath = `/files/${defaultBranch?.fileId}`;
    const branchPath = `/branches/${defaultBranch?.id}`;
    const redirectPath = `/connects/${branchType}${filePath}${branchPath}`;
    navigate(redirectPath);
  }, [navigate, defaultBranch, hasTable, hasBranch]);

  const selectDefaultFile = useCallback(() => {
    if (hasFile) return;
    if (isNothing(defaultFiles)) return;
    const defaultFile = defaultFiles[0];
    if (isNothing(defaultFile)) return;
    const filePath = `/files/${defaultFile?.id}`;
    const redirectPath = `/connects/masters${filePath}`;
    navigate(redirectPath);
  }, [navigate, defaultFiles, hasFile]);

  useEffect(() => {
    selectDefaultBranch();
  }, [selectDefaultBranch]);

  useEffect(() => {
    selectDefaultFile();
  }, [selectDefaultFile]);

  useEffect(() => {
    if (!hasFile) {
      return;
    }
    dispatch(loadDefaultBranch(fileId));
  }, [dispatch, hasFile, fileId]);

  return <MasterResource></MasterResource>;
};

export default Connect;
