import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paginate from '../../../../components/Paginate/Paginate';
import { loadDraftTables } from '../../../../state/draft-table/merge-diff/draftTableActions';
import { loadFileById } from '../../../../state/file/fileActions';
import { loadBranchById } from '../../../../state/branch/branchActions';
import { RootState } from '../../../../store';
import type DraftTableType from '../../../../models/draft-table';
import { parseDiff } from '../../../../common/utils';
import DiffText from '../../../../components/DiffText';
import './draft-table.css';

const DraftTableList = ({
  fileId,
  branchId,
  params,
  selectedRowFilter,
  onSelectTable,
}: {
  fileId: number;
  branchId: number;
  params?: { [k: string]: any };
  selectedRowFilter?: Partial<{ [k in keyof DraftTableType]: any }>;
  onSelectTable?: (selectedData: DraftTableType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const dispatch = useDispatch();
  const branchesReload = useSelector(
    (state: RootState) => state.branch.active.flags.reload
  );
  const draftTablesReload = useSelector(
    (state: RootState) => state.draftTable.active.flags.reload
  );
  const draftTables = useSelector(
    (state: RootState) => state.draftTableMergeDiff.active.data
  );
  const selectedDraftTable = useSelector(
    (state: RootState) => state.draftTableMergeDiff.active.selected
  );
  const draftTablesMeta = useSelector(
    (state: RootState) => state.draftTableMergeDiff.active.meta
  );
  const [currentPage, setCurrentPage] = useState(0);
  const handleOnSelect = (currentDraftTable: DraftTableType) => {
    onSelectTable && onSelectTable(currentDraftTable);
  };

  const scrollToSelectedElem = () => {
    const selectedElem = document.getElementById('draft-table selected');
    selectedElem?.scrollIntoView({ behavior: 'smooth' });
  };

  const handlePageClick = async (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    setCurrentPage(currentPageInx);
  };

  const handleTableName = (table: DraftTableType) => {
    const tableName = table.name;
    const tableDiff = parseDiff(tableName);
    if (!tableDiff.isDiff) return tableDiff.value;
    return (
      <>
        {table.status === 'submitted' ? (
          <span className="change-tag">{`#${table.submitId}-${table.commitId}`}</span>
        ) : null}
        <DiffText keyValue={tableDiff.value}></DiffText>
      </>
    );
  };

  useEffect(() => {
    if (branchesReload) {
      dispatch(loadBranchById(fileId, branchId));
    }
  }, [dispatch, fileId, branchId, branchesReload]);

  useEffect(() => {
    if (draftTablesReload) {
      dispatch(loadDraftTables(fileId, branchId, baseParams));
    }
  }, [dispatch, fileId, branchId, baseParams, draftTablesReload]);

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadBranchById(fileId, branchId));
    dispatch(loadDraftTables(fileId, branchId, baseParams));
    scrollToSelectedElem();
  }, [dispatch, fileId, branchId, baseParams]);

  useEffect(() => {
    scrollToSelectedElem();
  }, [dispatch, selectedDraftTable]);

  return (
    <div className="draft-table-list-container merge-diff">
      <Paginate
        totalPages={Number(draftTablesMeta?.totalPages)}
        onPageClick={handlePageClick}
        forcePage={currentPage}
      />
      <div className="table-list-container">
        <ul className="table-list">
          {draftTables.map((item, inx) => {
            const [[key, value]] = Object.entries(selectedRowFilter || {});
            const isSelected =
              item.hasOwnProperty(key) &&
              item[key as keyof DraftTableType] === value;
            const selectionTag = isSelected ? 'draft-table selected' : '';
            return (
              <li
                key={inx}
                onClick={() => handleOnSelect(item)}
                className={selectionTag}
                id={selectionTag}
              >
                <span className="table-name icon-label">
                  {handleTableName(item)}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DraftTableList;
