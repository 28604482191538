import _ from 'lodash';
import { useEffect, useReducer, useCallback } from 'react';
import {
  DataGrid,
  SelectColumn,
  EmptyDataGrid,
  textEditor,
} from '../../../../components/DataGrid/DataGrid';
import {
  GenericObjType,
  ModelMetaType,
  ModelRowNumType,
  Nullable,
} from '../../../../common/types';
import './params.css';

type ParamsModelType = {
  id?: number;
  name?: Nullable<string>;
  type?: Nullable<string>;
  value?: any;
};
type ParamsType = ModelRowNumType<ParamsModelType>;

const reducer = (
  state: ParamsType[],
  action: { type: string; payload: ParamsType[] }
): ParamsType[] => {
  switch (action.type) {
    case 'refresh':
      return action.payload;
    case 'add':
      return [...state, ...action.payload];
    case 'update':
      return state.map((columnItem) => {
        const updatedData = _.find(action.payload, {
          id: columnItem.id,
        });
        if (!_.isEmpty(updatedData)) {
          return updatedData;
        }
        return columnItem;
      });
    case 'delete':
      return state.filter((columnItem) => {
        const resultFind = _.findIndex(action.payload, {
          id: columnItem.id,
        });
        return resultFind === -1;
      });
    default:
      return state;
  }
};

const Params = ({
  rows,
  rowsMetaList,
  deriveCacheKey,
  onChange,
}: {
  rows: ParamsType[];
  rowsMetaList: ModelMetaType[];
  deriveCacheKey: () => string;
  onChange?: React.Dispatch<React.SetStateAction<GenericObjType>>;
}) => {
  const getCache = useCallback(() => {
    const cacheKey = deriveCacheKey();
    const cacheDataStr = sessionStorage.getItem(cacheKey);
    if (cacheDataStr) return JSON.parse(cacheDataStr);
    return [];
  }, [deriveCacheKey]);

  const setCache = useCallback(
    (cacheData: GenericObjType) => {
      const cacheKey = deriveCacheKey();
      const cacheDataStr = JSON.stringify(cacheData);
      sessionStorage.setItem(cacheKey, cacheDataStr);
    },
    [deriveCacheKey]
  );

  const [selectedParams, dispatchParams] = useReducer(reducer, getCache());
  const rowsValue = rows.map((rowItem) => {
    const matchedParams = selectedParams.find(
      (paramItem) => paramItem.id === rowItem.id
    );
    if (!_.isEmpty(matchedParams)) return matchedParams;
    return rowItem;
  });
  const userColumns = [
    SelectColumn,
    {
      key: 'rowNum',
      name: 'Row',
      width: 50,
      frozen: true,
      resizable: true,
    },
    {
      key: 'name',
      name: 'Key',
      editable: false,
      resizable: true,
      headerCellClass: 'filter-cell',
    },
    {
      key: 'type',
      name: 'Type',
      editable: false,
      width: 100,
      headerCellClass: 'filter-cell',
      resizable: true,
    },
    {
      key: 'value',
      name: 'Value',
      editable: true,
      width: 200,
      headerCellClass: 'filter-cell',
      renderEditCell: textEditor,
      resizable: true,
    },
  ];

  const handleAddRecords = (selectedData: ParamsType[]) => {
    const recordsToAdd = selectedData.filter((columnItem) => {
      const findResult = selectedParams.findIndex((paramItem) => {
        return paramItem.id === columnItem.id;
      });
      return findResult === -1;
    });
    dispatchParams({ type: 'add', payload: recordsToAdd });
  };

  const handleUpdateRecords = (updatedData: ParamsType[]) => {
    handleAddRecords(updatedData);
    dispatchParams({ type: 'update', payload: updatedData });
  };

  const handleRemoveRecords = (selectedData: ParamsType[]) => {
    const recordsToDelete = selectedParams.filter((paramItem) => {
      const findResult = selectedData.findIndex((columnItem) => {
        return paramItem.id === columnItem.id;
      });
      return findResult === -1;
    });
    dispatchParams({ type: 'delete', payload: recordsToDelete });
  };

  const handleRowSelection = (selectedData: ParamsType[]) => {
    handleAddRecords(selectedData);
    handleRemoveRecords(selectedData);
  };

  useEffect(() => {
    if (onChange) {
      const paramsData = selectedParams.reduce((result, columnItem) => {
        return { ...result, [columnItem.name as string]: columnItem.value };
      }, {});
      onChange(paramsData);
    }
  }, [onChange, selectedParams]);

  useEffect(() => {
    const cacheData = getCache();
    dispatchParams({ type: 'refresh', payload: cacheData });
  }, [getCache]);

  useEffect(() => {
    setCache(selectedParams);
  }, [setCache, selectedParams]);

  const isEmptyGrid = userColumns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="params-container">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="params-container">
      <div className="grid-toolbar">
        <div className="left-toolbar"></div>
        <div className="center-toolbar"></div>
        <div className="right-toolbar"></div>
      </div>
      <DataGrid<ParamsType>
        rowKeyGetter={(row) => String(row.id)}
        columns={userColumns}
        rows={rowsValue}
        rowsMetaList={rowsMetaList}
        rowHeight={30}
        onUpdate={handleUpdateRecords}
        onRowSelection={handleRowSelection}
        selectedRows={selectedParams}
      ></DataGrid>
    </div>
  );
};

export default Params;
