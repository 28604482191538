import { useCallback } from 'react';
import { generateUUID } from '../../../../../common/utils';
import type { GenericObjType } from '../../../../../common/types';
import Params from '../../../components/params/Params';

const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;

const Options = ({
  fileId,
  onChange,
}: {
  fileId: number;
  onChange?: React.Dispatch<React.SetStateAction<GenericObjType>>;
}) => {
  const cachePrefix = 'connect-file-options';
  const deriveCacheKey = useCallback(() => {
    const cacheBase = generateUUID({ fileId }, ['fileId']);
    const cacheKey = `${cachePrefix}-${cacheBase}`;
    return cacheKey;
  }, [fileId]);

  const optionsList = [
    { type: 'int', name: 'size', value: defaultSize },
    { type: 'int', name: 'page', value: 1 },
    { type: 'text', name: 'order', value: JSON.stringify(['id', 'asc']) },
  ];
  const optionsContent = optionsList.map((optionItem, inx) => {
    const optionItemId = inx + 1;
    return {
      ...optionItem,
      id: optionItemId,
      rowNum: optionItemId,
    };
  });

  return (
    <Params
      rows={optionsContent}
      rowsMetaList={[]}
      deriveCacheKey={deriveCacheKey}
      onChange={onChange}
    ></Params>
  );
};

export default Options;
