import _ from 'lodash';
import { useState, ReactNode, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  faFile,
  faCodeBranch,
  faCaretDown,
  faCodeMerge,
  faCodePullRequest,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BranchDialog from '../../../../domain/branch/dialog/grid/Branch';
import SubmitDialog from '../submits/list';
import FileDialog from '../../../../domain/file/dialog/grid/File';
import { RootState } from '../../../../store';
import { loadFileById } from '../../../../state/file/fileActions';
import { loadBranchById } from '../../../../state/branch/branchActions';
import { loadDraftTableById } from '../../../../state/draft-table/merge-diff/draftTableActions';
import BranchType from '../../../../models/branch';
import FileType from '../../../../models/file';
import ProcessLogType from '../../../../models/process-log';
import './header.css';

const Header = ({
  fileId,
  branchId,
  tableId,
  onSubmitSelect,
}: {
  fileId?: number;
  branchId?: number;
  tableId?: number;
  onSubmitSelect?: (submitItem: ProcessLogType) => void;
}) => {
  const navigate = useNavigate();
  const branchParams = useRef({
    order: [['id', 'desc']],
    where: { name: { ne: 'master' } },
  }).current;
  const currentFile = useSelector(
    (state: RootState) => state.file.active.selected
  );
  const currentBranch = useSelector(
    (state: RootState) => state.branch.active.selected
  );
  const currentSubmit = useSelector(
    (state: RootState) => state.submit.active.selected
  );
  const dispatch = useDispatch();
  const handleSelection = (selectedTable: string) => {
    const openDialog: { [k: string]: (a: boolean) => void } = {
      file: setOpenFiles,
      branch: setOpenBranches,
      submit: setOpenSubmits,
    };
    openDialog[selectedTable](true);
  };
  const [openBranches, setOpenBranches] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [openSubmits, setOpenSubmits] = useState(false);

  const redirectToDraftPage = () => {
    const filePage = `/files/${fileId}`;
    const branchPage = `/branches/${branchId}`;
    const tablePage = `/tables/${tableId}`;
    navigate(`/drafts${filePage}${branchPage}${tablePage}`);
  };

  const handleBranchSelect = (currentBranch: BranchType) => {
    const pagePath = 'reviews';
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${currentBranch.id}`;
    const redirectPath = `/${pagePath}${filePath}${branchPath}`;
    setOpenBranches(false);
    navigate(redirectPath);
  };

  const handleFileSelect = (currentFile: FileType) => {
    const filePath = `/files/${currentFile.id}`;
    const redirectPath = `/reviews${filePath}`;
    setOpenFiles(false);
    navigate(redirectPath);
  };

  useEffect(() => {
    if (fileId) dispatch(loadFileById(fileId));
    if (fileId && branchId) dispatch(loadBranchById(fileId, branchId));
    if (fileId && branchId && tableId)
      dispatch(loadDraftTableById(fileId, branchId, tableId));
  }, [dispatch, fileId, branchId, tableId]);

  const hasFile = fileId && !_.isEmpty(currentFile);
  const hasBranch = hasFile && branchId && !_.isEmpty(currentBranch);
  const hasSubmit = hasFile && hasBranch && !_.isEmpty(currentSubmit);

  const showButton: { [k: string]: (a?: boolean) => ReactNode } = {
    file: (enable = true) => (
      <button
        className={`file button${openFiles ? ' selected' : ''}`}
        onClick={() => handleSelection('file')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className={`file-icon`}
          icon={faFile}
        ></FontAwesomeIcon>
        <span className="file-name icon-label">
          {hasFile ? currentFile.name : `select file`}
        </span>
        <FontAwesomeIcon
          className="file-icon"
          icon={faCaretDown}
        ></FontAwesomeIcon>
      </button>
    ),
    branch: (enable = true) => (
      <button
        className={`branch button${openBranches ? ' selected' : ''}`}
        onClick={() => handleSelection('branch')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className={`branch-icon`}
          icon={faCodeBranch}
        ></FontAwesomeIcon>
        <span className="branch-name icon-label">
          {hasBranch ? currentBranch.name : `select branch`}
        </span>
        <FontAwesomeIcon
          className="branch-icon"
          icon={faCaretDown}
        ></FontAwesomeIcon>
      </button>
    ),
    submit: (enable = true) => (
      <button
        className={`submit button${openSubmits ? ' selected' : ''}`}
        onClick={() => handleSelection('submit')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className={`submit-icon`}
          icon={faCodePullRequest}
        ></FontAwesomeIcon>
        <span className="submit icon-label">
          {hasSubmit
            ? `#${currentSubmit.id} ${currentSubmit.message}`
            : `select submission`}
        </span>
        <FontAwesomeIcon
          className="submit-icon"
          icon={faCaretDown}
        ></FontAwesomeIcon>
      </button>
    ),
    draft: (enable = true) => (
      <button className="draft button" onClick={() => redirectToDraftPage()}>
        <FontAwesomeIcon
          className="tab-icon"
          icon={faCodeMerge}
        ></FontAwesomeIcon>
        <span className="icon-label">go to draft</span>
      </button>
    ),
  };

  return (
    <div className="review-header-container">
      <FileDialog
        open={openFiles}
        setOpen={setOpenFiles}
        selectedRowFilter={{ id: fileId }}
        onSelect={handleFileSelect}
      ></FileDialog>
      <div className="header-left">
        {showButton.file()}
        {hasFile ? showButton.branch() : null}
      </div>
      <div className="header-center">
        {hasFile && hasBranch ? showButton.submit() : null}
      </div>
      <div className="header-right">
        {showButton.draft(
          Boolean(currentBranch?.hasSubmit && !currentBranch?.hasConflict)
        )}
      </div>
      {hasFile && openBranches ? (
        <BranchDialog
          fileId={fileId}
          open={openBranches}
          setOpen={setOpenBranches}
          selectedRowFilter={{ id: branchId }}
          params={branchParams}
          onSelect={handleBranchSelect}
        ></BranchDialog>
      ) : null}
      {hasFile && hasBranch && openSubmits ? (
        <SubmitDialog
          fileId={fileId}
          branchId={branchId}
          onSelect={onSubmitSelect}
          open={openSubmits}
          setOpen={setOpenSubmits}
        ></SubmitDialog>
      ) : null}
    </div>
  );
};
export default Header;
