import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DraftTableList from '../../../domain/draft-table/list/DraftTable';
import DraftColumnDialog from '../../../domain/draft-column/dialog/grid/DraftColumn';
import type DraftTableType from '../../../models/draft-table';
import './footer.css';

const Footer = ({
  fileId,
  branchId,
  tableId,
}: {
  fileId: number;
  branchId: number;
  tableId?: number;
}) => {
  const navigate = useNavigate();
  const [openDraftColumns, setOpenDraftColumns] = useState(false);
  const handleSelectTable = (currentDraftTable: DraftTableType) => {
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const draftTablePath = `/tables/${currentDraftTable.tableId}`;
    const redirectPath = `/drafts${filePath}${branchPath}${draftTablePath}`;
    navigate(redirectPath);
  };

  return (
    <div className="draft-footer-container">
      <div className="footer-left">
        <DraftTableList
          fileId={fileId}
          branchId={branchId}
          selectedRowFilter={{ tableId, branchId }}
          onSelectOption={setOpenDraftColumns}
          onSelectTable={handleSelectTable}
        ></DraftTableList>
      </div>
      <div className="footer-center"></div>
      <div className="footer-right"></div>
      {tableId ? (
        <DraftColumnDialog
          fileId={fileId}
          branchId={branchId}
          tableId={tableId}
          open={openDraftColumns}
          setOpen={setOpenDraftColumns}
        ></DraftColumnDialog>
      ) : null}
    </div>
  );
};
export default Footer;
