import React, { useState } from 'react';
import FileGroupGrid from '../../../../domain/file-group/group/grid/Group';
import FileUserGrid from '../../../../domain/file-user/user/grid/User';

const FileShare = ({
  fileId,
  params,
}: {
  fileId: number;
  params?: { [k: string]: any };
}) => {
  const [selected, setSelected] = useState('group');
  return (
    <div className="share-container">
      <div className="share-tab">
        <button
          className={`share-user button${
            selected === 'user' ? ' selected' : ''
          }`}
          onClick={() => setSelected('user')}
        >
          Shared Users
        </button>
        <button
          className={`share-group button${
            selected === 'group' ? ' selected' : ''
          }`}
          onClick={() => setSelected('group')}
        >
          Shared Groups
        </button>
      </div>
      <div className="share-content">
        {selected === 'group' ? (
          <FileGroupGrid fileId={fileId} params={params}></FileGroupGrid>
        ) : null}
        {selected === 'user' ? (
          <FileUserGrid fileId={fileId} params={params}></FileUserGrid>
        ) : null}
      </div>
    </div>
  );
};

export default FileShare;
