import _ from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from './header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import Alert from '../../components/Alert/Alert';
import File from '../../domain/file/grid/File';
import FileCard from '../../domain/file/card/File';
import { isNothing } from '../../common/utils';
import type FileType from '../../models/file';
import FileShareDialog from '../../domain/file/share/dialog';
import { RootState } from '../../store';
import { loadDefaultBranch } from '../../state/branch/branchActions';
import './home.css';

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [openFileShare, setOpenFileShare] = useState(false);
  const [cardView, setCardView] = useState(true);
  const [selectedFileId, setSelectedFileId] = useState<number | undefined>();
  const [shareFileId, setShareFileId] = useState<number | undefined>();
  const orderDesc = useRef({ order: [['id', 'desc']] }).current;
  const [searchParams, setSearchParams] = useState(orderDesc);
  const defaultBranch = useSelector(
    (state: RootState) => state.branch.active.selected
  );
  const handleOnSelect = (currentFile: FileType) => {
    setSelectedFileId(currentFile.id);
  };
  const debouncedOnSearch = useRef(
    _.debounce((filterParams) => {
      setSearchParams(filterParams);
    }, 1000)
  ).current;
  const handleOnSearch = (value: any) => {
    if (isNothing(value)) {
      debouncedOnSearch(orderDesc);
    } else {
      debouncedOnSearch({
        where: { name: { substring: value } },
        ...orderDesc,
      });
    }
  };
  const handleOnShare = (currentFile: FileType) => {
    setShareFileId(currentFile.id);
    setOpenFileShare(true);
  };
  const handleViewChange = (isCardView: boolean) => {
    setCardView(isCardView);
  };

  useEffect(() => {
    if (isNothing(selectedFileId)) return;
    if (isNothing(defaultBranch)) return;
    const isUpdatedBranch = defaultBranch?.fileId === selectedFileId;
    if (!isUpdatedBranch) return;
    const isMaster = defaultBranch?.name === 'master';
    const filePath = isMaster ? 'masters' : 'drafts';
    navigate(
      `/${filePath}/files/${selectedFileId}/branches/${defaultBranch?.id}`
    );
  }, [dispatch, navigate, selectedFileId, defaultBranch]);

  useEffect(() => {
    if (!selectedFileId) return;
    dispatch(loadDefaultBranch(selectedFileId));
  }, [dispatch, selectedFileId]);

  return (
    <div className="home-container">
      <Alert></Alert>
      <div className="content-container">
        <div className={`sidebar${sidebarOpen ? '' : ' close'}`}>
          <Sidebar
            open={sidebarOpen}
            onToggle={(open) => setSidebarOpen(open)}
          ></Sidebar>
        </div>
        <div className={`content${sidebarOpen ? '' : ' open'}`}>
          <Header
            onSearch={handleOnSearch}
            onViewChange={handleViewChange}
          ></Header>
          <div className="file-data">
            {cardView ? (
              <FileCard
                onSelect={handleOnSelect}
                onShare={handleOnShare}
                params={searchParams}
              ></FileCard>
            ) : (
              <File
                onSelect={handleOnSelect}
                onShare={handleOnShare}
                params={searchParams}
              ></File>
            )}
          </div>
        </div>
      </div>
      {openFileShare ? (
        <FileShareDialog
          fileId={Number(shareFileId)}
          open={openFileShare}
          setOpen={setOpenFileShare}
        ></FileShareDialog>
      ) : null}
    </div>
  );
};

export default Home;
