import type ProcessLogType from '../../../models/process-log';
import type { ReducerType, StateType } from '../../../common/state/types';
import { initialState } from '../../../common/state/utils/constant';
import {
  actionOneMap,
  actionResponseMap,
  actionFlagMap,
} from '../../../common/state/types';
import {
  dataListLoadedReducer,
  dataAddedReducer,
  dataUpdatedReducer,
  dataFailedReducer,
  flagReducer,
} from '../../../common/state/reducers';

const rebaseReducer: ReducerType<ProcessLogType> = (
  state = {
    active: initialState as StateType<ProcessLogType>,
    rollback: initialState as StateType<ProcessLogType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.REBASE_DATALIST_LOADED:
      return dataListLoadedReducer<ProcessLogType>({
        state,
        action,
      });
    case actionOneMap.REBASE_DATA_UPDATED:
      return dataUpdatedReducer<ProcessLogType>({
        state,
        action,
      });
    case actionOneMap.REBASE_DATA_ADDED:
      return dataAddedReducer<ProcessLogType>({
        state,
        action,
      });
    case actionResponseMap.REBASE_DATA_FAILED:
      return dataFailedReducer<ProcessLogType>({
        state,
        action,
      });
    case actionFlagMap.REBASE_RELOAD:
      return flagReducer<ProcessLogType>({
        state,
        action: { ...action, payload: { reload: true } },
      });
    default:
      return state;
  }
};

export default rebaseReducer;
