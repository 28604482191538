import qs from 'qs';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import Alert from '../../components/Alert/Alert';
import { isNothing, parseParams } from '../../common/utils';
import type { FilterType } from '../../common/types';
import RowType from '../../models/row';
import { EmptyDataGrid } from '../../components/DataGrid/DataGrid';
import Row from '../../domain/row/grid/Row';
import Header from './header/Header';
import Footer from './footer/Footer';
import { RootState } from '../../store';
import {
  loadFirstBranch,
  loadBranchById,
} from '../../state/branch/branchActions';
import { loadTableById, loadFirstTable } from '../../state/table/tableActions';
import './master.css';

const Master = () => {
  const [searchRawParams, setSearchParams] = useSearchParams({});
  const searchParams = parseParams(searchRawParams);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const rawParams = useParams();
  const params = parseParams(rawParams);
  const fileId = params.fileId;
  const branchId = params.branchId;
  const tableId = params.tableId;

  const hasFile = !isNothing(fileId);
  const hasBranch = hasFile && !isNothing(branchId);
  const hasTable = hasBranch && !isNothing(tableId);

  const firstBranch = useSelector(
    (state: RootState) => state.branch.active.first
  );
  const firstTable = useSelector(
    (state: RootState) => state.table.active.first
  );

  const selectDefaultBranch = useCallback(() => {
    if (!hasFile) return;
    if (hasBranch) return;
    if (!firstBranch) return;
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${firstBranch?.id}`;
    const redirectPath = `/masters${filePath}${branchPath}`;
    navigate(redirectPath);
  }, [fileId, firstBranch, hasFile, hasBranch, navigate]);

  const selectDefaultTable = useCallback(() => {
    if (!hasFile) return;
    if (!hasBranch) return;
    if (hasTable) return;
    if (!firstTable) return;
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const tablePath = `/tables/${firstTable.tableId}`;
    const redirectPath = `/masters${filePath}${branchPath}${tablePath}`;
    navigate(redirectPath);
  }, [fileId, branchId, firstTable, hasFile, hasBranch, hasTable, navigate]);

  const handleFilter = (filters: FilterType<RowType>) => {
    setSearchParams(qs.stringify(filters));
  };

  useEffect(() => {
    selectDefaultBranch();
  }, [selectDefaultBranch]);

  useEffect(() => {
    selectDefaultTable();
  }, [selectDefaultTable]);

  useEffect(() => {
    if (!fileId) return;
    dispatch(loadFirstTable(fileId));
  }, [dispatch, fileId]);

  useEffect(() => {
    if (!fileId) return;
    if (!tableId) return;
    dispatch(loadTableById(fileId, tableId));
  }, [dispatch, fileId, tableId]);

  useEffect(() => {
    if (!fileId) return;
    dispatch(loadFirstBranch(fileId, { where: { name: 'master' } }));
  }, [dispatch, fileId]);

  useEffect(() => {
    if (!fileId) return;
    if (!branchId) return;
    dispatch(loadBranchById(fileId, branchId));
  }, [dispatch, fileId, branchId]);

  return (
    <div className="master-container">
      <Alert></Alert>
      <div className="content-container">
        <div className={`sidebar${sidebarOpen ? '' : ' close'}`}>
          <Sidebar
            open={sidebarOpen}
            onToggle={(open) => setSidebarOpen(open)}
          ></Sidebar>
        </div>
        <div className={`content${sidebarOpen ? '' : ' open'}`}>
          <div className="master-header">
            <Header
              fileId={fileId}
              branchId={branchId}
              tableId={tableId}
            ></Header>
          </div>
          <div className="master-data">
            {hasFile && hasTable ? (
              <Row
                fileId={fileId}
                branchId={branchId}
                tableId={tableId}
                searchParams={searchParams}
                onFilter={handleFilter}
              ></Row>
            ) : (
              <EmptyDataGrid></EmptyDataGrid>
            )}
          </div>
          <div className="master-footer">
            {hasFile && hasBranch ? (
              <Footer
                fileId={fileId}
                branchId={branchId}
                tableId={tableId}
              ></Footer>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Master;
