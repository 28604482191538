const dataTypeList = [
  { type: 'text' },
  { type: 'int' },
  { type: 'decimal' },
  { type: 'date' },
];

const seqOperators = [
  'any',
  'gt',
  'gte',
  'lt',
  'lte',
  'eq',
  'ne',
  'startsWith',
  'endsWith',
  'iLike',
  'notILike',
  'substring',
] as const;

export { dataTypeList, seqOperators };
