import type GroupType from '../../../models/group';
import type { ReducerType, StateType } from '../../../common/state/types';
import { initialState } from '../../../common/state/utils/constant';
import { actionManyMap, actionResponseMap } from '../../../common/state/types';
import {
  dataListLoadedReducer,
  dataListDeletedReducer,
  dataFailedReducer,
} from '../../../common/state/reducers';

const groupReducer: ReducerType<GroupType> = (
  state = {
    active: initialState as StateType<GroupType>,
    rollback: initialState as StateType<GroupType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.GROUPBYFILESHARE_DATALIST_LOADED:
      return dataListLoadedReducer<GroupType>({
        state,
        action,
      });
    case actionManyMap.GROUPBYFILESHARE_DATALIST_DELETED:
      return dataListDeletedReducer<GroupType>({
        state,
        action,
      });
    case actionResponseMap.GROUPBYFILESHARE_DATA_FAILED:
      return dataFailedReducer<GroupType>({ state, action });
    default:
      return state;
  }
};

export default groupReducer;
