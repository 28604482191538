import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import type ProcessLogType from '../../../../../models/process-log';
import SubmitList from '../../../../../domain/process-log/submit/list';
import CommitList from '../../../../../domain/process-log/commit/list';
import {
  acceptMerge,
  rejectMerge,
} from '../../../../../state/process-log/merge/mergeActions';
import AcceptDialog from '../dialog/accept/Accept';
import RejectDialog from '../dialog/reject/Reject';
import Dialog from '../../../../../components/Dialog/Dialog';
import './list.css';

const MergeReview = ({
  fileId,
  branchId,
  onSelect,
  open,
  setOpen,
}: {
  fileId: number;
  branchId: number;
  onSelect?: (selectedData: ProcessLogType) => void;
  open: boolean;
  setOpen: (data: boolean) => void;
}) => {
  const submitted = useRef({ where: { status: 'submitted' } }).current;
  const [currentSubmit, setCurrentSubmit] = useState<ProcessLogType | null>(
    null
  );
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [selectedSubmits, setSelectedSubmits] = useState<ProcessLogType[] | []>(
    []
  );
  const commitFilter = useRef({
    where: { status: 'submitted' },
  }).current;
  const dispatch = useDispatch();
  const handleOnSelect = (submitItem: ProcessLogType) => {
    setCurrentSubmit(submitItem);
    onSelect && onSelect(submitItem);
  };
  const handleAccept = (data: ProcessLogType) => {
    dispatch(acceptMerge(fileId, branchId, selectedSubmits, data.message));
  };
  const handleReject = (data: ProcessLogType) => {
    dispatch(rejectMerge(fileId, branchId, selectedSubmits, data.message));
  };
  const handlePreAccept = (data: ProcessLogType[]) => {
    setSelectedSubmits(data);
    setOpenAcceptDialog(true);
  };
  const handlePreReject = (data: ProcessLogType[]) => {
    setSelectedSubmits(data);
    setOpenRejectDialog(true);
  };
  return (
    <Dialog selector="submit-dialog" open={open} setOpen={setOpen}>
      <div className="submit-list-container">
        <SubmitList
          fileId={fileId}
          branchId={branchId}
          params={submitted}
          onSelect={handleOnSelect}
          onAccept={handlePreAccept}
          onReject={handlePreReject}
        >
          <CommitList
            fileId={fileId}
            branchId={branchId}
            submitId={Number(currentSubmit?.id)}
            params={commitFilter}
          ></CommitList>
        </SubmitList>
      </div>
      <div className="menu-buttons">
        <button type="button" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
      {openAcceptDialog ? (
        <AcceptDialog
          open={openAcceptDialog}
          setOpen={setOpenAcceptDialog}
          onSubmit={handleAccept}
        ></AcceptDialog>
      ) : null}
      {openRejectDialog ? (
        <RejectDialog
          open={openRejectDialog}
          setOpen={setOpenRejectDialog}
          onSubmit={handleReject}
        ></RejectDialog>
      ) : null}
    </Dialog>
  );
};

export default MergeReview;
