import type DraftRowType from '../../models/draft-row';
import type { ReducerType, StateType } from '../../common/state/types';
import { initialState } from '../../common/state/utils/constant';
import {
  actionOneMap,
  actionManyMap,
  actionResponseMap,
  actionFlagMap,
} from '../../common/state/types';
import {
  dataListLoadedReducer,
  dataListAddedReducer,
  dataListUpdatedReducer,
  dataListDeletedReducer,
  dataLoadedReducer,
  dataFailedReducer,
  flagReducer,
} from '../../common/state/reducers';

const draftRowReducer: ReducerType<DraftRowType> = (
  state = {
    active: initialState as StateType<DraftRowType>,
    rollback: initialState as StateType<DraftRowType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.DRAFTROW_DATALIST_LOADED:
      return dataListLoadedReducer<DraftRowType>({
        state,
        action,
      });
    case actionOneMap.DRAFTROW_DATA_LOADED:
      return dataLoadedReducer<DraftRowType>({ state, action });
    case actionManyMap.DRAFTROW_DATALIST_ADDED:
      return dataListAddedReducer<DraftRowType>({
        state,
        action,
      });
    case actionManyMap.DRAFTROW_DATALIST_UPDATED:
      return dataListUpdatedReducer<DraftRowType>({
        state,
        action,
      });
    case actionManyMap.DRAFTROW_DATALIST_DELETED:
      return dataListDeletedReducer<DraftRowType>({
        state,
        action,
      });
    case actionResponseMap.DRAFTROW_DATA_FAILED:
      return dataFailedReducer<DraftRowType>({ state, action });
    case actionFlagMap.DRAFTROW_LOADING:
      return flagReducer<DraftRowType>({
        state,
        action: { ...action, payload: { loading: true } },
      });
    case actionFlagMap.DRAFTROW_COMPLETED:
      return flagReducer<DraftRowType>({
        state,
        action: { ...action, payload: { loading: false } },
      });
    case actionFlagMap.DRAFTROW_RELOAD:
      return flagReducer<DraftRowType>({
        state,
        action: { ...action, payload: { reload: true } },
      });
    default:
      return state;
  }
};

export default draftRowReducer;
