import type { ReducerType, StateType } from '../../common/state/types';
import {
  actionOneMap,
  actionResponseMap,
  actionManyMap,
} from '../../common/state/types';
import {
  setAuthToken,
  setLoginGroup,
  setLoginContext,
} from '../../common/utils';
import { initialState } from '../../common/state/utils/constant';
import type UserType from '../../models/user';
import {
  dataListLoadedReducer,
  dataListAddedReducer,
  dataListUpdatedReducer,
  dataListDeletedReducer,
  dataLoadedReducer,
  dataFailedReducer,
} from '../../common/state/reducers';

type UserCustomType = {
  loginUser?: UserType;
  guestUser?: UserType;
  loginContext?: string;
};

type UserActionType = {
  type:
    | 'USER_LOGINUSER_LOADED'
    | 'USER_GUESTUSER_LOADED'
    | 'USER_LOGOUTUSER'
    | 'USER_LOGINCONTEXT';
  payload: UserType | string;
};

const userReducer: ReducerType<UserType, UserCustomType, UserActionType> = (
  state = {
    active: initialState as StateType<UserType, UserCustomType>,
    rollback: initialState as StateType<UserType, UserCustomType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.USER_DATALIST_LOADED:
      return dataListLoadedReducer<UserType>({
        state,
        action,
      });
    case actionOneMap.USER_DATA_LOADED:
      return dataLoadedReducer<UserType>({ state, action });
    case actionManyMap.USER_DATALIST_ADDED:
      return dataListAddedReducer<UserType>({
        state,
        action,
      });
    case actionManyMap.USER_DATALIST_UPDATED:
      return dataListUpdatedReducer<UserType>({
        state,
        action,
      });
    case actionManyMap.USER_DATALIST_DELETED:
      return dataListDeletedReducer<UserType>({
        state,
        action,
      });
    case 'USER_LOGINUSER_LOADED':
      return {
        active: {
          ...state.active,
          flags: { ...state.active.flags, loading: false },
          custom: { ...state.active.custom, loginUser: action.payload },
        },
        rollback: state.active,
      };
    case 'USER_GUESTUSER_LOADED':
      return {
        active: {
          ...state.active,
          flags: { ...state.active.flags, loading: false },
          custom: { ...state.active.custom, guestUser: action.payload },
        },
        rollback: state.active,
      };
    case 'USER_LOGOUTUSER':
      setAuthToken(undefined);
      setLoginContext('group');
      setLoginGroup(undefined);
      return {
        active: {
          ...state.active,
          custom: { ...state.active.custom, loginUser: undefined },
        },
        rollback: state.active,
      };
    case 'USER_LOGINCONTEXT':
      setLoginContext(String(action.payload));
      return {
        active: {
          ...state.active,
          custom: { ...state.active.custom, loginContext: action.payload },
        },
        rollback: state.active,
      };
    case actionResponseMap.USER_DATA_FAILED:
      setAuthToken(undefined);
      setLoginContext('group');
      setLoginGroup(undefined);
      return dataFailedReducer<UserType>({ state, action });
    default:
      return state;
  }
};

export default userReducer;
