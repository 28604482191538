import _ from 'lodash';
import type {
  StateCollectionType,
  ActionResponseType,
  ActionOneType,
  ActionManyType,
  ActionFlagType,
} from './types';
import {
  actionOneMap,
  actionManyMap,
  actionResponseMap,
  actionFlagMap,
} from './types';
import { upsertOnSortedArray } from '../utils';
import { GenericObjType } from '../types';

export const dataListLoadedReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>;
}): StateCollectionType<ModelType> => {
  const { type, payload: pageResponse } =
    action as ActionResponseType<ModelType>;
  if (!(type in actionResponseMap)) throw new Error(`Invalid reducer ${type}`);
  const hasData = !_.isEmpty(pageResponse.data);
  const isReload = state.active.flags.reload;
  const sameUrl = _.isEqual(action.path?.url, state?.active.path?.url);
  const sameWhereParams = _.isEqual(
    action.path?.params?.where,
    state.active.path?.params?.where
  );
  const sameAllParams = _.isEqual(
    action.path?.params,
    state.active.path?.params
  );
  const isPagination =
    !sameAllParams && sameUrl && sameWhereParams && hasData && !isReload;
  return {
    active: {
      ...state.active,
      data: isPagination
        ? upsertOnSortedArray(state.active.data, pageResponse.data, 'rowNum')
        : pageResponse.data,
      meta: pageResponse.meta,
      metaList: isPagination
        ? upsertOnSortedArray(
            state.active.metaList,
            [pageResponse.meta],
            'currentPage'
          )
        : [pageResponse.meta],
      flags: { ...state.active.flags, loading: false, reload: false },
      path: action.path,
    },
    rollback: state.active,
  };
};

export const dataAddedReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>;
}): StateCollectionType<ModelType> => {
  const { type, payload: pageResponse } = action as ActionOneType<ModelType>;
  if (!(type in actionOneMap)) throw new Error(`Invalid reducer ${type}`);
  const sameUrl = _.isEqual(action.path?.url, state?.active.path?.url);
  return {
    active: {
      ...state.active,
      data: [
        ...state.active.data,
        {
          ...pageResponse,
          rowNum: state.active.meta?.endRecord + 1,
        },
      ],
      meta: {
        ...state.active.meta,
        endRecord: (state.active.meta?.endRecord || 0) + 1,
      },
      flags: { ...state.active.flags, loading: false },
      selected: sameUrl ? state.active.selected : undefined,
    },
    rollback: state.active,
  };
};

export const dataListAddedReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>;
}): StateCollectionType<ModelType> => {
  const { type, payload: pageResponse } = action as ActionManyType<ModelType>;
  if (!(type in actionManyMap)) throw new Error(`Invalid reducer ${type}`);
  const sameUrl = _.isEqual(action.path?.url, state?.active.path?.url);
  return {
    active: {
      ...state.active,
      data: [
        ...state.active.data,
        ...pageResponse.map((item: ModelType, i: number) => {
          const lastMeta = _.maxBy(state.active.metaList, (meta) => {
            return meta.currentPage;
          });
          const lastRecord = lastMeta?.endRecord || 0;
          return {
            ...item,
            rowNum: lastRecord + i + 1,
          };
        }),
      ],
      metaList: state.active.metaList.map((meta) => {
        const rowsAdded = pageResponse.length;
        const lastMeta = _.maxBy(state.active.metaList, (meta) => {
          return meta.currentPage;
        });
        const isLastMeta = meta.currentPage === lastMeta?.currentPage;
        if (isLastMeta) {
          return {
            ...meta,
            endRecord: meta.endRecord + rowsAdded,
            totalRecords: meta.totalRecords + rowsAdded,
          };
        }
        return meta;
      }),
      flags: { ...state.active.flags, loading: false },
      selected: sameUrl ? state.active.selected : undefined,
    },
    rollback: state.active,
  };
};

export const dataUpdatedReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>;
}): StateCollectionType<ModelType> => {
  const { type, payload: pageResponse } = action as ActionOneType<ModelType>;
  if (!(type in actionOneMap)) throw new Error(`Invalid reducer ${type}`);
  const isSelectionUpdated = state.active.selected?.id === pageResponse.id;
  return {
    active: {
      ...state.active,
      data: state.active.data.map((item) => {
        const isUpdated = pageResponse.id === item.id;
        if (isUpdated) {
          return { ...pageResponse, rowNum: item.rowNum };
        }
        return item;
      }),
      flags: { ...state.active.flags, loading: false },
      selected: isSelectionUpdated ? pageResponse : state.active.selected,
    },
    rollback: state.active,
  };
};

export const dataListUpdatedReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>;
}): StateCollectionType<ModelType> => {
  const { type, payload: pageResponse } = action as ActionManyType<ModelType>;
  if (!(type in actionManyMap)) throw new Error(`Invalid reducer ${type}`);
  const isSelectionUpdated = pageResponse
    .map((item) => item.id)
    .includes(state.active.selected?.id);
  const pageResponseData = _.find(pageResponse, {
    id: state.active.selected?.id,
  });
  return {
    active: {
      ...state.active,
      data: state.active.data.map((item) => {
        const updatedData = _.find(pageResponse, {
          id: item.id,
        });
        if (!_.isEmpty(updatedData)) {
          return { ...updatedData, rowNum: item.rowNum };
        }
        return item;
      }),
      flags: { ...state.active.flags, loading: false },
      selected: isSelectionUpdated ? pageResponseData : state.active.selected,
    },
    rollback: state.active,
  };
};

export const dataDeletedReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>;
}): StateCollectionType<ModelType> => {
  const { type, payload: pageResponse } = action as ActionOneType<ModelType>;
  if (!(type in actionOneMap)) throw new Error(`Invalid reducer ${type}`);
  const isSelectionDeleted = state.active.selected?.id === pageResponse.id;
  return {
    active: {
      ...state.active,
      data: _.filter(state.active.data, (item) => {
        const isDeleted = pageResponse.id === item.id;
        return !isDeleted;
      }),
      meta: {
        ...state.active.meta,
        endRecord: _.get(state.active.meta, 'endRecord', 0) - 1,
      },
      flags: { ...state.active.flags, loading: false },
      selected: isSelectionDeleted ? undefined : state.active.selected,
    },
    rollback: state.active,
  };
};

export const dataListDeletedReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>;
}): StateCollectionType<ModelType> => {
  const { type, payload: pageResponse } = action as ActionManyType<ModelType>;
  if (!(type in actionManyMap)) throw new Error(`Invalid reducer ${type}`);
  const isSelectionDeleted = pageResponse
    .map((item) => item.id)
    .includes(state.active.selected?.id);
  return {
    active: {
      ...state.active,
      data: _.filter(state.active.data, (item) => {
        const resultFind = _.findIndex(pageResponse, {
          id: item.id,
        });
        return resultFind === -1;
      }),
      flags: { ...state.active.flags, loading: false },
      selected: isSelectionDeleted ? undefined : state.active.selected,
    },
    rollback: state.active,
  };
};

export const dataLoadedReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>;
}): StateCollectionType<ModelType> => {
  const { type, payload: pageResponse } = action as ActionOneType<ModelType>;
  if (!(type in actionOneMap)) throw new Error(`Invalid reducer ${type}`);
  return {
    active: {
      ...state.active,
      flags: { ...state.active.flags, loading: false, reload: false },
      first: undefined,
      selected: pageResponse,
    },
    rollback: state.active,
  };
};

export const dataFailedReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>;
}): StateCollectionType<ModelType> => {
  const { type } = action as ActionResponseType<ModelType>;
  if (!(type in actionResponseMap)) throw new Error(`Invalid reducer ${type}`);
  return { ...state, active: state.rollback };
};

export const flagReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>
    | ActionFlagType;
}): StateCollectionType<ModelType> => {
  const { type, payload: pageResponse } = action as ActionFlagType;
  if (!(type in actionFlagMap)) throw new Error(`Invalid reducer ${type}`);
  return {
    active: {
      ...state.active,
      flags: { ...state.active.flags, ...pageResponse },
    },
    rollback: state.active,
  };
};

export const dataFirstReducer = <ModelType extends GenericObjType>({
  state,
  action,
}: {
  state: StateCollectionType<ModelType>;
  action:
    | ActionOneType<ModelType>
    | ActionResponseType<ModelType>
    | ActionManyType<ModelType>;
}): StateCollectionType<ModelType> => {
  const { type, payload: pageResponse } = action as ActionOneType<ModelType>;
  if (!(type in actionOneMap)) throw new Error(`Invalid reducer ${type}`);
  return {
    active: {
      ...state.active,
      flags: { ...state.active.flags, loading: false },
      first: pageResponse,
    },
    rollback: state.active,
  };
};
