import type RowType from '../../models/row';
import type { ReducerType, StateType } from '../../common/state/types';
import { initialState } from '../../common/state/utils/constant';
import { actionOneMap, actionResponseMap } from '../../common/state/types';
import {
  dataListLoadedReducer,
  dataLoadedReducer,
  dataFailedReducer,
} from '../../common/state/reducers';

const rowReducer: ReducerType<RowType> = (
  state = {
    active: initialState as StateType<RowType>,
    rollback: initialState as StateType<RowType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.ROW_DATALIST_LOADED:
      return dataListLoadedReducer<RowType>({
        state,
        action,
      });
    case actionOneMap.ROW_DATA_LOADED:
      return dataLoadedReducer<RowType>({ state, action });
    case actionResponseMap.ROW_DATA_FAILED:
      return dataFailedReducer<RowType>({ state, action });
    default:
      return state;
  }
};

export default rowReducer;
