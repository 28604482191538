import _ from 'lodash';
import { GenericObjType } from '../../../../common/types';

const SelectOptions = <StateType extends GenericObjType>({
  row,
  targetColumn,
  onRowChange,
  values,
}: {
  row: any;
  targetColumn: string;
  onRowChange: (updatedRows: any, commitChanges: boolean) => void;
  values: StateType[];
}) => {
  const isSubTable = targetColumn.includes('.');
  const updateSubTable = (targetValue: any) => {
    const updatedSubTable = values.find(
      (item) => _.get(item, targetColumn) === targetValue
    );
    return updatedSubTable;
  };
  return (
    <select
      style={{ width: '100%', height: '100%', paddingLeft: '10px' }}
      value={_.get(row, targetColumn)}
      onChange={(event) => {
        const targetValue = event.target.value;
        if (isSubTable) {
          const updatedSubTable = updateSubTable(targetValue);
          onRowChange({ ...row, ...updatedSubTable }, true);
        } else {
          const updatedColumn = { [targetColumn]: targetValue };
          onRowChange({ ...row, ...updatedColumn }, true);
        }
      }}
      autoFocus
    >
      {values.map((item, i) => (
        <option key={i} value={_.get(item, targetColumn)}>
          {_.get(item, targetColumn)}
        </option>
      ))}
    </select>
  );
};

export { SelectOptions };
