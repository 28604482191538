import {
  DataGrid,
  EmptyDataGrid,
} from '../../../../components/DataGrid/DataGrid';
import type { Nullable } from '../../../../common/types';
import './headers.css';

type HeadersType = {
  id: number;
  rowNum: number;
  name: Nullable<string>;
  value: Nullable<string> | Nullable<number>;
};

const Headers = () => {
  const headersList = [
    { name: 'host', value: window.location.host },
    { name: 'accept', value: 'application/json, text/plain, */*' },
    { name: 'xLoginContext', value: localStorage.getItem('context') },
    { name: 'xLoginGroup', value: localStorage.getItem('group') },
    { name: 'xAuthToken', value: localStorage.getItem('token') },
  ];
  const headersContent = headersList.map((headerItem, inx) => ({
    ...headerItem,
    rowNum: inx + 1,
    id: inx + 1,
  }));

  const userColumns = [
    {
      key: 'rowNum',
      name: 'Row',
      width: 50,
      frozen: true,
      resizable: true,
      editable: false,
    },
    {
      key: 'name',
      name: 'Key',
      editable: false,
      resizable: true,
      headerCellClass: 'filter-cell',
    },
    {
      key: 'value',
      name: 'Value',
      editable: false,
      width: 300,
      headerCellClass: 'filter-cell',
      resizable: true,
    },
  ];

  const isEmptyGrid = userColumns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="headers-container">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="headers-container">
      <div className="grid-toolbar">
        <div className="left-toolbar"></div>
        <div className="center-toolbar"></div>
        <div className="right-toolbar"></div>
      </div>
      <DataGrid<HeadersType>
        rowKeyGetter={(row) => String(row.id)}
        columns={userColumns}
        rows={headersContent}
        rowsMetaList={[]}
        rowHeight={30}
      ></DataGrid>
    </div>
  );
};

export default Headers;
