import _ from 'lodash';
import DraftColumnType from '../../../models/draft-column';
import { GenericObjType } from '../../../common/types';
import { parseDiff, isNothing } from '../../../common/utils';
import { Column } from 'react-data-grid';

type ColumnPropsType = {
  key: string;
  name: string;
  headerCellClass?: string;
  props: any;
}[];

const deriveColumnName = (column: DraftColumnType) => {
  const rawName = column.name;
  const columnDiff = parseDiff(rawName);
  if (!columnDiff.isDiff) return rawName;
  const after = _.get(columnDiff.value, 'after');
  const before = _.get(columnDiff.value, 'before');
  if (after) return after;
  return before;
};

const deriveColumnProps = (columns: DraftColumnType[]): ColumnPropsType => {
  const uniqColumns: string[] = [];
  const columnProps = columns
    .map((column) => {
      const columnName = deriveColumnName(column);
      return { ...column, name: columnName, diff: column.name };
    })
    .filter((column) => !_.isNil(column.map) && !_.isNil(column.name))
    .filter((column) => {
      const isFirst = !uniqColumns.includes(String(column.name));
      if (isFirst) uniqColumns.push(String(column.name));
      return isFirst;
    })
    .map((column) => {
      return {
        key: String(column.name),
        name: String(column.name),
        props: column,
      };
    });
  return columnProps;
};

const deriveColumnDefaults = <TRow extends GenericObjType>(
  columns: Column<TRow>[]
) => {
  const defaultVal = columns.reduce((result, column) => {
    const isFilterCell = column.headerCellClass === 'filter-cell';
    if (!isFilterCell) return result;
    return { ...result, [column.key]: '' };
  }, {});
  return defaultVal;
};

const createBlankRecords = (columns: ColumnPropsType, rowCount = 10) => {
  const blankRows = [];
  for (let i = 1; i <= rowCount; i += 1) {
    const columnKeys = [...columns.map((column) => column.key), 'id'];
    const blankRow = _.reduce(
      columnKeys,
      (collection, columnName) => ({ ...collection, [columnName]: null }),
      {}
    );
    blankRows.push(blankRow);
  }
  return blankRows;
};

const createDbFilters = <T extends GenericObjType>(currentFilters: T) => {
  const dbFilters = _.reduce(
    currentFilters,
    (result, value, key) => {
      if (key === 'enabled') return result;
      if (isNothing(value)) return result;
      return { ...result, [key]: { substring: value } };
    },
    {}
  );
  return dbFilters;
};

const createGridFilters = <T extends GenericObjType>(currentFilters: T) => {
  const gridFilters = _.reduce(
    currentFilters,
    (result, value, key) => {
      if (key === 'enabled') return result;
      if (isNothing(value)) return result;
      return { ...result, [key]: value };
    },
    {}
  );
  return gridFilters;
};

export {
  deriveColumnProps,
  deriveColumnDefaults,
  createBlankRecords,
  createDbFilters,
  createGridFilters,
};
