export const initialState = {
  data: [],
  meta: {
    totalPages: 0,
    currentPage: 0,
    startRecord: 0,
    endRecord: 0,
    totalRecords: 0,
    order: 'ASC',
  },
  metaList: [
    {
      totalPages: 0,
      currentPage: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecords: 0,
      order: 'ASC',
    },
  ],
  flags: {
    loading: true,
    reload: false,
  },
};

export const actionTypeList = {
  domain: [
    'BRANCH',
    'CHANGELOG',
    'COLUMN',
    'DRAFTCOLUMN',
    'DRAFTROW',
    'DRAFTTABLE',
    'DRAFTCOLUMNMERGEDIFF',
    'DRAFTROWMERGEDIFF',
    'DRAFTTABLEMERGEDIFF',
    'DRAFTCOLUMNREBASEDIFF',
    'DRAFTROWREBASEDIFF',
    'DRAFTTABLEREBASEDIFF',
    'FILE',
    'GROUP',
    'GROUPBYFILE',
    'GROUPBYFILESHARE',
    'GROUPBYUSER',
    'GROUPBYUSERJOIN',
    'COMMIT',
    'MERGE',
    'REBASE',
    'SUBMIT',
    'ROW',
    'TABLE',
    'USER',
    'USERBYFILE',
    'USERBYFILESHARE',
    'USERBYGROUP',
    'USERBYGROUPJOIN',
    'ALERT',
    'PERMISSION',
  ] as const,
  one: [
    'DATA_LOADED',
    'DATA_DELETED',
    'DATA_UPDATED',
    'DATA_ADDED',
    'DATA_FIRST',
  ] as const,
  many: [
    'DATALIST_ADDED',
    'DATALIST_DELETED',
    'DATALIST_UPDATED',
    'DATALIST_ADD_REQUEST',
    'DATALIST_DELETE_REQUEST',
    'DATALIST_UPDATE_REQUEST',
  ] as const,
  response: [
    'DATALIST_LOADED',
    'DATALIST_LOAD_REQUEST',
    'DATA_FAILED',
    'PAGE_META',
  ] as const,
  flag: ['RELOAD', 'LOADING', 'COMPLETED'] as const,
};
