import qs from 'qs';
import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Alert from '../../../components/Alert/Alert';
import { isNothing, parseParams } from '../../../common/utils';
import type { FilterType } from '../../../common/types';
import { EmptyDataGrid } from '../../../components/DataGrid/DataGrid';
import {
  loadFirstBranch,
  loadBranchById,
} from '../../../state/branch/branchActions';
import {
  loadFirstDraftTable,
  loadDraftTableById,
} from '../../../state/draft-table/draftTableActions';
import {
  loadFirstSubmit,
  loadSubmitById,
} from '../../../state/process-log/submit/submitActions';
import DraftRow from '../../../domain/draft-row/merge-diff/grid/DraftRow';
import DraftRowType from '../../../models/draft-row';
import Header from './header/Header';
import Footer from './footer/Footer';
import { RootState } from '../../../store';
import ProcessLogType from '../../../models/process-log';
import './review.css';

const Review = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const rawParams = useParams();
  const [searchRawParams, setSearchParams] = useSearchParams();
  const params = parseParams(rawParams);
  const searchParams = parseParams(searchRawParams);
  const fileId = params.fileId;
  const branchId = params.branchId;
  const tableId = params.tableId;
  const submitId = params.submitId;

  const hasFile = !isNothing(fileId);
  const hasBranch = hasFile && !isNothing(branchId);
  const hasTable = hasBranch && !isNothing(tableId);
  const hasSubmit = hasBranch && !isNothing(submitId);

  const firstBranch = useSelector(
    (state: RootState) => state.branch.active.first
  );
  const firstTable = useSelector(
    (state: RootState) => state.draftTable.active.first
  );
  const firstSubmit = useSelector(
    (state: RootState) => state.submit.active.first
  );

  const handleSubmitSelect = (submitItem: ProcessLogType) => {
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const submitPath = `/submits/${submitItem.id}`;
    const tablePath = `/tables/${tableId}`;
    const redirectPath = `/reviews${filePath}${branchPath}${submitPath}${tablePath}`;
    navigate({ pathname: redirectPath });
  };

  const selectDefaultBranch = useCallback(() => {
    if (!hasFile) return;
    if (hasBranch) return;
    if (isNothing(firstBranch)) return;
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${firstBranch?.id}`;
    const redirectPath = `/reviews${filePath}${branchPath}`;
    navigate({ pathname: redirectPath });
  }, [fileId, hasFile, hasBranch, navigate, firstBranch]);

  const selectDefaultTable = useCallback(() => {
    if (!hasFile) return;
    if (!hasBranch) return;
    if (!hasSubmit) return;
    if (hasTable) return;
    if (isNothing(firstTable)) return;
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const submitPath = `/submits/${submitId}`;
    const tablePath = `/tables/${firstTable?.tableId}`;
    const redirectPath = `/reviews${filePath}${branchPath}${submitPath}${tablePath}`;
    navigate({ pathname: redirectPath });
  }, [
    fileId,
    branchId,
    submitId,
    hasFile,
    hasBranch,
    hasSubmit,
    hasTable,
    navigate,
    firstTable,
  ]);

  const selectDefaultSubmit = useCallback(() => {
    if (!hasFile) return;
    if (!hasBranch) return;
    if (hasSubmit) return;
    if (isNothing(firstSubmit)) return;
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const submitPath = `/submits/${firstSubmit?.id}`;
    const redirectPath = `/reviews${filePath}${branchPath}${submitPath}`;
    navigate({ pathname: redirectPath });
  }, [fileId, branchId, hasFile, hasBranch, hasSubmit, navigate, firstSubmit]);

  const handleFilter = (filters: FilterType<DraftRowType>) => {
    setSearchParams(qs.stringify(filters));
  };

  useEffect(() => {
    selectDefaultBranch();
  }, [selectDefaultBranch]);

  useEffect(() => {
    selectDefaultSubmit();
  }, [selectDefaultSubmit]);

  useEffect(() => {
    selectDefaultTable();
  }, [selectDefaultTable]);

  useEffect(() => {
    if (!fileId) return;
    dispatch(loadFirstBranch(fileId, { where: { name: { ne: 'master' } } }));
  }, [dispatch, fileId]);

  useEffect(() => {
    if (!branchId) return;
    dispatch(loadFirstDraftTable(fileId, branchId));
  }, [dispatch, fileId, branchId]);

  useEffect(() => {
    if (!branchId) return;
    dispatch(
      loadFirstSubmit({ where: { fileId, branchId, status: 'submitted' } })
    );
  }, [dispatch, fileId, branchId]);

  useEffect(() => {
    if (!branchId) return;
    if (!submitId) return;
    dispatch(loadSubmitById(fileId, branchId, submitId));
  }, [dispatch, fileId, branchId, submitId]);

  useEffect(() => {
    if (!branchId) return;
    if (!tableId) return;
    dispatch(loadDraftTableById(fileId, branchId, tableId));
  }, [dispatch, fileId, branchId, tableId]);

  useEffect(() => {
    if (!fileId) return;
    if (!branchId) return;
    dispatch(loadBranchById(fileId, branchId));
  }, [dispatch, fileId, branchId]);

  return (
    <div className="review-container">
      <Alert></Alert>
      <div className="content-container">
        <div className={`sidebar${sidebarOpen ? '' : ' close'}`}>
          <Sidebar
            open={sidebarOpen}
            onToggle={(open) => setSidebarOpen(open)}
          ></Sidebar>
        </div>
        <div className={`content${sidebarOpen ? '' : ' open'}`}>
          <div className="draft-header">
            <Header
              fileId={fileId}
              branchId={branchId}
              tableId={tableId}
              onSubmitSelect={handleSubmitSelect}
            ></Header>
          </div>
          <div className="draft-data">
            {hasFile && hasBranch && hasTable && hasSubmit ? (
              <DraftRow
                fileId={fileId}
                branchId={branchId}
                tableId={tableId}
                params={{ where: { submitId } }}
                searchParams={searchParams}
                onFilter={handleFilter}
              ></DraftRow>
            ) : (
              <EmptyDataGrid></EmptyDataGrid>
            )}
          </div>
          <div className="draft-footer">
            {hasFile && hasBranch ? (
              <Footer
                fileId={fileId}
                branchId={branchId}
                submitId={submitId}
                tableId={tableId}
              ></Footer>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
