import _ from 'lodash';
import React, { useState, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  faMagnifyingGlass,
  faCaretDown,
  faUserLock,
  faUserNinja,
  faUsers,
  faKey,
  faGrip,
  faTableList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootState } from '../../../../store';
import { loadLoginContext } from '../../../../state/user/userActions';
import RegisterDialog from '../../../../domain/user/register/dialog/form/Register';
import LoginDialog from '../../../../domain/user/login/dialog/form/Login';
import GroupByUserDialog from '../../../../domain/user-group/group/dialog/grid/Group';
import './header.css';

const Header = ({
  onSearch,
  onViewChange,
}: {
  onSearch: (value: string) => void;
  onViewChange: (value: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const loginContext = useSelector(
    (state: RootState) => state.user.active.custom?.loginContext
  );
  const loginUser = useSelector(
    (state: RootState) => state.user.active.custom?.loginUser
  );
  const loginGroup = useSelector(
    (state: RootState) => state.group.active.custom?.loginGroup
  );
  const guestUser = useSelector(
    (state: RootState) => state.user.active.custom?.guestUser
  );
  const publicGroup = useSelector(
    (state: RootState) => state.group.active.custom?.publicGroup
  );
  const [selected, setSelected] = useState(loginContext);
  const [cardView, setCardView] = useState(true);
  const [openRegister, setOpenRegister] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openGroupByUser, setOpenGroupByUser] = useState(false);

  const hasLoginUser = !_.isEmpty(loginUser);
  const hasLoginGroup = hasLoginUser && !_.isEmpty(loginGroup);

  const handleTabSelection = (selectedButton: string) => {
    if (selectedButton === 'user') {
      dispatch(loadLoginContext('user'));
    }
    if (selectedButton === 'group') {
      dispatch(loadLoginContext('group'));
    }
    setSelected(selectedButton);
  };

  const handleSignIn = () => {
    setOpenRegister(false);
    setOpenLogin(true);
  };

  const handleSignUp = () => {
    setOpenLogin(false);
    setOpenRegister(true);
  };

  const handleViewChange = () => {
    const cardViewState = !cardView;
    setCardView(cardViewState);
    onViewChange(cardViewState);
  };

  const showButton: { [k: string]: ReactNode } = {
    user: (
      <button
        className={`shared-user button${
          selected === 'user' ? ' selected' : ''
        }`}
        onClick={() => handleTabSelection('user')}
        disabled={!hasLoginUser}
      >
        <FontAwesomeIcon
          className="icon"
          icon={hasLoginUser ? faUserLock : faUserNinja}
        ></FontAwesomeIcon>
        <span className="icon-label">
          {hasLoginUser ? loginUser.name : guestUser?.name}
        </span>
      </button>
    ),
    group: (
      <button
        className={`shared-group button${
          selected === 'group' ? ' selected' : ''
        }`}
        onClick={() => handleTabSelection('group')}
      >
        <FontAwesomeIcon className="icon" icon={faUsers}></FontAwesomeIcon>
        <span className="icon-label">
          {hasLoginGroup ? loginGroup.name : publicGroup?.name}
        </span>
        <FontAwesomeIcon
          className="icon caret group-by-user"
          icon={faCaretDown}
          onClick={() => hasLoginUser && setOpenGroupByUser(true)}
        ></FontAwesomeIcon>
      </button>
    ),
    loginUser: (
      <button
        className={`login-user button${openLogin ? ' selected' : ''}`}
        onClick={() => setOpenLogin(true)}
        disabled={hasLoginUser}
      >
        <FontAwesomeIcon
          className="icon"
          icon={hasLoginUser ? faUserLock : faKey}
        ></FontAwesomeIcon>
        <span className="icon-label">
          {hasLoginUser ? loginUser.name : 'sign in'}
        </span>
      </button>
    ),
    viewType: (
      <button className="view-type button" onClick={handleViewChange}>
        <FontAwesomeIcon
          className="icon"
          icon={cardView ? faTableList : faGrip}
        ></FontAwesomeIcon>
        <span className="icon-label">
          {cardView ? 'list view' : 'card view'}
        </span>
      </button>
    ),
  };

  return (
    <>
      <div className="home-header-container">
        <div className="header-left">
          {showButton.user} {showButton.group}
        </div>
        <div className="header-center">
          <div className="search-container">
            <FontAwesomeIcon
              className="search-icon"
              icon={faMagnifyingGlass}
            ></FontAwesomeIcon>
            <input
              className="search-input"
              placeholder="Search"
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="header-right">
          {showButton.loginUser}
          {showButton.viewType}
        </div>
      </div>
      <RegisterDialog
        open={openRegister}
        setOpen={setOpenRegister}
        signIn={handleSignIn}
      ></RegisterDialog>
      <LoginDialog
        signUp={handleSignUp}
        open={openLogin}
        setOpen={setOpenLogin}
      ></LoginDialog>
      {openGroupByUser ? (
        <GroupByUserDialog
          userId={loginUser ? Number(loginUser?.id) : Number(guestUser?.id)}
          open={openGroupByUser}
          setOpen={setOpenGroupByUser}
        ></GroupByUserDialog>
      ) : null}
    </>
  );
};
export default Header;
