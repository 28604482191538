import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import {
  actionManyMap,
  actionResponseMap,
  actionFlagMap,
} from '../../common/state/types';
import { scrollChunk, scrollBatch } from '../../common/utils';
import type DraftColumnType from '../../models/draft-column';
import type { StateType } from '../../common/state/types';
import { handleError } from '../alert/alertActions';
import { reloadBranches } from '../branch/branchActions';
import { reloadDraftRows } from '../draft-row/draftRowActions';

const domainSize = process.env.REACT_APP_DRAFTCOLUMNS_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const reloadDraftColumns =
  (): ThunkAction<void, StateType<DraftColumnType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: actionFlagMap.DRAFTCOLUMN_RELOAD });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.DRAFTCOLUMN_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadDraftColumns =
  (
    fileId: number,
    branchId: number,
    tableId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<DraftColumnType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/files/${fileId}/branches/${branchId}/tables/${tableId}/columns/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.DRAFTCOLUMN_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.DRAFTCOLUMN_DATA_FAILED,
        payload: null,
      });
    }
  };

export const addDraftColumns =
  (
    fileId: number,
    branchId: number,
    tableId: number,
    payload: DraftColumnType[]
  ): ThunkAction<void, StateType<DraftColumnType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const queryPath = `/api/files/${fileId}/branches/${branchId}/tables/${tableId}/columns/`;
    try {
      const payloadBatchList = scrollChunk(payload);
      const promises = payloadBatchList.map((payloadBatch) => async () => {
        const body = JSON.stringify({ data: payloadBatch });
        const res = await axios.post(queryPath, body, config);
        return res.data;
      });
      const resData = await scrollBatch(promises);
      dispatch({
        type: actionManyMap.DRAFTCOLUMN_DATALIST_ADDED,
        payload: resData,
        path: { url: queryPath, params: {} },
      });
      dispatch(reloadBranches());
      dispatch(reloadDraftRows());
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.DRAFTCOLUMN_DATA_FAILED,
        payload: null,
      });
    }
  };

export const updateDraftColumns =
  (
    fileId: number,
    branchId: number,
    tableId: number,
    payload: DraftColumnType[]
  ): ThunkAction<void, StateType<DraftColumnType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const queryPath = `/api/files/${fileId}/branches/${branchId}/tables/${tableId}/columns/`;
    try {
      dispatch({
        type: actionManyMap.DRAFTCOLUMN_DATALIST_UPDATED,
        payload: payload,
        path: { url: queryPath, params: {} },
      });
      const payloadBatchList = scrollChunk(payload);
      const promises = payloadBatchList.map((payloadBatch) => async () => {
        const body = JSON.stringify({ data: payloadBatch });
        const res = await axios.put(queryPath, body, config);
        return res.data;
      });
      const resData = await scrollBatch(promises);
      dispatch({
        type: actionManyMap.DRAFTCOLUMN_DATALIST_UPDATED,
        payload: resData,
        path: { url: queryPath, params: {} },
      });
      dispatch(reloadBranches());
      dispatch(reloadDraftRows());
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.DRAFTCOLUMN_DATA_FAILED,
        payload: null,
      });
    }
  };

export const removeDraftColumns =
  (
    fileId: number,
    branchId: number,
    tableId: number,
    payload: DraftColumnType[]
  ): ThunkAction<void, StateType<DraftColumnType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const queryPath = `/api/files/${fileId}/branches/${branchId}/tables/${tableId}/columns/`;
    try {
      dispatch({
        type: actionManyMap.DRAFTCOLUMN_DATALIST_DELETED,
        payload: payload,
        path: { url: queryPath, params: {} },
      });
      const payloadBatchList = scrollChunk(payload);
      const promises = payloadBatchList.map((payloadBatch) => async () => {
        const payloadData = JSON.stringify({ data: payloadBatch });
        await axios.delete(queryPath, { ...config, data: payloadData });
      });
      await scrollBatch(promises);
      dispatch(reloadBranches());
      dispatch(reloadDraftRows());
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.DRAFTCOLUMN_DATA_FAILED,
        payload: null,
      });
    }
  };
