import ReactPaginate from 'react-paginate';
import './paginate.css';

const Paginate = ({
  totalPages,
  onPageClick,
  forcePage,
}: {
  totalPages: number;
  onPageClick: (selectedItem: { selected: number }) => void;
  forcePage?: number;
}) => {
  // react-paginate throws a warning when totalPages = 0
  const pageCount = Math.max(totalPages, 1);
  return (
    <ReactPaginate
      forcePage={forcePage}
      previousLabel="<<"
      nextLabel=">>"
      pageCount={pageCount}
      onPageChange={onPageClick}
      containerClassName="pagination"
      previousLinkClassName="pagination-link"
      nextLinkClassName="pagination-link"
      disabledClassName="pagination-link-disabled"
      activeClassName="pagination-link-active"
    />
  );
};

export default Paginate;
