import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import {
  actionOneMap,
  actionResponseMap,
  actionManyMap,
} from '../../common/state/types';
import type { StateType } from '../../common/state/types';
import { setAuthToken } from '../../common/utils';
import { handleError } from '../alert/alertActions';
import type UserType from '../../models/user';

const domainSize = process.env.REACT_APP_USERS_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const loadLoginUser =
  (
    token: string
  ): ThunkAction<void, StateType<UserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    setAuthToken(token);
    const queryPath = `/api/auth`;
    try {
      const res = await axios.get(queryPath);
      dispatch({
        type: 'USER_LOGINUSER_LOADED',
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USER_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadGuestUser =
  (): ThunkAction<void, StateType<UserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = '/api/users/guest/main';
    try {
      const res = await axios.get(queryPath);
      dispatch({
        type: 'USER_GUESTUSER_LOADED',
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USER_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadUserById =
  (
    userId: number
  ): ThunkAction<void, StateType<UserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = `/api/users/${userId}`;
    try {
      const res = await axios.get(queryPath);
      dispatch({
        type: actionOneMap.USER_DATA_LOADED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USER_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadUsers =
  (
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<UserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/users/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.USER_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USER_DATA_FAILED,
        payload: null,
      });
    }
  };

export const registerUser =
  (
    payload: UserType
  ): ThunkAction<void, StateType<UserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payload });
    const queryPath = `/api/auth/register`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch(loadLoginUser(res.data.token));
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USER_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loginUser =
  (
    email: UserType['email'],
    password: UserType['password']
  ): ThunkAction<void, StateType<UserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: { email, password } });
    const queryPath = `/api/auth/login`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch(loadLoginUser(res.data.token));
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USER_DATA_FAILED,
        payload: null,
      });
    }
  };

export const logoutUser =
  (): ThunkAction<void, StateType<UserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    dispatch({ type: 'USER_LOGOUTUSER' });
  };

export const loadLoginContext =
  (
    context: string
  ): ThunkAction<void, StateType<UserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    dispatch({ type: 'USER_LOGINCONTEXT', payload: context });
  };

export const updateUsers =
  (
    payload: UserType[]
  ): ThunkAction<void, StateType<UserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payload });
    const queryPath = `/api/users/`;
    try {
      dispatch({
        type: actionManyMap.USER_DATALIST_UPDATED,
        payload: payload,
        path: { url: queryPath, params: {} },
      });
      await axios.put(queryPath, body, config);
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USER_DATA_FAILED,
        payload: null,
      });
    }
  };

export const removeUsers =
  (
    payload: UserType[]
  ): ThunkAction<void, StateType<UserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
      data: JSON.stringify({ data: payload }),
    };
    const queryPath = `/api/users/`;
    try {
      dispatch({
        type: actionManyMap.USER_DATALIST_DELETED,
        payload: payload,
        path: { url: queryPath, params: {} },
      });
      await axios.delete(queryPath, config);
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USER_DATA_FAILED,
        payload: null,
      });
    }
  };
