import React from 'react';
import FileShare from '../grid';
import Dialog from '../../../../components/Dialog/Dialog';
import './share.css';

const FileShareDialog = ({
  fileId,
  params,
  open,
  setOpen,
}: {
  fileId: number;
  params?: { [k: string]: any };
  open: boolean;
  setOpen: (data: boolean) => void;
}) => {
  return (
    <Dialog selector="share-dialog" open={open} setOpen={setOpen}>
      <div className="dialog-content">
        <FileShare fileId={fileId} params={params}></FileShare>
      </div>
      <div className="menu-buttons">
        <button type="button" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
    </Dialog>
  );
};

export default FileShareDialog;
