import type FileUserType from '../../../models/file-user';
import type { ReducerType, StateType } from '../../../common/state/types';
import { initialState } from '../../../common/state/utils/constant';
import {
  actionOneMap,
  actionManyMap,
  actionResponseMap,
} from '../../../common/state/types';
import {
  dataListLoadedReducer,
  dataListAddedReducer,
  dataListUpdatedReducer,
  dataListDeletedReducer,
  dataLoadedReducer,
  dataFailedReducer,
} from '../../../common/state/reducers';

const userReducer: ReducerType<FileUserType> = (
  state = {
    active: initialState as StateType<FileUserType>,
    rollback: initialState as StateType<FileUserType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.USERBYFILE_DATALIST_LOADED:
      return dataListLoadedReducer<FileUserType>({
        state,
        action,
      });
    case actionOneMap.USERBYFILE_DATA_LOADED:
      return dataLoadedReducer<FileUserType>({ state, action });
    case actionManyMap.USERBYFILE_DATALIST_ADDED:
      return dataListAddedReducer<FileUserType>({
        state,
        action,
      });
    case actionManyMap.USERBYFILE_DATALIST_UPDATED:
      return dataListUpdatedReducer<FileUserType>({
        state,
        action,
      });
    case actionManyMap.USERBYFILE_DATALIST_DELETED:
      return dataListDeletedReducer<FileUserType>({
        state,
        action,
      });
    case actionResponseMap.USERBYFILE_DATA_FAILED:
      return dataFailedReducer<FileUserType>({ state, action });
    default:
      return state;
  }
};

export default userReducer;
