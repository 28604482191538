import { useEffect } from 'react';
// https://github.com/adazzle/react-data-grid/issues/3314

const WHITE_LIST = [
  'ResizeObserver loop completed with undelivered notifications.',
];

const useDataGridErrorSuppressor = () => {
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (WHITE_LIST.includes(e.message)) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
};

export default useDataGridErrorSuppressor;
