import _ from 'lodash';
import {
  GenericObjType,
  ModelMetaType,
  ModelRowNumType,
} from '../../../common/types';
import { FlagsType } from '../../../common/state/types';
import { DataGridHandle } from 'react-data-grid';

const getPageByRowNum = <T extends GenericObjType>(
  rowNum: number,
  rows: ModelRowNumType<T>[],
  rowsMetaList: ModelMetaType[]
) => {
  const pageMeta = _.find(rowsMetaList, (meta) => {
    return rowNum >= meta.startRecord && rowNum <= meta.endRecord;
  });
  return pageMeta;
};

const calculateRowInx = (
  event: React.UIEvent<HTMLDivElement>,
  rowHeight: number
) => {
  const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
  const scrollContent = scrollHeight - scrollTop;
  const scrollNext = clientHeight === scrollContent;
  const topRowInx = Math.ceil(scrollTop / rowHeight);
  const bottomRowInx = Math.floor((scrollTop + clientHeight) / rowHeight) - 1;
  return { topRowInx, bottomRowInx, scrollNext };
};

const getAdjacentPages = (
  currentPageInx: number,
  rowsMetaList: ModelMetaType[]
) => {
  const currentPage = currentPageInx + 1;
  const currentPageMeta = _.find(rowsMetaList, { currentPage });
  if (_.isEmpty(currentPageMeta)) return {};
  const lastPageInx = currentPageMeta.totalPages - 1;
  const beforePageInx = currentPageInx - 1;
  const nextPageInx = currentPageInx + 1;
  return {
    current: currentPageInx,
    before: currentPageInx >= 1 ? beforePageInx : null,
    after: nextPageInx <= lastPageInx ? nextPageInx : null,
  };
};

const loadAdjacentPages = <T>(
  currentPageInx: number,
  rows: ModelRowNumType<T>[],
  rowsMetaList: ModelMetaType[],
  loadPage: (pageInx: number, flags?: Partial<FlagsType>) => void
) => {
  const pages = getAdjacentPages(currentPageInx, rowsMetaList);
  const pageInxList = Object.values(pages).filter((value) => value !== null);
  const pagesNotLoaded = pageInxList.filter((pageInx) => {
    const pageMeta = _.find(rowsMetaList, { currentPage: pageInx + 1 });
    const first = _.find(rows, { rowNum: pageMeta?.startRecord });
    const last = _.find(rows, { rowNum: pageMeta?.endRecord });
    return _.isEmpty(first) || _.isEmpty(last);
  });
  if (_.isEmpty(pagesNotLoaded)) return;
  pagesNotLoaded.forEach((pageInx) => {
    loadPage(pageInx);
  });
};

const scrollGrid = <T>(
  gridRef: React.RefObject<DataGridHandle>,
  rows: ModelRowNumType<T>[],
  columnInx: number,
  rowNum: number
) => {
  const rowInx = rows.findIndex((item) => item.rowNum === rowNum);
  if (rowInx === -1) return;
  gridRef?.current?.selectCell({
    idx: columnInx,
    rowIdx: rowInx,
  });
};

const handlePageChange = <T>(
  currentRowNum: number,
  rows: ModelRowNumType<T>[],
  rowsMetaList: ModelMetaType[],
  onPageChange: React.Dispatch<React.SetStateAction<number>>
) => {
  const currentPageMeta = getPageByRowNum(currentRowNum, rows, rowsMetaList);
  if (_.isEmpty(currentPageMeta)) return;
  const currentPageInx = currentPageMeta.currentPage - 1;
  onPageChange(currentPageInx);
  return currentPageMeta;
};

const handlePageLoad = <T>(
  currentRowNum: number,
  rows: ModelRowNumType<T>[],
  rowsMetaList: ModelMetaType[],
  loadPageQueue: number[],
  loadPage: (pageInx: number, flags?: Partial<FlagsType>) => void
) => {
  const currentPageMeta = getPageByRowNum(currentRowNum, rows, rowsMetaList);
  if (_.isEmpty(currentPageMeta)) return loadPageQueue;
  const currentPageInx = currentPageMeta.currentPage - 1;
  if (loadPageQueue.includes(currentPageInx)) return loadPageQueue;
  const endGap = currentPageMeta.endRecord - currentRowNum;
  const startGap = currentRowNum - currentPageMeta.startRecord;
  const loadPages = endGap <= 10 || startGap <= 10;
  if (!loadPages) return loadPageQueue;
  loadAdjacentPages(currentPageInx, rows, rowsMetaList, loadPage);
  return [...loadPageQueue, currentPageInx];
};

const detectPageStartRowNum = (
  currentPageNum: number,
  rowsMetaList: ModelMetaType[]
) => {
  const endStartGapList = rowsMetaList.map(
    (item) => item.endRecord - item.startRecord + 1
  );
  const pageSize = Math.max(...endStartGapList);
  const pageEndRowNum = currentPageNum * pageSize;
  const pageStartRowNum = pageEndRowNum - pageSize + 1;
  return pageStartRowNum;
};

export {
  getAdjacentPages,
  loadAdjacentPages,
  getPageByRowNum,
  calculateRowInx,
  scrollGrid,
  handlePageLoad,
  handlePageChange,
  detectPageStartRowNum,
};
