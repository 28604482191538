import { useSelector } from 'react-redux';
import { AlertType } from '../../state/alert/alertTypes';
import { RootState } from '../../store';
import './alert.css';

const Alert = () => {
  const alerts = useSelector((state: RootState) => state.alert.active.data);
  if (alerts.length === 0) return null;
  const uniqueAlerts = alerts.reduce(
    (unique: AlertType[], alert: AlertType) => {
      const matchFound = (uniqueAlert: AlertType) => {
        return uniqueAlert.msg === alert.msg;
      };
      const hasDup = unique.some(matchFound);
      if (!hasDup) unique.push(alert);
      return unique;
    },
    []
  );
  return (
    <div className="alert-container">
      {uniqueAlerts.map((alert) => {
        return (
          <div key={alert.id} className="alert">
            <div className={`alert alert-${alert.type}`}>{alert.msg}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Alert;
