import type DraftRowType from '../../../models/draft-row';
import type { ReducerType, StateType } from '../../../common/state/types';
import { initialState } from '../../../common/state/utils/constant';
import { actionResponseMap } from '../../../common/state/types';
import {
  dataListLoadedReducer,
  dataFailedReducer,
} from '../../../common/state/reducers';

const draftRowReducer: ReducerType<DraftRowType> = (
  state = {
    active: initialState as StateType<DraftRowType>,
    rollback: initialState as StateType<DraftRowType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.DRAFTROWMERGEDIFF_DATALIST_LOADED:
      return dataListLoadedReducer<DraftRowType>({
        state,
        action,
      });
    case actionResponseMap.DRAFTROWMERGEDIFF_DATA_FAILED:
      return dataFailedReducer<DraftRowType>({ state, action });
    default:
      return state;
  }
};

export default draftRowReducer;
