import _ from 'lodash';
import qs from 'qs';
import axios, { AxiosResponse } from 'axios';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../../../store';
import { GenericObjType } from '../../../../common/types';
import './url.css';

const ConnectURL = ({
  resourceVersion,
  resourceUrl,
  params,
  onSend,
}: {
  resourceVersion: string;
  resourceUrl: string;
  params: GenericObjType;
  onSend: (a: AxiosResponse) => void;
}) => {
  const host = window.location.host;
  const protocol = window.location.protocol;
  const locationOrigin = `${protocol}//${host}/${resourceVersion}`;

  const [clickUrl, setClickUrl] = useState(false);
  const [url, setUrl] = useState(locationOrigin);
  const currentFile = useSelector(
    (state: RootState) => state.file.active.selected
  );

  const adjustTextareaHeight = (element: HTMLTextAreaElement) => {
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + 'px';
  };

  useEffect(() => {
    const columnParams = params.where;
    const columnOptions = _.omit(params, 'where');
    const hasFile = !_.isEmpty(currentFile);
    const hasParams = !_.isEmpty(columnParams) || !_.isEmpty(columnOptions);
    const paramsCon = hasParams ? '?' : '';
    if (hasFile) {
      setUrl(
        `${locationOrigin}/${resourceUrl}${paramsCon}${qs.stringify(params)}`
      );
    }
  }, [currentFile, locationOrigin, resourceUrl, params]);

  const handleSend = async () => {
    const res = await axios.get(`${locationOrigin}/${resourceUrl}`, {
      params: params,
      paramsSerializer: (items) => qs.stringify(items),
    });
    onSend(res);
  };

  return (
    <div className="url-container">
      <div className="url-action-container">
        <FontAwesomeIcon
          className="url-get-icon"
          icon={faPlus}
        ></FontAwesomeIcon>
        <button className="url-action">GET</button>
        <span>|</span>
      </div>
      {clickUrl ? (
        <textarea
          className="url-text"
          placeholder="https://"
          value={url}
          readOnly={true}
          onKeyDown={(e) => {
            if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
              e.stopPropagation();
            }
          }}
          onFocus={(e) => {
            adjustTextareaHeight(e.target);
            setUrl(e.target.value);
          }}
          onBlur={() => setClickUrl(false)}
        />
      ) : (
        <input
          className="url-text"
          placeholder="https://"
          value={url}
          readOnly={true}
          onClick={() => setClickUrl(true)}
        />
      )}
      <div className="url-send-container">
        <FontAwesomeIcon
          className="url-send-icon"
          icon={faShareFromSquare}
        ></FontAwesomeIcon>
        <button className="url-send" onClick={() => handleSend()}>
          SEND
        </button>
      </div>
    </div>
  );
};

export default ConnectURL;
