import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import {
  actionOneMap,
  actionResponseMap,
  actionManyMap,
} from '../../common/state/types';
import { setLoginGroup } from '../../common/utils';
import type { StateType } from '../../common/state/types';
import type GroupType from '../../models/group';
import { handleError } from '../alert/alertActions';

const domainSize = process.env.REACT_APP_GROUPS_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const loadLoginGroup =
  (
    groupId: number
  ): ThunkAction<void, StateType<GroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    setLoginGroup(groupId);
    const queryPath = `/api/groups/${groupId}`;
    try {
      const res = await axios.get(queryPath);
      dispatch({
        type: 'GROUP_LOGINGROUP_LOADED',
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUP_DATA_FAILED,
        payload: null,
      });
    }
  };

export const logoutGroup =
  (): ThunkAction<void, StateType<GroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: 'GROUP_LOGOUTGROUP',
    });
  };

export const loadPublicGroup =
  (): ThunkAction<void, StateType<GroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = '/api/groups/public/main';
    try {
      const res = await axios.get(queryPath);
      dispatch({
        type: 'GROUP_PUBLICGROUP_LOADED',
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUP_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadGroups =
  (
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<GroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/groups/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.GROUP_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUP_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadGroupById =
  (
    groupId: number
  ): ThunkAction<void, StateType<GroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = `/api/groups/${groupId}`;
    try {
      const res = await axios.get(queryPath);
      dispatch({
        type: actionOneMap.GROUP_DATA_LOADED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUP_DATA_FAILED,
        payload: null,
      });
    }
  };

export const addGroups =
  (
    payload: GroupType[]
  ): ThunkAction<void, StateType<GroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payload });
    const queryPath = `/api/groups/`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch({
        type: actionManyMap.GROUP_DATALIST_ADDED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUP_DATA_FAILED,
        payload: null,
      });
    }
  };

export const updateGroups =
  (
    payload: GroupType[]
  ): ThunkAction<void, StateType<GroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payload });
    const queryPath = `/api/groups/`;
    try {
      dispatch({
        type: actionManyMap.GROUP_DATALIST_UPDATED,
        payload: payload,
        path: { url: queryPath, params: {} },
      });
      await axios.put(queryPath, body, config);
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUP_DATA_FAILED,
        payload: null,
      });
    }
  };

export const removeGroups =
  (
    payload: GroupType[]
  ): ThunkAction<void, StateType<GroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
      data: JSON.stringify({ data: payload }),
    };
    const queryPath = `/api/groups/`;
    try {
      dispatch({
        type: actionManyMap.GROUP_DATALIST_DELETED,
        payload: payload,
        path: { url: queryPath, params: {} },
      });
      await axios.delete(queryPath, config);
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUP_DATA_FAILED,
        payload: null,
      });
    }
  };
