import type { PageResponseType, QueryParamsType } from '../types';
import { actionTypeList } from './utils/constant';
import { createActionMap, createActionList } from './utils';

type ActionTypeList = typeof actionTypeList;
type ActionLiteralDomain = ActionTypeList['domain'][number];
type ActionLiteralOne = ActionTypeList['one'][number];
type ActionLiteralMany = ActionTypeList['many'][number];
type ActionLiteralResponse = ActionTypeList['response'][number];
type ActionLiteralFlag = ActionTypeList['flag'][number];

type ActionDomainOneType = `${ActionLiteralDomain}_${ActionLiteralOne}`;
type ActionDomainManyType = `${ActionLiteralDomain}_${ActionLiteralMany}`;
type ActionDomainResponseType =
  `${ActionLiteralDomain}_${ActionLiteralResponse}`;
type ActionDomainFlagType = `${ActionLiteralDomain}_${ActionLiteralFlag}`;

export type ResourcePathType<ModelType> = {
  url: string;
  params: QueryParamsType<ModelType>;
};

export type FlagsType = {
  loading: boolean;
  reload: boolean;
};

export type StateType<ModelType, CustomType = any> = {
  flags: FlagsType;
  data: PageResponseType<ModelType>['data'];
  meta: PageResponseType<ModelType>['meta'];
  metaList: PageResponseType<ModelType>['meta'][];
  pageMeta?: PageResponseType<ModelType>['meta'];
  custom?: CustomType;
  selected?: ModelType;
  first?: ModelType;
  path?: ResourcePathType<ModelType>;
};

export type StateCollectionType<ModelType, CustomType = any> = {
  active: StateType<ModelType, CustomType>;
  rollback: StateType<ModelType, CustomType>;
};

const actionOneList = createActionList<ActionDomainOneType>('one');
const actionManyList = createActionList<ActionDomainManyType>('many');
const actionResponseList =
  createActionList<ActionDomainResponseType>('response');
const actionFlagList = createActionList<ActionDomainFlagType>('flag');

export const actionOneMap = createActionMap(actionOneList);
export const actionManyMap = createActionMap(actionManyList);
export const actionResponseMap = createActionMap(actionResponseList);
export const actionFlagMap = createActionMap(actionFlagList);

export interface ActionFlagType {
  type: ActionDomainFlagType;
  payload: { loading: boolean; reload: boolean; jumpPage: boolean };
}

export interface ActionOneType<ModelType> {
  type: ActionDomainOneType;
  payload: ModelType;
  path?: ResourcePathType<ModelType>;
}

export interface ActionManyType<ModelType> {
  type: ActionDomainManyType;
  payload: PageResponseType<ModelType>['data'];
  path?: ResourcePathType<ModelType>;
}

export interface ActionResponseType<ModelType> {
  type: ActionDomainResponseType;
  payload: PageResponseType<ModelType>;
  path?: ResourcePathType<ModelType>;
}

export type ReducerType<ModelType, CustomType = any, CustomActionType = any> = (
  state: StateCollectionType<ModelType, CustomType> | undefined,
  action:
    | ActionFlagType
    | ActionOneType<ModelType>
    | ActionManyType<ModelType>
    | ActionResponseType<ModelType>
    | CustomActionType
) => StateCollectionType<ModelType, CustomType>;
