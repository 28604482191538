import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { actionResponseMap } from '../../common/state/types';
import type ColumnType from '../../models/column';
import type { StateType } from '../../common/state/types';
import { handleError } from '../alert/alertActions';

const domainSize = process.env.REACT_APP_COLUMNS_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const loadColumns =
  (
    fileId: number,
    tableId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<ColumnType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/files/${fileId}/tables/${tableId}/columns/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.COLUMN_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.COLUMN_DATA_FAILED,
        payload: null,
      });
    }
  };
