import { useNavigate } from 'react-router-dom';
import DraftTableList from '../../../../domain/draft-table/merge-diff/list/DraftTable';
import type DraftTableType from '../../../../models/draft-table';
import './footer.css';

const Footer = ({
  fileId,
  branchId,
  submitId,
  tableId,
}: {
  fileId: number;
  branchId: number;
  submitId: number;
  tableId?: number;
}) => {
  const navigate = useNavigate();
  const handleSelectTable = (currentDraftTable: DraftTableType) => {
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const submitPath = `/submits/${submitId}`;
    const draftTablePath = `/tables/${currentDraftTable.tableId}`;
    const redirectPath = `/reviews${filePath}${branchPath}${submitPath}${draftTablePath}`;
    navigate({ pathname: redirectPath });
  };

  return (
    <div className="review-footer-container">
      <div className="footer-left">
        <DraftTableList
          fileId={fileId}
          branchId={branchId}
          selectedRowFilter={{ tableId, branchId }}
          onSelectTable={handleSelectTable}
        ></DraftTableList>
      </div>
      <div className="footer-center"></div>
      <div className="footer-right"></div>
    </div>
  );
};
export default Footer;
