import React, { useState, useEffect } from 'react';
import Dialog from '../../../components/Dialog/Dialog';

const FileUploadDialog = ({
  fileId,
  branchId,
  open,
  setOpen,
}: {
  fileId: number;
  branchId: number;
  open: boolean;
  setOpen: (data: boolean) => void;
}) => {
  const [fileData, setFileData] = useState<File | null>(null);
  const [result, setResult] = useState<number | null>(null);
  const [worker, setWorker] = useState<Worker | null>(null);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    console.log(event);
    if (event.target.files) {
      setFileData(event.target.files[0]);
    }
  };

  const handleFileUpload = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!fileData) {
      alert('Please select a file first.');
      return;
    }
    if (worker) {
      worker.postMessage({ fileId, branchId, fileData });
    }
  };

  useEffect(() => {
    const myWorker = new Worker(
      new URL('../../../workers/load/worker.ts', import.meta.url)
    );
    myWorker.onmessage = function (event) {
      console.log('Received result from worker:', event.data);
      setResult(event.data);
    };
    setWorker(myWorker);
    return () => {
      myWorker.terminate();
    };
  }, []);

  return (
    <Dialog selector="file-upload-dialog" open={open} setOpen={setOpen}>
      <div>
        <h2>Upload Excel File</h2>
        <form onSubmit={handleFileUpload}>
          <input type="file" onChange={handleFileChange} />
          <button type="submit">Upload</button>
          <div>{result}</div>
        </form>
      </div>
    </Dialog>
  );
};

export default FileUploadDialog;
