import type UserGroupType from '../../../models/user-group';
import type { ReducerType, StateType } from '../../../common/state/types';
import { initialState } from '../../../common/state/utils/constant';
import {
  actionOneMap,
  actionManyMap,
  actionResponseMap,
} from '../../../common/state/types';
import {
  dataListLoadedReducer,
  dataListAddedReducer,
  dataListUpdatedReducer,
  dataListDeletedReducer,
  dataLoadedReducer,
  dataFailedReducer,
} from '../../../common/state/reducers';

const userReducer: ReducerType<UserGroupType> = (
  state = {
    active: initialState as StateType<UserGroupType>,
    rollback: initialState as StateType<UserGroupType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.USERBYGROUP_DATALIST_LOADED:
      return dataListLoadedReducer<UserGroupType>({
        state,
        action,
      });
    case actionOneMap.USERBYGROUP_DATA_LOADED:
      return dataLoadedReducer<UserGroupType>({ state, action });
    case actionManyMap.USERBYGROUP_DATALIST_ADDED:
      return dataListAddedReducer<UserGroupType>({
        state,
        action,
      });
    case actionManyMap.USERBYGROUP_DATALIST_UPDATED:
      return dataListUpdatedReducer<UserGroupType>({
        state,
        action,
      });
    case actionManyMap.USERBYGROUP_DATALIST_DELETED:
      return dataListDeletedReducer<UserGroupType>({
        state,
        action,
      });
    case actionResponseMap.USERBYGROUP_DATA_FAILED:
      return dataFailedReducer<UserGroupType>({ state, action });
    default:
      return state;
  }
};

export default userReducer;
