export default class WorkerManager {
  private static workers: { [key: string]: SharedWorker } = {};
  static getWorker(name: string, workerModule: () => void): SharedWorker {
    if (this.workers[name] && this.workers[name].port) {
      return this.workers[name];
    } else {
      const code = workerModule.toString();
      const blob = new Blob([`(${code})()`], {
        type: 'application/javascript',
      });
      const worker = new SharedWorker(URL.createObjectURL(blob), {
        name,
      });
      this.workers[name] = worker;
      return worker;
    }
  }
}
