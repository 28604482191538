import _ from 'lodash';
import {
  faPencil,
  faFilter,
  faSquarePlus,
  faSquareMinus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGrid,
  textEditorNorm,
  SelectColumn,
  SelectOptions,
  EmptyDataGrid,
  HeaderRenderer,
  FilterContext,
  FilterFlagContext,
  pager,
} from '../../../components/DataGrid/DataGrid';
import Paginate from '../../../components/Paginate/Paginate';
import DiffText from '../../../components/DiffText';
import { dataTypeList } from '../../../common/constants';
import { isNothing, parseDiff } from '../../../common/utils';
import { GenericObjType, FilterType } from '../../../common/types';
import {
  loadDraftColumns,
  addDraftColumns,
  updateDraftColumns,
  removeDraftColumns,
} from '../../../state/draft-column/draftColumnActions';
import { loadFileById } from '../../../state/file/fileActions';
import { loadBranchById } from '../../../state/branch/branchActions';
import { loadDraftTableById } from '../../../state/draft-table/draftTableActions';
import { RootState } from '../../../store';
import type DraftColumnType from '../../../models/draft-column';
import './draftColumn.css';
import AddDialog from './dialog/add/Add';
import RemoveDialog from './dialog/remove/Remove';

const DraftColumn = ({
  fileId,
  branchId,
  tableId,
  params,
  lockTable = false,
  onFilter = () => {},
  searchParams = {},
}: {
  fileId: number;
  branchId: number;
  tableId: number;
  params?: { [k: string]: any };
  lockTable?: boolean;
  searchParams?: GenericObjType;
  onFilter?: (a: GenericObjType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const baseSearchParams = useMemo(() => searchParams || {}, [searchParams]);
  const dispatch = useDispatch();
  const draftColumns = useSelector(
    (state: RootState) => state.draftColumn.active.data
  );
  const draftColumnsReload = useSelector(
    (state: RootState) => state.draftColumn.active.flags.reload
  );
  const branchesReload = useSelector(
    (state: RootState) => state.branch.active.flags.reload
  );
  const draftColumnsMetaList = useSelector(
    (state: RootState) => state.draftColumn.active.metaList
  );
  const draftColumnsMeta = useSelector(
    (state: RootState) => state.draftColumn.active.meta
  );
  const [selectedRows, setSelectedRows] = useState((): DraftColumnType[] => []);
  const hasSelectedRows = !_.isEmpty(selectedRows);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [editable, setEditable] = useState(false);
  const [forcePage, setForcePage] = useState(0);
  const [selectedPageNum, setSelectedPageNum] = useState(0);
  const [selectedPageRowNum, setSelectedPageRowNum] = useState(0);

  const userColumns = [
    SelectColumn,
    {
      key: 'rowNum',
      name: 'Row',
      maxWidth: 50,
      frozen: true,
      resizable: true,
    },
    {
      key: 'status',
      name: 'Status',
      resizable: true,
      maxWidth: 100,
    },
    {
      key: 'name',
      name: 'Column Name',
      editable: editable,
      resizable: true,
      renderEditCell: (props: any) => {
        const rowValue = props.row.name;
        const rowDiff = parseDiff(rowValue);
        if (!rowDiff.isDiff) return textEditorNorm(props);
        return textEditorNorm({
          ...props,
          row: {
            ...props.row,
            name: `${rowDiff.value.before} ${rowDiff.value.after}`,
          },
        });
      },
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'name'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      renderCell: ({ row }: { row: DraftColumnType }) => {
        const rowValue = row.name;
        const rowDiff = parseDiff(rowValue);
        if (!rowDiff.isDiff) return rowDiff.value;
        return (
          <>
            <DiffText keyValue={rowDiff.value}></DiffText>
          </>
        );
      },
    },
    {
      key: 'type',
      name: 'Type',
      editable: editable,
      renderEditCell: (p: any) => {
        const targetColumn = 'type';
        return (
          <SelectOptions<{ [targetColumn]: string }>
            row={p.row}
            targetColumn={targetColumn}
            onRowChange={p.onRowChange}
            values={dataTypeList}
          ></SelectOptions>
        );
      },
      width: 100,
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'map'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      resizable: true,
    },
    {
      key: 'createdAt',
      name: 'Date Added',
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'createdAt'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      width: 200,
      resizable: true,
    },
    {
      key: 'updatedAt',
      name: 'Date Modified',
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'updatedAt'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      width: 200,
      resizable: true,
    },
  ];

  const [filterFlag, setFilterFlag] = useState(!isNothing(searchParams));
  const [filters, setFilters] = useState(baseSearchParams);

  const handleAddRecords = (data: DraftColumnType) => {
    const payload: DraftColumnType[] = [data];
    dispatch(addDraftColumns(fileId, branchId, tableId, payload));
  };

  const handleUpdateRecords = (updatedData: DraftColumnType[]) => {
    dispatch(updateDraftColumns(fileId, branchId, tableId, updatedData));
  };

  const handleRemoveRecords = () => {
    dispatch(removeDraftColumns(fileId, branchId, tableId, selectedRows));
    setSelectedRows([]);
  };

  const handleRowSelection = (selectedData: DraftColumnType[]) => {
    setSelectedRows(selectedData);
  };

  const debouncedOnFilter = useRef(
    _.debounce((currentFilters) => {
      dispatch(
        loadDraftColumns(fileId, branchId, tableId, {
          ...baseParams,
          where: { ...currentFilters, ...baseParams.where },
        })
      );
    }, 1000)
  ).current;

  const handleFilter = (currentFilters: FilterType<DraftColumnType>) => {
    setFilters(currentFilters);
    debouncedOnFilter(currentFilters);
    onFilter(currentFilters);
  };

  const handleToggleFilter = (isEnabled: boolean) => {
    const currentFilters = isEnabled ? filters : {};
    setFilterFlag(isEnabled);
    handleFilter(currentFilters);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    const currentPageNum = currentPageInx + 1;
    const pageStartRowNum = pager.detectPageStartRowNum(
      currentPageNum,
      draftColumnsMetaList
    );
    setSelectedPageNum(currentPageNum);
    setSelectedPageRowNum(pageStartRowNum);
  };

  const loadPage = useCallback(
    (pageInx: number) => {
      const currentPage = pageInx + 1;
      const pageMeta = _.find(draftColumnsMetaList, { currentPage });
      if (!_.isEmpty(pageMeta)) return pageMeta;
      dispatch(
        loadDraftColumns(fileId, branchId, tableId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          page: currentPage,
        })
      );
      return pageMeta;
    },
    [
      dispatch,
      fileId,
      branchId,
      tableId,
      baseParams,
      draftColumnsMetaList,
      filters,
    ]
  );

  useEffect(() => {
    if (branchesReload) {
      dispatch(loadBranchById(fileId, branchId));
    }
  }, [dispatch, fileId, branchId, branchesReload]);

  useEffect(() => {
    if (draftColumnsReload) {
      dispatch(
        loadDraftColumns(fileId, branchId, tableId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
        })
      );
    }
  }, [
    dispatch,
    fileId,
    branchId,
    tableId,
    baseParams,
    filters,
    draftColumnsReload,
  ]);

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadBranchById(fileId, branchId));
    dispatch(loadDraftTableById(fileId, branchId, tableId));
    dispatch(
      loadDraftColumns(fileId, branchId, tableId, {
        ...baseParams,
        where: { ...filters, ...baseParams.where },
      })
    );
  }, [dispatch, fileId, branchId, tableId, filters, baseParams]);

  const isEmptyGrid = userColumns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="draft-column-container">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="draft-column-container">
      <FilterFlagContext.Provider value={filterFlag}>
        <FilterContext.Provider value={filters}>
          <div className="grid-toolbar">
            <div className="left-toolbar">
              <button
                type="button"
                disabled={!editable}
                onClick={() => setOpenAddDialog(true)}
              >
                <FontAwesomeIcon icon={faSquarePlus} />
                <span className="add icon-label">Add</span>
              </button>
              <button
                type="button"
                disabled={!hasSelectedRows || !editable}
                onClick={() => setOpenRemoveDialog(true)}
              >
                <FontAwesomeIcon icon={faSquareMinus} />
                <span className="remove icon-label">Remove</span>
              </button>
            </div>
            <div className="center-toolbar">
              <Paginate
                totalPages={Number(draftColumnsMeta?.totalPages)}
                onPageClick={handlePageClick}
                forcePage={forcePage}
              />
            </div>
            <div className="right-toolbar">
              <button
                className={`filterable ${filterFlag ? 'selected' : ''}`}
                onClick={() => handleToggleFilter(!filterFlag)}
              >
                <FontAwesomeIcon icon={faFilter} />
                <span className="filter icon-label">Filter</span>
              </button>
              <button
                className={`editable ${editable ? 'selected' : ''}`}
                onClick={() => setEditable(!editable)}
                disabled={lockTable}
              >
                <FontAwesomeIcon icon={faPencil} />
                <span className="edit icon-label">Edit</span>
              </button>
            </div>
          </div>
          <DataGrid<DraftColumnType>
            rowKeyGetter={(row) => String(row.id)}
            columns={userColumns}
            rows={draftColumns}
            rowsMetaList={draftColumnsMetaList}
            onPageChange={setForcePage}
            loadPage={loadPage}
            selectedPageNum={selectedPageNum}
            selectedPageRowNum={selectedPageRowNum}
            rowHeight={30}
            onUpdate={handleUpdateRecords}
            onRowSelection={handleRowSelection}
          ></DataGrid>
          {openAddDialog ? (
            <AddDialog
              open={openAddDialog}
              setOpen={setOpenAddDialog}
              onSubmit={handleAddRecords}
            ></AddDialog>
          ) : null}
          {openRemoveDialog ? (
            <RemoveDialog
              open={openRemoveDialog}
              setOpen={setOpenRemoveDialog}
              onSubmit={handleRemoveRecords}
            ></RemoveDialog>
          ) : null}
        </FilterContext.Provider>
      </FilterFlagContext.Provider>
    </div>
  );
};

export default DraftColumn;
