import _ from 'lodash';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DataGrid,
  EmptyDataGrid,
  HeaderRenderer,
  FilterContext,
  FilterFlagContext,
  pager,
} from '../../../../components/DataGrid/DataGrid';
import { isNothing } from '../../../../common/utils';
import { GenericObjType, FilterType } from '../../../../common/types';
import { loadSubmits } from '../../../../state/process-log/submit/submitActions';
import { RootState } from '../../../../store';
import type ProcessLogType from '../../../../models/process-log';
import './submit.css';
import Paginate from '../../../../components/Paginate/Paginate';

const Submit = ({
  params,
  onSelect,
  onFilter = () => {},
  searchParams = {},
}: {
  params?: { [k: string]: any };
  onSelect?: (selectedData: ProcessLogType) => void;
  searchParams?: GenericObjType;
  onFilter?: (a: GenericObjType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const baseSearchParams = useMemo(() => searchParams || {}, [searchParams]);
  const dispatch = useDispatch();
  const submits = useSelector((state: RootState) => state.submit.active.data);
  const submitsMeta = useSelector(
    (state: RootState) => state.submit.active.meta
  );
  const submitsMetaList = useSelector(
    (state: RootState) => state.submit.active.metaList
  );
  const submitsReload = useSelector(
    (state: RootState) => state.submit.active.flags.reload
  );
  const [forcePage, setForcePage] = useState(0);
  const [selectedPageNum, setSelectedPageNum] = useState(0);
  const [selectedPageRowNum, setSelectedPageRowNum] = useState(0);

  const columns = [
    {
      key: 'rowNum',
      name: 'Row',
      width: 50,
    },
    {
      key: 'file',
      name: 'File Name',
      headerCellClass: 'filter-cell',
      renderCell: ({ row }: { row: ProcessLogType }) => row.file?.name,
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'file.name'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      resizable: true,
    },
    {
      key: 'branch',
      name: 'Branch Name',
      headerCellClass: 'filter-cell',
      renderCell: ({ row }: { row: ProcessLogType }) => row.branch?.name,
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'branch.name'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      resizable: true,
      width: 200,
    },
    {
      key: 'message',
      name: 'Message',
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'message'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      width: 200,
    },
    {
      key: 'status',
      name: 'Status',
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'status'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      width: 100,
    },
    {
      key: 'statusMessage',
      name: 'Status Message',
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'statusMessage'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      width: 150,
    },
    {
      key: 'user',
      name: 'Modified By',
      headerCellClass: 'filter-cell',
      renderCell: ({ row }: { row: ProcessLogType }) => row.user?.name,
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'user.name'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      resizable: true,
      width: 200,
    },
    {
      key: 'createdAt',
      name: 'Date Added',
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'createdAt'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      width: 200,
      resizable: true,
    },
    {
      key: 'updatedAt',
      name: 'Date Modified',
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'updatedAt'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      width: 200,
      resizable: true,
    },
  ];

  const [filterFlag, setFilterFlag] = useState(!isNothing(searchParams));
  const [filters, setFilters] = useState(baseSearchParams);

  const debouncedOnFilter = useRef(
    _.debounce((currentFilters) => {
      dispatch(
        loadSubmits({
          ...baseParams,
          where: { ...currentFilters, ...baseParams.where },
          include: [{ model: 'branch' }, { model: 'user' }],
        })
      );
    }, 1000)
  ).current;

  const handleFilter = (currentFilters: FilterType<ProcessLogType>) => {
    setFilters(currentFilters);
    debouncedOnFilter(currentFilters);
    onFilter(currentFilters);
  };

  const handleToggleFilter = (isEnabled: boolean) => {
    const currentFilters = isEnabled ? filters : {};
    setFilterFlag(isEnabled);
    handleFilter(currentFilters);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    const currentPageNum = currentPageInx + 1;
    const pageStartRowNum = pager.detectPageStartRowNum(
      currentPageNum,
      submitsMetaList
    );
    setSelectedPageNum(currentPageNum);
    setSelectedPageRowNum(pageStartRowNum);
  };

  const loadPage = useCallback(
    (pageInx: number) => {
      const currentPage = pageInx + 1;
      const pageMeta = _.find(submitsMetaList, { currentPage });
      if (!_.isEmpty(pageMeta)) return pageMeta;
      dispatch(
        loadSubmits({
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          include: [{ model: 'branch' }, { model: 'user' }],
          page: currentPage,
        })
      );
      return pageMeta;
    },
    [dispatch, baseParams, submitsMetaList, filters]
  );

  useEffect(() => {
    if (submitsReload) {
      dispatch(
        loadSubmits({
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          include: [{ model: 'branch' }, { model: 'user' }],
        })
      );
    }
  }, [dispatch, baseParams, filters, submitsReload]);

  useEffect(() => {
    dispatch(
      loadSubmits({
        ...baseParams,
        where: { ...filters, ...baseParams.where },
        include: [{ model: 'branch' }, { model: 'user' }],
      })
    );
  }, [dispatch, filters, baseParams]);

  const isEmptyGrid = columns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="submit-container">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="submit-container">
      <FilterFlagContext.Provider value={filterFlag}>
        <FilterContext.Provider value={filters}>
          <div className="grid-toolbar">
            <div className="left-toolbar"></div>
            <div className="center-toolbar">
              <Paginate
                totalPages={Number(submitsMeta?.totalPages)}
                onPageClick={handlePageClick}
                forcePage={forcePage}
              />
            </div>
            <div className="right-toolbar">
              <button
                className={`filterable ${filterFlag ? 'selected' : ''}`}
                onClick={() => handleToggleFilter(!filterFlag)}
              >
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </div>
          </div>
          <DataGrid<ProcessLogType>
            rowKeyGetter={(row) => String(row.id)}
            columns={columns}
            rows={submits}
            rowsMetaList={submitsMetaList}
            rowHeight={30}
            onPageChange={setForcePage}
            loadPage={loadPage}
            selectedPageNum={selectedPageNum}
            selectedPageRowNum={selectedPageRowNum}
          ></DataGrid>
        </FilterContext.Provider>
      </FilterFlagContext.Provider>
    </div>
  );
};

export default Submit;
