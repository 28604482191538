const branchWorker = () => {
  /* eslint-disable-next-line no-restricted-globals */
  self.addEventListener(
    'connect',
    (connectEvent: any) => {
      const port = (connectEvent.ports as MessagePort[])[0];

      port.addEventListener(
        'message',
        async (messageEvent: MessageEvent) => {
          if (!messageEvent) return;

          const branchMessage = messageEvent.data;
          const headers = branchMessage?.headers;
          const payload = branchMessage?.payload;
          /* eslint-disable-next-line no-restricted-globals */
          const host = self.location?.origin;

          const filePath = `/api/files/${payload?.fileId}`;
          const branchPath = `/branches/${payload?.id}`;
          const url = `${host}${filePath}${branchPath}`;

          const delay = (timeout: number) =>
            new Promise<void>((resolve) => {
              setTimeout(resolve, timeout);
            });

          const startWorker = async () => {
            const res = await fetch(url, { headers });
            const result = await res.json();
            const isDone = result?.status !== 'pending';
            if (isDone) {
              port.postMessage(result);
            }
            return isDone;
          };

          let retryCount = 0;
          let keepGoing = true;
          const maxDelay = 400000;

          while (keepGoing) {
            const isDone = await startWorker();
            if (isDone) keepGoing = false;

            retryCount += 1;
            const calculatedTime = Math.pow(2, retryCount) * 100;
            const timeout =
              calculatedTime >= maxDelay ? maxDelay : calculatedTime;

            await delay(timeout);
          }
        },
        false
      );

      port.start();
    },
    false
  );
};

export default branchWorker;
