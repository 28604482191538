import React from 'react';
import Column from '../../grid/Column';
import Dialog from '../../../../components/Dialog/Dialog';
import './column.css';

const ColumnDialog = ({
  fileId,
  branchId,
  tableId,
  open,
  setOpen,
  onSubmit,
}: {
  fileId: number;
  branchId: number;
  tableId: number;
  open: boolean;
  setOpen: (data: boolean) => void;
  onSubmit?: () => void;
}) => {
  return (
    <Dialog selector="column-dialog" open={open} setOpen={setOpen}>
      <div className="dialog-content">
        <Column fileId={fileId} branchId={branchId} tableId={tableId}></Column>
      </div>
      <div className="menu-buttons">
        <button type="button" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
    </Dialog>
  );
};

export default ColumnDialog;
