import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import Alert from '../../../../components/Alert/Alert';
import { isNothing } from '../../../../common/utils';
import Header from '../../header/Header';
import { RootState } from '../../../../store';
import {
  loadFirstDraftTable,
  loadDraftTableById,
} from '../../../../state/draft-table/draftTableActions';
import {
  loadBranchById,
  loadFirstBranch,
} from '../../../../state/branch/branchActions';
import DraftTableList from '../../../../domain/draft-table/list/DraftTable';
import DraftColumnDialog from '../../../../domain/draft-column/dialog/grid/DraftColumn';
import DraftRowResource from '../../resources/draft-row/DraftRow';
import DraftTableResource from '../../resources/draft-table/DraftTable';
import DraftColumnResource from '../../resources/draft-column/DraftColumn';
import FileResource from '../../resources/file/File';
import type DraftTableType from '../../../../models/draft-table';
import './draft.css';

const DraftConnect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState('draft-row');
  const [openDraftColumns, setOpenDraftColumns] = useState(false);
  const currentFile = useSelector(
    (state: RootState) => state.file.active.selected
  );
  const currentBranch = useSelector(
    (state: RootState) => state.branch.active.selected
  );
  const currentTable = useSelector(
    (state: RootState) => state.draftTable.active.selected
  );

  const params = useParams();
  const fileId = Number(params.fileId);
  const branchId = Number(params.branchId);
  const tableId = Number(params.tableId);

  const hasFile = fileId && !_.isEmpty(currentFile);
  const hasBranch =
    hasFile && !isNothing(branchId) && !_.isEmpty(currentBranch);
  const hasTable = hasBranch && !isNothing(tableId) && !_.isEmpty(currentTable);

  const firstTable = useSelector(
    (state: RootState) => state.draftTable.active.first
  );
  const firstBranch = useSelector(
    (state: RootState) => state.branch.active.first
  );

  const selectDefaultBranch = useCallback(() => {
    if (!hasFile) return;
    if (hasBranch) return;
    if (!firstBranch) return;
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${firstBranch?.id}`;
    const redirectPath = `/connects/drafts${filePath}${branchPath}`;
    navigate(redirectPath);
  }, [fileId, firstBranch, hasFile, hasBranch, navigate]);

  const selectDefaultTable = useCallback(() => {
    if (!hasFile) return;
    if (!hasBranch) return;
    if (tableId) return;
    if (!firstTable) return;
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const tablePath = `/tables/${firstTable.tableId}`;
    const redirectPath = `/connects/drafts${filePath}${branchPath}${tablePath}`;
    navigate(redirectPath);
  }, [fileId, branchId, firstTable, hasFile, hasBranch, navigate, tableId]);

  const handleSelectTable = (currentDraftTable: DraftTableType) => {
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const draftTablePath = `/tables/${currentDraftTable.tableId}`;
    const redirectPath = `/connects/drafts${filePath}${branchPath}${draftTablePath}`;
    navigate(redirectPath);
  };

  useEffect(() => {
    selectDefaultBranch();
  }, [selectDefaultBranch]);

  useEffect(() => {
    selectDefaultTable();
  }, [selectDefaultTable]);

  useEffect(() => {
    if (!fileId) return;
    dispatch(loadFirstBranch(fileId, { where: { name: { ne: 'master' } } }));
  }, [dispatch, fileId]);

  useEffect(() => {
    if (!branchId) return;
    dispatch(loadFirstDraftTable(fileId, branchId));
  }, [dispatch, fileId, branchId]);

  useEffect(() => {
    if (!fileId) return;
    if (!branchId) return;
    dispatch(loadBranchById(fileId, branchId));
  }, [dispatch, fileId, branchId]);

  useEffect(() => {
    if (!branchId) return;
    if (!tableId) return;
    dispatch(loadDraftTableById(fileId, branchId, tableId));
  }, [dispatch, fileId, branchId, tableId]);

  return (
    <div className="draft-connect-container">
      <Alert></Alert>
      <div className="content-container">
        <div className={`sidebar${sidebarOpen ? '' : ' close'}`}>
          <Sidebar
            open={sidebarOpen}
            onToggle={(open) => setSidebarOpen(open)}
          ></Sidebar>
        </div>
        <div className={`content${sidebarOpen ? '' : ' open'}`}>
          <div className="connect-header">
            <Header fileId={fileId} branchId={branchId}></Header>
          </div>
          <div className="connect-data">
            <div className="resource-tab">
              <div className="resource-label-container">
                <FontAwesomeIcon className="icon" icon={faTable} />
                <label className="resource-label" htmlFor="resource-buttons">
                  Resource:
                </label>
              </div>
              <div className="resource-buttons">
                <button
                  className={`resource params text ${
                    selectedResource === 'file' ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedResource('file')}
                >
                  File
                </button>
                <button
                  disabled={!hasFile}
                  className={`resource params text ${
                    selectedResource === 'draft-table' ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedResource('draft-table')}
                >
                  Table
                </button>
                <button
                  disabled={!hasTable}
                  className={`resource params text ${
                    selectedResource === 'draft-column' ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedResource('draft-column')}
                >
                  Column
                </button>
                <button
                  disabled={!hasTable}
                  className={`resource params text ${
                    selectedResource === 'draft-row' ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedResource('draft-row')}
                >
                  Row
                </button>
              </div>
            </div>
            <div className="resource-data">
              {hasFile && selectedResource === 'file' ? (
                <FileResource fileId={fileId}></FileResource>
              ) : null}
              {hasFile && hasBranch && selectedResource === 'draft-table' ? (
                <DraftTableResource
                  fileId={fileId}
                  branchId={branchId}
                ></DraftTableResource>
              ) : null}
              {hasFile &&
              hasBranch &&
              hasTable &&
              selectedResource === 'draft-column' ? (
                <DraftColumnResource
                  fileId={fileId}
                  branchId={branchId}
                  tableId={tableId}
                ></DraftColumnResource>
              ) : null}
              {hasFile &&
              hasBranch &&
              hasTable &&
              selectedResource === 'draft-row' ? (
                <DraftRowResource
                  fileId={fileId}
                  branchId={branchId}
                  tableId={tableId}
                ></DraftRowResource>
              ) : null}
            </div>
          </div>
          <div className="connect-footer">
            {hasFile && hasBranch ? (
              <div className="connect-footer-container">
                <div className="footer-content">
                  <DraftTableList
                    fileId={fileId}
                    branchId={branchId}
                    selectedRowFilter={{ tableId, branchId }}
                    onSelectOption={setOpenDraftColumns}
                    onSelectTable={handleSelectTable}
                    lockTable={true}
                  ></DraftTableList>
                </div>
                {hasTable ? (
                  <DraftColumnDialog
                    fileId={fileId}
                    branchId={branchId}
                    tableId={tableId}
                    open={openDraftColumns}
                    setOpen={setOpenDraftColumns}
                    lockTable={true}
                  ></DraftColumnDialog>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DraftConnect;
