import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import {
  actionOneMap,
  actionResponseMap,
  actionFlagMap,
} from '../../../common/state/types';
import type ProcessLogType from '../../../models/process-log';
import type { StateType } from '../../../common/state/types';
import { setAlert } from '../../alert/alertActions';
import { attachHeaders } from '../../../common/utils';
import domainModule from '../../../workers/submit/worker';
import workerManager from '../../../workers';
import { loadBranchById } from '../../branch/branchActions';
import { reloadDraftRows } from '../../draft-row/draftRowActions';
import { reloadDraftColumns } from '../../draft-column/draftColumnActions';
import { reloadDraftTables } from '../../draft-table/draftTableActions';
import { reloadCommits } from '../commit/commitActions';
import { handleError } from '../../alert/alertActions';

const domainSize = process.env.REACT_APP_SUBMITS_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const reloadSubmits =
  (): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: actionFlagMap.SUBMIT_RELOAD });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.SUBMIT_DATA_FAILED,
        payload: null,
      });
    }
  };

export const syncSubmit =
  (submitInfo: ProcessLogType) => async (dispatch: Dispatch) => {
    const eventListener = (event: MessageEvent<any>) => {
      const submitLatest = event?.data;
      const { fileId, branchId, status, statusMessage } = submitLatest;
      if (status === 'submitted') {
        dispatch({
          type: actionOneMap.SUBMIT_DATA_UPDATED,
          payload: submitLatest,
        });
        dispatch(setAlert('Submission successful', 'success'));
        dispatch(loadBranchById(fileId, branchId));
        dispatch(reloadDraftRows());
        dispatch(reloadDraftColumns());
        dispatch(reloadDraftTables());
        dispatch(reloadCommits());
      }
      if (status === 'error') {
        dispatch(setAlert(statusMessage, 'error'));
        dispatch(loadBranchById(fileId, branchId));
        dispatch(reloadDraftRows());
        dispatch(reloadDraftColumns());
        dispatch(reloadDraftTables());
        dispatch(reloadCommits());
      }
    };

    if (submitInfo?.status === 'pending') {
      const eventMessage = attachHeaders(submitInfo);
      if (window.SharedWorker) {
        const domainWorker = workerManager.getWorker('submit', domainModule);
        domainWorker.port.addEventListener('message', eventListener, false);
        domainWorker.port.start();
        domainWorker.port.postMessage(eventMessage);
      } else {
        const statusMessage = 'Worker not found. You may refresh manually';
        dispatch(setAlert(statusMessage, 'warning'));
      }
    }
  };

export const loadSubmitsByBranch =
  (
    fileId: number,
    branchId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/files/${fileId}/branches/${branchId}/submits/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.SUBMIT_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.SUBMIT_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadSubmits =
  (
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/submits/review`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.SUBMIT_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.SUBMIT_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadFirstSubmit =
  (
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: 1,
      size: 1,
    };
    const queryPath = `/api/submits/review`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionOneMap.SUBMIT_DATA_FIRST,
        payload: res.data.data[0],
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.SUBMIT_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadSubmitById =
  (
    fileId: number,
    branchId: number,
    submitId: number
  ): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = `/api/files/${fileId}/branches/${branchId}/submits/${submitId}`;
    try {
      const res = await axios.get(queryPath);
      dispatch({
        type: actionOneMap.SUBMIT_DATA_LOADED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.SUBMIT_DATA_FAILED,
        payload: null,
      });
    }
  };

export const addSubmit =
  (
    fileId: number,
    branchId: number,
    payload: ProcessLogType
  ): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payload });
    const queryPath = `/api/files/${fileId}/branches/${branchId}/submits/`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch({
        type: actionOneMap.SUBMIT_DATA_ADDED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
      dispatch(syncSubmit(res.data));
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.SUBMIT_DATA_FAILED,
        payload: null,
      });
    }
  };
