import { useCallback } from 'react';
import { generateUUID } from '../../../../../common/utils';
import { GenericObjType } from '../../../../../common/types';
import Params from '../../../components/params/Params';

const FileParams = ({
  fileId,
  onChange,
}: {
  fileId: number;
  onChange?: React.Dispatch<React.SetStateAction<GenericObjType>>;
}) => {
  const cachePrefix = 'connect-master-table-params';
  const deriveCacheKey = useCallback(() => {
    const cacheBase = generateUUID({ fileId }, ['fileId']);
    const cacheKey = `${cachePrefix}-${cacheBase}`;
    return cacheKey;
  }, [fileId]);

  const paramsList = [{ type: 'text', name: 'name', value: '' }];
  const paramsContent = paramsList.map((paramItem, inx) => {
    const optionItemId = inx + 1;
    return {
      ...paramItem,
      id: optionItemId,
      rowNum: optionItemId,
    };
  });

  return (
    <Params
      rows={paramsContent}
      rowsMetaList={[]}
      deriveCacheKey={deriveCacheKey}
      onChange={onChange}
    ></Params>
  );
};

export default FileParams;
