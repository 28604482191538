import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { actionManyMap, actionResponseMap } from '../../../common/state/types';
import type UserType from '../../../models/user';
import type FileUserType from '../../../models/file-user';
import type { StateType } from '../../../common/state/types';
import { handleError } from '../../alert/alertActions';

const domainSize = process.env.REACT_APP_USERS_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const loadFileUsers =
  (
    fileId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<FileUserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      where: { fileId, ...params.where },
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/file-users/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.USERBYFILE_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USERBYFILE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const addUsers =
  (
    fileId: number,
    payload: UserType[]
  ): ThunkAction<void, StateType<FileUserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payload });
    const queryPath = `/api/file-users/files/${fileId}/share`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch({
        type: actionManyMap.USERBYFILE_DATALIST_ADDED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USERBYFILE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const updateFileUsers =
  (
    fileId: number,
    payload: FileUserType[]
  ): ThunkAction<void, StateType<FileUserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const normPayload = payload.map((item) => ({ ...item, fileId }));
    const body = JSON.stringify({ data: normPayload });
    const queryPath = `/api/file-users/`;
    try {
      const res = await axios.put(queryPath, body, config);
      dispatch({
        type: actionManyMap.USERBYFILE_DATALIST_UPDATED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USERBYFILE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const removeFileUsers =
  (
    fileId: number,
    payload: FileUserType[]
  ): ThunkAction<void, StateType<FileUserType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const normPayload = payload.map((item) => ({ ...item, fileId }));
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
      data: JSON.stringify({ data: normPayload }),
    };
    const queryPath = `/api/file-users/`;
    try {
      dispatch({
        type: actionManyMap.USERBYFILE_DATALIST_DELETED,
        payload: payload,
        path: { url: queryPath, params: {} },
      });
      await axios.delete(queryPath, config);
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.USERBYFILE_DATA_FAILED,
        payload: null,
      });
    }
  };
