import _ from 'lodash';
import { faPencil, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGrid,
  textEditorNorm,
  SelectColumn,
  EmptyDataGrid,
  deriveColumnProps,
  HeaderRenderer,
  FilterContext,
  FilterFlagContext,
  pager,
} from '../../../../components/DataGrid/DataGrid';
import { isNothing, parseDiff } from '../../../../common/utils';
import { GenericObjType, FilterType } from '../../../../common/types';
import { loadDraftColumns } from '../../../../state/draft-column/merge-diff/draftColumnActions';
import { loadDraftRows } from '../../../../state/draft-row/merge-diff/draftRowActions';
import { loadFileById } from '../../../../state/file/fileActions';
import { loadBranchById } from '../../../../state/branch/branchActions';
import { loadDraftTableById } from '../../../../state/draft-table/merge-diff/draftTableActions';
import { RootState } from '../../../../store';
import type DraftRowType from '../../../../models/draft-row';
import Paginate from '../../../../components/Paginate/Paginate';
import DiffText from '../../../../components/DiffText';
import './draftRow.css';

const DraftRow = ({
  fileId,
  branchId,
  tableId,
  params,
  onFilter = () => {},
  searchParams = {},
}: {
  fileId: number;
  branchId: number;
  tableId: number;
  params?: { [k: string]: any };
  searchParams?: GenericObjType;
  onFilter?: (a: GenericObjType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const baseSearchParams = useMemo(() => searchParams || {}, [searchParams]);
  const dispatch = useDispatch();
  const rows = useSelector(
    (state: RootState) => state.draftRowMergeDiff.active.data
  );
  const rowsMetaList = useSelector(
    (state: RootState) => state.draftRowMergeDiff.active.metaList
  );
  const rowsMeta = useSelector(
    (state: RootState) => state.draftRowMergeDiff.active.meta
  );
  const rowsReload = useSelector(
    (state: RootState) => state.draftRow.active.flags.reload
  );
  const columnsReload = useSelector(
    (state: RootState) => state.draftColumn.active.flags.reload
  );
  const draftColumns = useSelector(
    (state: RootState) => state.draftColumnMergeDiff.active.data
  );
  const columnProps = deriveColumnProps(draftColumns);
  const [filterFlag, setFilterFlag] = useState(!isNothing(searchParams));
  const [filters, setFilters] = useState(baseSearchParams);
  const [editable, setEditable] = useState(false);
  const [forcePage, setForcePage] = useState(0);
  const [selectedPageNum, setSelectedPageNum] = useState(0);
  const [selectedPageRowNum, setSelectedPageRowNum] = useState(0);

  const userColumns = columnProps.map((column) => {
    return {
      ...column,
      resizable: true,
      editable: editable,
      renderEditCell: textEditorNorm,
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={column.key}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      renderCell: ({ row }: { row: DraftRowType }) => {
        const rowValue = row[column.key as keyof DraftRowType];
        const rowDiff = parseDiff(rowValue);
        if (!rowDiff.isDiff) return rowDiff.value;
        return (
          <>
            <DiffText keyValue={rowValue}></DiffText>
          </>
        );
      },
    };
  });
  const columns = [
    SelectColumn,
    {
      key: 'rowNum',
      name: 'Row',
      maxWidth: 50,
      frozen: true,
      resizable: true,
    },
    {
      key: 'status',
      name: 'Status',
      width: 150,
      frozen: true,
      resizable: true,
      renderCell: ({ row }: { row: DraftRowType }) => {
        if (row.status !== 'submitted') return row.status;
        return (
          <>
            <span className="change-tag">{`#${row.submitId}-${row.commitId}`}</span>
            <span>{row.status}</span>
          </>
        );
      },
    },
    ...userColumns,
  ];

  const debouncedOnFilter = useRef(
    _.debounce((currentFilters) => {
      dispatch(
        loadDraftRows(fileId, branchId, tableId, {
          ...baseParams,
          where: { ...currentFilters, ...baseParams.where },
        })
      );
    }, 1000)
  ).current;

  const handleFilter = (currentFilters: FilterType<DraftRowType>) => {
    setFilters(currentFilters);
    debouncedOnFilter(currentFilters);
    onFilter(currentFilters);
  };

  const handleToggleFilter = (isEnabled: boolean) => {
    const currentFilters = isEnabled ? filters : {};
    setFilterFlag(isEnabled);
    handleFilter(currentFilters);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    const currentPageNum = currentPageInx + 1;
    const pageStartRowNum = pager.detectPageStartRowNum(
      currentPageNum,
      rowsMetaList
    );
    setSelectedPageNum(currentPageNum);
    setSelectedPageRowNum(pageStartRowNum);
  };

  const loadPage = useCallback(
    (pageInx: number) => {
      const currentPage = pageInx + 1;
      const pageMeta = _.find(rowsMetaList, { currentPage });
      if (!_.isEmpty(pageMeta)) return pageMeta;
      dispatch(
        loadDraftRows(fileId, branchId, tableId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          page: currentPage,
        })
      );
      return pageMeta;
    },
    [dispatch, fileId, branchId, tableId, baseParams, rowsMetaList, filters]
  );

  useEffect(() => {
    if (columnsReload) {
      dispatch(loadDraftColumns(fileId, branchId, tableId));
    }
  }, [dispatch, fileId, branchId, tableId, filters, columnsReload]);

  useEffect(() => {
    if (rowsReload) {
      dispatch(
        loadDraftRows(fileId, branchId, tableId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          page: forcePage,
        })
      );
    }
  }, [
    dispatch,
    fileId,
    branchId,
    tableId,
    filters,
    baseParams,
    forcePage,
    rowsReload,
  ]);

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadBranchById(fileId, branchId));
    dispatch(loadDraftTableById(fileId, branchId, tableId));
    dispatch(loadDraftColumns(fileId, branchId, tableId));
    dispatch(
      loadDraftRows(fileId, branchId, tableId, {
        ...baseParams,
        where: { ...filters, ...baseParams.where },
      })
    );
  }, [dispatch, fileId, branchId, tableId, baseParams, filters]);

  const isEmptyGrid = columns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="draft-row-container merge-diff">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="draft-row-container merge-diff">
      <FilterFlagContext.Provider value={filterFlag}>
        <FilterContext.Provider value={filters}>
          <div className="grid-toolbar">
            <div className="left-toolbar"></div>
            <div className="center-toolbar">
              <Paginate
                totalPages={Number(rowsMeta?.totalPages)}
                onPageClick={handlePageClick}
                forcePage={forcePage}
              />
            </div>
            <div className="right-toolbar">
              <button
                className={`filterable ${filterFlag ? 'selected' : ''}`}
                onClick={() => handleToggleFilter(!filterFlag)}
              >
                <FontAwesomeIcon icon={faFilter} />
                <span className="filter icon-label">Filter</span>
              </button>
              <button
                className={`editable ${editable ? 'selected' : ''}`}
                onClick={() => setEditable(!editable)}
              >
                <FontAwesomeIcon icon={faPencil} />
                <span className="edit icon-label">Edit</span>
              </button>
            </div>
          </div>
          <DataGrid<DraftRowType>
            rowKeyGetter={(row) => String(row.id)}
            columns={columns}
            rows={rows}
            rowsMetaList={rowsMetaList}
            onPageChange={setForcePage}
            loadPage={loadPage}
            selectedPageNum={selectedPageNum}
            selectedPageRowNum={selectedPageRowNum}
            rowHeight={30}
          ></DataGrid>
        </FilterContext.Provider>
      </FilterFlagContext.Provider>
    </div>
  );
};

export default DraftRow;
