import type { ReducerType, StateType } from '../../common/state/types';
import {
  actionOneMap,
  actionResponseMap,
  actionManyMap,
} from '../../common/state/types';
import { initialState } from '../../common/state/utils/constant';
import type GroupType from '../../models/group';
import {
  dataListLoadedReducer,
  dataListAddedReducer,
  dataListUpdatedReducer,
  dataListDeletedReducer,
  dataLoadedReducer,
  dataFailedReducer,
} from '../../common/state/reducers';

type GroupCustomType = {
  loginGroup?: GroupType;
  publicGroup?: GroupType;
};

type GroupActionType = {
  type:
    | 'GROUP_PUBLICGROUP_LOADED'
    | 'GROUP_LOGINGROUP_LOADED'
    | 'GROUP_LOGOUTGROUP';
  payload: GroupType;
};

const groupReducer: ReducerType<GroupType, GroupCustomType, GroupActionType> = (
  state = {
    active: initialState as StateType<GroupType, GroupCustomType>,
    rollback: initialState as StateType<GroupType, GroupCustomType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.GROUP_DATALIST_LOADED:
      return dataListLoadedReducer<GroupType>({
        state,
        action,
      });
    case actionOneMap.GROUP_DATA_LOADED:
      return dataLoadedReducer<GroupType>({ state, action });
    case actionManyMap.GROUP_DATALIST_ADDED:
      return dataListAddedReducer<GroupType>({
        state,
        action,
      });
    case actionManyMap.GROUP_DATALIST_UPDATED:
      return dataListUpdatedReducer<GroupType>({
        state,
        action,
      });
    case actionManyMap.GROUP_DATALIST_DELETED:
      return dataListDeletedReducer<GroupType>({
        state,
        action,
      });
    case 'GROUP_PUBLICGROUP_LOADED':
      return {
        active: {
          ...state.active,
          flags: { ...state.active.flags, loading: false },
          custom: { ...state.active.custom, publicGroup: action.payload },
        },
        rollback: state.active,
      };
    case 'GROUP_LOGINGROUP_LOADED':
      return {
        active: {
          ...state.active,
          flags: { ...state.active.flags, loading: false },
          custom: { ...state.active.custom, loginGroup: action.payload },
        },
        rollback: state.active,
      };
    case 'GROUP_LOGOUTGROUP':
      return {
        active: {
          ...state.active,
          custom: { ...state.active.custom, loginGroup: undefined },
        },
        rollback: state.active,
      };
    case actionResponseMap.GROUP_DATA_FAILED:
      return dataFailedReducer<GroupType>({ state, action });
    default:
      return state;
  }
};

export default groupReducer;
