import React from 'react';
import Login from '../../form/Login';
import Dialog from '../../../../../components/Dialog/Dialog';
import './login.css';

const LoginDialog = ({
  open,
  setOpen,
  signUp,
}: {
  open: boolean;
  setOpen: (data: boolean) => void;
  signUp: (data: boolean) => void;
}) => {
  return (
    <Dialog selector="user-login-dialog" open={open} setOpen={setOpen}>
      <div className="dialog-content">
        <Login
          onCancel={() => setOpen(false)}
          onSubmit={() => setOpen(false)}
          signUp={signUp}
        ></Login>
      </div>
    </Dialog>
  );
};

export default LoginDialog;
