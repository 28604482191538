import _ from 'lodash';
import { useState, ReactNode, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  faFile,
  faCodeBranch,
  faCaretDown,
  faCircleDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BranchDialog from '../../../domain/branch/dialog/grid/Branch';
import FileDialog from '../../../domain/file/dialog/grid/File';
import { RootState } from '../../../store';
import { loadFileById, downloadMaster } from '../../../state/file/fileActions';
import { loadTableById } from '../../../state/table/tableActions';
import BranchType from '../../../models/branch';
import FileType from '../../../models/file';
import './header.css';

const Header = ({
  fileId,
  tableId,
  branchId,
  onSelect,
}: {
  fileId?: number;
  branchId?: number;
  tableId?: number;
  onSelect?: ({ mergeReview }: { mergeReview: boolean }) => void;
}) => {
  const navigate = useNavigate();
  const orderDesc = useRef({ order: [['id', 'desc']] }).current;
  const currentFile = useSelector(
    (state: RootState) => state.file.active.selected
  );
  const dispatch = useDispatch();

  const handleSelection = (selectedTable: string) => {
    const openDialog: { [k: string]: (a: boolean) => void } = {
      file: setOpenFiles,
      branch: setOpenBranches,
    };
    openDialog[selectedTable](true);
  };

  const handleOnBranchSelect = (currentBranch: BranchType) => {
    const pagePath = currentBranch.name === 'master' ? 'masters' : 'drafts';
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${currentBranch.id}`;
    const redirectPath = `/${pagePath}${filePath}${branchPath}`;
    setOpenBranches(false);
    navigate(redirectPath);
  };

  const handleFileSelect = (currentFile: FileType) => {
    const filePath = `/files/${currentFile.id}`;
    const redirectPath = `/drafts${filePath}`;
    setOpenFiles(false);
    navigate(redirectPath);
  };

  const handleDownload = () => {
    dispatch(downloadMaster(Number(currentFile?.id)));
  };

  const [openBranches, setOpenBranches] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);

  useEffect(() => {
    if (fileId) dispatch(loadFileById(fileId));
    if (fileId && tableId) dispatch(loadTableById(fileId, tableId));
  }, [dispatch, fileId, tableId]);

  const hasFile = fileId && !_.isEmpty(currentFile);

  const showButton: { [k: string]: (a?: boolean) => ReactNode } = {
    file: (enable = true) => (
      <button
        className={`file button${openFiles ? ' selected' : ''}`}
        onClick={() => handleSelection('file')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className={`file-icon`}
          icon={faFile}
        ></FontAwesomeIcon>
        <span className="file-name icon-label">
          {hasFile ? currentFile.name : `select file`}
        </span>
        <FontAwesomeIcon
          className="file-icon"
          icon={faCaretDown}
        ></FontAwesomeIcon>
      </button>
    ),
    branch: (enable = true) => (
      <button
        className={`branch button${openBranches ? ' selected' : ''}`}
        onClick={() => handleSelection('branch')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className={`branch-icon`}
          icon={faCodeBranch}
        ></FontAwesomeIcon>
        <span className="branch-name icon-label">master</span>
        <FontAwesomeIcon
          className="branch-icon"
          icon={faCaretDown}
        ></FontAwesomeIcon>
      </button>
    ),
    download: (enable = true) => (
      <button
        className="download button"
        onClick={() => handleDownload()}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className="tab-icon"
          icon={faCircleDown}
        ></FontAwesomeIcon>
        <span className="icon-label">download</span>
      </button>
    ),
  };

  return (
    <div className="master-header-container">
      <FileDialog
        open={openFiles}
        setOpen={setOpenFiles}
        selectedRowFilter={{ id: fileId }}
        onSelect={handleFileSelect}
      ></FileDialog>
      <div className="header-left">
        {showButton.file()}
        {hasFile ? showButton.branch() : null}
      </div>
      <div className="header-center"></div>
      <div className="header-right">{showButton.download()}</div>
      {hasFile && openBranches ? (
        <BranchDialog
          fileId={fileId}
          open={openBranches}
          setOpen={setOpenBranches}
          selectedRowFilter={{ id: branchId }}
          params={orderDesc}
          onSelect={handleOnBranchSelect}
        ></BranchDialog>
      ) : null}
    </div>
  );
};
export default Header;
