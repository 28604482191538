import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { actionResponseMap } from '../../common/state/types';
import type RowType from '../../models/row';
import type { StateType } from '../../common/state/types';
import { handleError } from '../alert/alertActions';

const domainSize = process.env.REACT_APP_ROWS_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const loadRows =
  (
    fileId: number,
    tableId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<RowType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    try {
      const res = await axios.get(
        `/api/files/${fileId}/tables/${tableId}/rows/`,
        {
          params: queryParams,
          paramsSerializer: (items) => qs.stringify(items),
        }
      );
      dispatch({
        type: actionResponseMap.ROW_DATALIST_LOADED,
        payload: res.data,
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.ROW_DATA_FAILED,
        payload: null,
      });
    }
  };
