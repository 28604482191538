import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import {
  actionOneMap,
  actionResponseMap,
  actionFlagMap,
} from '../../../common/state/types';
import type ProcessLogType from '../../../models/process-log';
import type { StateType } from '../../../common/state/types';
import { setAlert } from '../../alert/alertActions';
import { attachHeaders } from '../../../common/utils';
import { loadBranchById } from '../../branch/branchActions';
import { reloadDraftRows } from '../../draft-row/draftRowActions';
import { reloadDraftColumns } from '../../draft-column/draftColumnActions';
import { reloadDraftTables } from '../../draft-table/draftTableActions';
import { reloadCommits } from '../commit/commitActions';
import { reloadSubmits } from '../submit/submitActions';
import domainModule from '../../../workers/rebase/worker';
import workerManager from '../../../workers';
import { handleError } from '../../alert/alertActions';

const domainSize = process.env.REACT_APP_REBASES_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const reloadRebases =
  (): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: actionFlagMap.REBASE_RELOAD });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.REBASE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const syncRebase =
  (rebaseInfo: ProcessLogType) => async (dispatch: Dispatch) => {
    const eventListener = (event: MessageEvent<any>) => {
      const rebaseLatest = event?.data;
      const { fileId, branchId, status, statusMessage } = rebaseLatest;
      if (status === 'rebased') {
        dispatch({
          type: actionOneMap.REBASE_DATA_UPDATED,
          payload: rebaseLatest,
        });
        dispatch(setAlert('Rebase successful', 'success'));
        dispatch(loadBranchById(fileId, branchId));
        dispatch(reloadDraftRows());
        dispatch(reloadDraftColumns());
        dispatch(reloadDraftTables());
        dispatch(reloadCommits());
        dispatch(reloadSubmits());
      }
      if (status === 'error') {
        dispatch(setAlert(statusMessage, 'error'));
        dispatch(loadBranchById(fileId, branchId));
        dispatch(reloadDraftRows());
        dispatch(reloadDraftColumns());
        dispatch(reloadDraftTables());
        dispatch(reloadCommits());
        dispatch(reloadSubmits());
      }
    };

    if (rebaseInfo?.status === 'pending') {
      const eventMessage = attachHeaders(rebaseInfo);
      if (window.SharedWorker) {
        const domainWorker = workerManager.getWorker('rebase', domainModule);
        domainWorker.port.addEventListener('message', eventListener, false);
        domainWorker.port.start();
        domainWorker.port.postMessage(eventMessage);
      } else {
        const statusMessage = 'Worker not found. You may refresh manually';
        dispatch(setAlert(statusMessage, 'warning'));
      }
    }
  };

export const loadRebases =
  (
    fileId: number,
    branchId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/files/${fileId}/branches/${branchId}/rebases/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.REBASE_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.REBASE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const addRebase =
  (
    fileId: number,
    branchId: number,
    payload: ProcessLogType
  ): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payload });
    const queryPath = `/api/files/${fileId}/branches/${branchId}/rebases/`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch({
        type: actionOneMap.REBASE_DATA_ADDED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
      dispatch(syncRebase(res.data));
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.REBASE_DATA_FAILED,
        payload: null,
      });
    }
  };
