import _ from 'lodash';
import { useState, ReactNode, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  faFile,
  faCodeBranch,
  faCaretDown,
  faCodeCommit,
  faCodePullRequest,
  faCodeMerge,
  faRotate,
  faCircleDown,
  faCircleUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../../../components/Spinner/Spinner';
import BranchDialog from '../../../domain/branch/dialog/grid/Branch';
import CommitDialog from '../../../domain/process-log/commit/dialog/msg/Commit';
import SubmitDialog from '../../../domain/process-log/submit/dialog/add/Submit';
import RebaseDialog from '../../../domain/process-log/rebase/dialog/msg/Rebase';
import FileDialog from '../../../domain/file/dialog/grid/File';
import FileUploadDialog from '../../../domain/file/upload/File';
import { RootState } from '../../../store';
import { loadFileById, downloadDraft } from '../../../state/file/fileActions';
import { loadBranchById } from '../../../state/branch/branchActions';
import { loadDraftTableById } from '../../../state/draft-table/draftTableActions';
import BranchType from '../../../models/branch';
import FileType from '../../../models/file';
import './header.css';

const Header = ({
  fileId,
  branchId,
  tableId,
  onSelect,
}: {
  fileId?: number;
  branchId?: number;
  tableId?: number;
  onSelect?: ({ mergeReview }: { mergeReview: boolean }) => void;
}) => {
  const navigate = useNavigate();
  const orderDesc = useRef({ order: [['id', 'desc']] }).current;
  const currentFile = useSelector(
    (state: RootState) => state.file.active.selected
  );
  const currentBranch = useSelector(
    (state: RootState) => state.branch.active.selected
  );
  const dispatch = useDispatch();

  const redirectToReviewPage = () => {
    const filePage = `/files/${fileId}`;
    const branchPage = `/branches/${branchId}`;
    navigate(`/reviews${filePage}${branchPage}`);
  };

  const handleOnBranchSelect = (currentBranch: BranchType) => {
    const pagePath = currentBranch.name === 'master' ? 'masters' : 'drafts';
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${currentBranch.id}`;
    const redirectPath = `/${pagePath}${filePath}${branchPath}`;
    setOpenBranches(false);
    navigate(redirectPath);
  };

  const handleFileSelect = (currentFile: FileType) => {
    const filePath = `/files/${currentFile.id}`;
    const redirectPath = `/drafts${filePath}`;
    setOpenFiles(false);
    navigate(redirectPath);
  };

  const handleSelection = (selectedTable: string) => {
    const openDialog: { [k: string]: (a: boolean) => void } = {
      file: setOpenFiles,
      branch: setOpenBranches,
      commit: setOpenCommits,
      submit: setOpenSubmits,
      rebase: setOpenRebases,
      fileUpload: setOpenFileUpload,
    };
    openDialog[selectedTable](true);
  };
  const handleDownload = () => {
    dispatch(downloadDraft(Number(currentFile?.id), Number(currentBranch?.id)));
  };

  const [openSubmits, setOpenSubmits] = useState(false);
  const [openCommits, setOpenCommits] = useState(false);
  const [openRebases, setOpenRebases] = useState(false);
  const [openBranches, setOpenBranches] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [openFileUpload, setOpenFileUpload] = useState(false);

  useEffect(() => {
    if (fileId) dispatch(loadFileById(fileId));
    if (fileId && branchId) dispatch(loadBranchById(fileId, branchId));
    if (fileId && branchId && tableId)
      dispatch(loadDraftTableById(fileId, branchId, tableId));
  }, [dispatch, fileId, branchId, tableId]);

  const hasFile = fileId && !_.isEmpty(currentFile);
  const hasBranch = hasFile && branchId && !_.isEmpty(currentBranch);

  const isUpdated = Boolean(currentBranch?.isUpdated);
  const hasConflict = Boolean(currentBranch?.hasConflict);
  const isEdited = Boolean(currentBranch?.isEdited);
  const hasCommit = Boolean(currentBranch?.hasCommit);
  const hasSubmit = Boolean(currentBranch?.hasSubmit);

  const canRebase = Boolean(hasBranch && !isUpdated);
  const canCommit = isUpdated && isEdited && !hasConflict;
  const canSubmit = isUpdated && hasCommit && !hasConflict;
  const canReview = isUpdated && hasSubmit && !hasConflict;
  const canDownload = Boolean(hasFile && hasBranch);
  const canUpload = Boolean(hasFile && hasBranch);

  const showButton: { [k: string]: (a?: boolean) => ReactNode } = {
    file: (enable = true) => (
      <button
        className={`file button${openFiles ? ' selected' : ''}`}
        onClick={() => handleSelection('file')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className={`file-icon`}
          icon={faFile}
        ></FontAwesomeIcon>
        <span className="file-name icon-label">
          {hasFile ? currentFile.name : `select file`}
        </span>
        <FontAwesomeIcon
          className="file-icon"
          icon={faCaretDown}
        ></FontAwesomeIcon>
      </button>
    ),
    branch: (enable = true) => (
      <button
        className={`branch button${openBranches ? ' selected' : ''}`}
        onClick={() => handleSelection('branch')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className={`branch-icon`}
          icon={faCodeBranch}
        ></FontAwesomeIcon>
        <span className="branch-name icon-label">
          {hasBranch ? currentBranch.name : `select branch`}
        </span>
        <FontAwesomeIcon
          className="branch-icon"
          icon={faCaretDown}
        ></FontAwesomeIcon>
      </button>
    ),
    commit: (enable = true) => (
      <button
        className={`commit button${openCommits ? ' selected' : ''}`}
        onClick={() => handleSelection('commit')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className="tab-icon"
          icon={faCodeCommit}
        ></FontAwesomeIcon>
        <span className="icon-label">commit</span>
      </button>
    ),
    submit: (enable = true) => (
      <button
        className={`commit button${openSubmits ? ' selected' : ''}`}
        onClick={() => handleSelection('submit')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className="tab-icon"
          icon={faCodePullRequest}
        ></FontAwesomeIcon>
        <span className="icon-label">submit</span>
      </button>
    ),
    rebase: (enable = true) => (
      <button
        className={`rebase button${openRebases ? ' selected' : ''}`}
        onClick={() => handleSelection('rebase')}
        disabled={!enable}
      >
        <FontAwesomeIcon className="tab-icon" icon={faRotate}></FontAwesomeIcon>
        <span className="icon-label">rebase</span>
      </button>
    ),
    mergeDiff: (enable = true) => (
      <button
        className="merge button"
        onClick={() => redirectToReviewPage()}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className="tab-icon"
          icon={faCodeMerge}
        ></FontAwesomeIcon>
        <span className="icon-label">review</span>
      </button>
    ),
    download: (enable = true) => (
      <button
        className="download button"
        onClick={() => handleDownload()}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className="tab-icon"
          icon={faCircleDown}
        ></FontAwesomeIcon>
        <span className="icon-label">download</span>
      </button>
    ),
    upload: (enable = true) => (
      <button
        className="upload button"
        onClick={() => handleSelection('fileUpload')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className="tab-icon"
          icon={faCircleUp}
        ></FontAwesomeIcon>
        <span className="icon-label">upload</span>
      </button>
    ),
  };

  return (
    <div className="draft-header-container">
      <FileDialog
        open={openFiles}
        setOpen={setOpenFiles}
        selectedRowFilter={{ id: fileId }}
        onSelect={handleFileSelect}
      ></FileDialog>
      <div className="header-left">
        {showButton.file()}
        {hasFile ? showButton.branch() : null}
      </div>
      <div className="header-center"></div>
      <div className="header-right">
        {showButton.rebase(canRebase)}
        {showButton.commit(canCommit)}
        {showButton.submit(canSubmit)}
        {showButton.mergeDiff(canReview)}
        {showButton.download(canDownload)}
        {showButton.upload(canUpload)}
        <Spinner></Spinner>
      </div>
      {hasFile && hasBranch && openFileUpload ? (
        <FileUploadDialog
          fileId={fileId}
          branchId={branchId}
          open={openFileUpload}
          setOpen={setOpenFileUpload}
        ></FileUploadDialog>
      ) : null}
      {hasFile && openBranches ? (
        <BranchDialog
          fileId={fileId}
          open={openBranches}
          setOpen={setOpenBranches}
          selectedRowFilter={{ id: branchId }}
          params={orderDesc}
          onSelect={handleOnBranchSelect}
        ></BranchDialog>
      ) : null}
      {hasFile && hasBranch && openRebases ? (
        <RebaseDialog
          fileId={fileId}
          branchId={branchId}
          open={openRebases}
          setOpen={setOpenRebases}
        ></RebaseDialog>
      ) : null}
      {hasFile && hasBranch && openCommits ? (
        <CommitDialog
          fileId={fileId}
          branchId={branchId}
          open={openCommits}
          setOpen={setOpenCommits}
        ></CommitDialog>
      ) : null}
      {hasFile && hasBranch && openSubmits ? (
        <SubmitDialog
          fileId={fileId}
          branchId={branchId}
          open={openSubmits}
          setOpen={setOpenSubmits}
        ></SubmitDialog>
      ) : null}
    </div>
  );
};
export default Header;
