import React, { useState } from 'react';
import DraftColumnType from '../../../../../models/draft-column';
import { SelectOptions } from '../../../../../components/DataGrid/DataGrid';
import Dialog from '../../../../../components/Dialog/Dialog';
import { dataTypeList } from '../../../../../common/constants';
import './add.css';

const AddDialog = ({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: (data: boolean) => void;
  onSubmit: (data: DraftColumnType) => void;
}) => {
  const defaultValue = { name: '', type: '', map: '' };
  const [inputValue, setInputValue] = useState(defaultValue);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(inputValue);
    setOpen(false);
  };

  return (
    <Dialog selector="draft-column add-dialog" open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit} method="dialog">
        <label htmlFor="name">Name:</label>
        <br />
        <input
          type="text"
          name="name"
          value={inputValue.name}
          onChange={(e) => {
            setInputValue({ ...inputValue, name: e.target.value });
          }}
        />
        <br />
        <label htmlFor="type">Type:</label>
        <br />
        <div className="type-dropdown">
          <SelectOptions
            row={inputValue}
            targetColumn={'type'}
            onRowChange={(updatedValues: any, commitChanges: boolean) =>
              setInputValue(updatedValues)
            }
            values={dataTypeList}
          ></SelectOptions>
        </div>
        <div className="menu-buttons">
          <button type="button" onClick={() => setOpen(false)}>
            Cancel
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddDialog;
