import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faCodeCommit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loadCommits } from '../../../../state/process-log/commit/commitActions';
import { loadFileById } from '../../../../state/file/fileActions';
import { RootState } from '../../../../store';
import type ProcessLogType from '../../../../models/process-log';
import './commit.css';

const Commit = ({
  fileId,
  branchId,
  submitId,
  params,
  onSelect,
}: {
  fileId: number;
  branchId: number;
  submitId: number;
  params?: { [k: string]: any };
  onSelect?: (selectedData: ProcessLogType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const dispatch = useDispatch();
  const commits = useSelector((state: RootState) => state.commit.active.data);
  const handleCommitClick = (commitItem: ProcessLogType) => {
    if (onSelect) onSelect(commitItem);
  };
  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadCommits(fileId, branchId, baseParams));
  }, [dispatch, fileId, branchId, baseParams]);
  return (
    <div className="commit-container">
      <ul className="commit-list">
        {commits.map((commitItem, inx) => {
          return (
            <li
              className={`commit-item ${
                commitItem.referenceId === submitId ? 'selected' : ''
              }`}
              key={inx}
              onClick={() => handleCommitClick(commitItem)}
            >
              <FontAwesomeIcon
                className="icon"
                icon={faCodeCommit}
              ></FontAwesomeIcon>{' '}
              <span className="commit-id">#{commitItem.id} </span>
              <span className="commit-message">{commitItem.message}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Commit;
