import _ from 'lodash';
import { faPencil, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGrid,
  textEditorNorm,
  SelectColumn,
  EmptyDataGrid,
  HeaderRenderer,
  FilterContext,
  FilterFlagContext,
  pager,
} from '../../../components/DataGrid/DataGrid';
import Paginate from '../../../components/Paginate/Paginate';
import { isNothing } from '../../../common/utils';
import { GenericObjType, FilterType } from '../../../common/types';
import { loadColumns } from '../../../state/column/columnActions';
import { loadFileById } from '../../../state/file/fileActions';
import { loadBranchById } from '../../../state/branch/branchActions';
import { loadTableById } from '../../../state/table/tableActions';
import { RootState } from '../../../store';
import type ColumnType from '../../../models/column';
import './column.css';

const Column = ({
  fileId,
  branchId,
  tableId,
  params,
  onFilter = () => {},
  searchParams = {},
}: {
  fileId: number;
  branchId: number;
  tableId: number;
  params?: { [k: string]: any };
  searchParams?: GenericObjType;
  onFilter?: (a: GenericObjType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const baseSearchParams = useMemo(() => searchParams || {}, [searchParams]);
  const dispatch = useDispatch();
  const currentBranch = useSelector(
    (state: RootState) => state.branch.active.selected
  );
  const columns = useSelector((state: RootState) => state.column.active.data);
  const columnsMetaList = useSelector(
    (state: RootState) => state.column.active.metaList
  );
  const columnsMeta = useSelector(
    (state: RootState) => state.column.active.meta
  );
  const [editable, setEditable] = useState(false);
  const [forcePage, setForcePage] = useState(0);
  const [selectedPageNum, setSelectedPageNum] = useState(0);
  const [selectedPageRowNum, setSelectedPageRowNum] = useState(0);

  const userColumns = [
    SelectColumn,
    {
      key: 'rowNum',
      name: 'Row',
      maxWidth: 50,
      frozen: true,
      resizable: true,
    },
    {
      key: 'name',
      name: 'Column Name',
      editable: editable,
      renderEditCell: textEditorNorm,
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'name'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      resizable: true,
    },
    {
      key: 'type',
      name: 'Type',
      editable: editable,
      width: 100,
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'map'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      resizable: true,
    },
    {
      key: 'createdAt',
      name: 'Date Added',
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'createdAt'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      width: 200,
      resizable: true,
    },
    {
      key: 'updatedAt',
      name: 'Date Modified',
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'updatedAt'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      width: 200,
      resizable: true,
    },
  ];

  const [filterFlag, setFilterFlag] = useState(!isNothing(searchParams));
  const [filters, setFilters] = useState(baseSearchParams);

  const debouncedOnFilter = useRef(
    _.debounce((currentFilters) => {
      dispatch(
        loadColumns(fileId, tableId, {
          ...baseParams,
          where: { ...currentFilters, ...baseParams.where },
        })
      );
    }, 1000)
  ).current;

  const handleFilter = (currentFilters: FilterType<ColumnType>) => {
    setFilters(currentFilters);
    debouncedOnFilter(currentFilters);
    onFilter(currentFilters);
  };

  const handleToggleFilter = (isEnabled: boolean) => {
    const currentFilters = isEnabled ? filters : {};
    setFilterFlag(isEnabled);
    handleFilter(currentFilters);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    const currentPageNum = currentPageInx + 1;
    const pageStartRowNum = pager.detectPageStartRowNum(
      currentPageNum,
      columnsMetaList
    );
    setSelectedPageNum(currentPageNum);
    setSelectedPageRowNum(pageStartRowNum);
  };

  const loadPage = useCallback(
    (pageInx: number) => {
      const currentPage = pageInx + 1;
      const pageMeta = _.find(columnsMetaList, { currentPage });
      if (!_.isEmpty(pageMeta)) return pageMeta;
      dispatch(
        loadColumns(fileId, tableId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          page: currentPage,
        })
      );
      return pageMeta;
    },
    [dispatch, fileId, tableId, baseParams, columnsMetaList, filters]
  );

  const reloadColumns = useRef(
    _.debounce((fileId, tableId, baseParams, currentFilters) => {
      dispatch(
        loadColumns(fileId, tableId, {
          ...baseParams,
          where: { ...currentFilters, ...baseParams.where },
        })
      );
    }, 1000)
  ).current;

  useEffect(() => {
    reloadColumns(fileId, tableId, baseParams, filters);
  }, [
    reloadColumns,
    dispatch,
    fileId,
    tableId,
    baseParams,
    filters,
    currentBranch,
  ]);

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadBranchById(fileId, branchId));
    dispatch(loadTableById(fileId, tableId));
    dispatch(
      loadColumns(fileId, tableId, {
        ...baseParams,
        where: { ...filters, ...baseParams.where },
      })
    );
  }, [dispatch, fileId, branchId, tableId, filters, baseParams]);

  const isEmptyGrid = userColumns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="column-container">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="column-container">
      <FilterFlagContext.Provider value={filterFlag}>
        <FilterContext.Provider value={filters}>
          <div className="grid-toolbar">
            <div className="left-toolbar"></div>
            <div className="center-toolbar">
              <Paginate
                totalPages={Number(columnsMeta?.totalPages)}
                onPageClick={handlePageClick}
                forcePage={forcePage}
              />
            </div>
            <div className="right-toolbar">
              <button
                className={`filterable ${filterFlag ? 'selected' : ''}`}
                onClick={() => handleToggleFilter(!filterFlag)}
              >
                <FontAwesomeIcon icon={faFilter} />
                <span className="filter icon-label">Filter</span>
              </button>
              <button
                className={`editable ${editable ? 'selected' : ''}`}
                onClick={() => setEditable(!editable)}
                disabled={true}
              >
                <FontAwesomeIcon icon={faPencil} />
                <span className="edit icon-label">Edit</span>
              </button>
            </div>
          </div>
          <DataGrid<ColumnType>
            rowKeyGetter={(row) => String(row.id)}
            columns={userColumns}
            rows={columns}
            rowsMetaList={columnsMetaList}
            onPageChange={setForcePage}
            loadPage={loadPage}
            selectedPageNum={selectedPageNum}
            selectedPageRowNum={selectedPageRowNum}
            rowHeight={30}
          ></DataGrid>
        </FilterContext.Provider>
      </FilterFlagContext.Provider>
    </div>
  );
};

export default Column;
