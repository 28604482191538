import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loadSubmits } from '../../../../state/process-log/submit/submitActions';
import { RootState } from '../../../../store';
import Paginate from '../../../../components/Paginate/Paginate';
import { timeSince } from '../../../../common/utils';
import type ProcessLogType from '../../../../models/process-log';
import './submit.css';

const SubmitCard = ({
  params,
  onSelect,
  onShare,
  selectedRowFilter,
}: {
  params?: { [k: string]: any };
  onSelect?: (selectedData: ProcessLogType) => void;
  onShare?: (selectedData: ProcessLogType) => void;
  selectedRowFilter?: Partial<{ [k in keyof ProcessLogType]: any }>;
}) => {
  const dispatch = useDispatch();
  const baseParams = useMemo(() => params || {}, [params]);
  const [currentPage, setCurrentPage] = useState(0);
  const submits = useSelector((state: RootState) => state.submit.active.data);
  const submitsMeta = useSelector(
    (state: RootState) => state.submit.active.meta
  );
  const handlePageClick = async (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    setCurrentPage(currentPageInx);
  };

  const handleOnSelect = (selectedData: ProcessLogType) => {
    if (_.isEmpty(selectedData)) return;
    if (!onSelect) return;
    onSelect(selectedData);
  };

  useEffect(() => {
    dispatch(
      loadSubmits({
        ...baseParams,
        page: currentPage,
        include: [{ model: 'branch' }, { model: 'user' }],
      })
    );
  }, [dispatch, baseParams, currentPage]);

  return (
    <div className="submit-master-card-container">
      <div className="card-toolbar">
        <div className="left-toolbar"></div>
        <div className="center-toolbar">
          <Paginate
            totalPages={Number(submitsMeta?.totalPages)}
            onPageClick={handlePageClick}
            forcePage={currentPage}
          />
        </div>
        <div className="right-toolbar"></div>
      </div>
      <div className="submit-master-data">
        <div className="datacard-container">
          {submits.map((submitItem, inx) => {
            return (
              <div className="datacard" key={inx}>
                <div className="datacard-title">{submitItem.file?.name}</div>
                <div className="datacard-name-tag tag">
                  {submitItem.branch?.name}
                </div>
                <textarea
                  className="datacard-description"
                  name="name"
                  value={`SUBMIT #${submitItem.id}: ${submitItem.message}`}
                  readOnly
                />
                <div className="datacard-description-tag tag">description</div>
                <div className="datacard-user">{`modified by ${submitItem.user?.name}`}</div>
                <div className="datacard-createdat">{`created ${timeSince(
                  submitItem.createdAt
                )}`}</div>
                <div className="datacard-updatedat">{`updated ${timeSince(
                  submitItem.updatedAt
                )}`}</div>
                <div className="action-container">
                  <button
                    className="row-button"
                    onClick={() => handleOnSelect(submitItem)}
                  >
                    <FontAwesomeIcon icon={faFolderOpen} />
                    open
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SubmitCard;
