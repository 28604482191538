import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateUUID } from '../../../../../common/utils';
import { GenericObjType } from '../../../../../common/types';
import { loadColumns } from '../../../../../state/column/columnActions';
import { loadFileById } from '../../../../../state/file/fileActions';
import { loadBranchById } from '../../../../../state/branch/branchActions';
import { loadTableById } from '../../../../../state/table/tableActions';
import { RootState } from '../../../../../store';
import Params from '../../../components/params/Params';

const MasterRowParams = ({
  fileId,
  branchId,
  tableId,
  onChange,
}: {
  fileId: number;
  branchId: number;
  tableId: number;
  onChange?: React.Dispatch<React.SetStateAction<GenericObjType>>;
}) => {
  const cachePrefix = 'connect-master-row-params';
  const deriveCacheKey = useCallback(() => {
    const cacheBase = generateUUID({ fileId, branchId, tableId }, [
      'fileId',
      'branchId',
      'tableId',
    ]);
    const cacheKey = `${cachePrefix}-${cacheBase}`;
    return cacheKey;
  }, [fileId, branchId, tableId]);

  const dispatch = useDispatch();
  const masterColumns = useSelector(
    (state: RootState) => state.column.active.data
  );
  const masterColumnsValue = masterColumns.map((masterColumnData) => {
    const { id, rowNum, name, type } = masterColumnData;
    return { id, rowNum, name, type, value: '' };
  });

  const draftColumnsMetaList = useSelector(
    (state: RootState) => state.draftColumn.active.metaList
  );

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadBranchById(fileId, branchId));
    dispatch(loadTableById(fileId, tableId));
    dispatch(loadColumns(fileId, tableId));
  }, [dispatch, fileId, branchId, tableId]);

  return (
    <Params
      rows={masterColumnsValue}
      rowsMetaList={draftColumnsMetaList}
      deriveCacheKey={deriveCacheKey}
      onChange={onChange}
    ></Params>
  );
};

export default MasterRowParams;
