import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquarePlus,
  faSquareMinus,
  faCodePullRequest,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import Paginate from '../../../../components/Paginate/Paginate';
import {
  loadSubmitsByBranch,
  loadSubmitById,
} from '../../../../state/process-log/submit/submitActions';
import { loadFileById } from '../../../../state/file/fileActions';
import { RootState } from '../../../../store';
import type ProcessLogType from '../../../../models/process-log';
import './submit.css';

const Submit = ({
  fileId,
  branchId,
  params,
  onSelect,
  onAccept,
  onReject,
  children,
}: {
  fileId: number;
  branchId: number;
  params?: { [k: string]: any };
  onSelect?: (selectedData: ProcessLogType) => void;
  onAccept?: (selectedData: ProcessLogType[]) => void;
  onReject?: (selectedData: ProcessLogType[]) => void;
  children?: React.ReactElement;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const dispatch = useDispatch();
  const submits = useSelector((state: RootState) => state.submit.active.data);
  const submitsMeta = useSelector(
    (state: RootState) => state.submit.active.meta
  );
  const currentBranch = useSelector(
    (state: RootState) => state.branch.active.selected
  );
  const currentSubmit = useSelector(
    (state: RootState) => state.submit.active.selected
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedSubmits, setSelectedSubmits] = useState<ProcessLogType[] | []>(
    []
  );
  const [, setSearchParams] = useSearchParams();
  const handleSubmitClick = (submitItem: ProcessLogType) => {
    if (submitItem.id === currentSubmit?.id) return;
    setSearchParams((prevSearch) => {
      prevSearch.set('submitId', String(submitItem.id));
      return prevSearch;
    });
    dispatch(loadSubmitById(fileId, branchId, Number(submitItem.id)));
    if (onSelect) onSelect(submitItem);
  };
  const handleCheckboxChange = (submitItem: ProcessLogType) => {
    const selectedSubmit = selectedSubmits.find(
      (item: ProcessLogType) => item.id === submitItem.id
    );
    if (!_.isEmpty(selectedSubmit)) {
      setSelectedSubmits((prevData) =>
        prevData.filter((item) => item.id !== submitItem.id)
      );
    } else {
      setSelectedSubmits((prevData) => [...prevData, submitItem]);
    }
  };
  const handleIsChecked = (submitItem: ProcessLogType) => {
    const currentSubmitItem = selectedSubmits.find(
      (item) => item.id === submitItem.id
    );
    return !_.isEmpty(currentSubmitItem);
  };
  const handlePageClick = async (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    setCurrentPage(currentPageInx);
  };
  const handleOnAccept = () => {
    if (onAccept) onAccept(selectedSubmits);
  };
  const handleOnReject = () => {
    if (onReject) onReject(selectedSubmits);
  };
  const handleSelectAll = (switchFlag: boolean) => {
    setSelectAll(switchFlag);
    if (switchFlag) {
      setSelectedSubmits(submits);
    } else {
      setSelectedSubmits([]);
    }
  };

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadSubmitsByBranch(fileId, branchId, baseParams));
  }, [dispatch, fileId, branchId, baseParams, currentBranch]);

  return (
    <div className="submit-container">
      <div className="grid-toolbar">
        <div className="left-toolbar">
          <div className="select-all-container">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={() => handleSelectAll(!selectAll)}
            />
            <button type="button" onClick={() => handleSelectAll(!selectAll)}>
              Select All
            </button>
          </div>
          <button type="button" onClick={handleOnAccept}>
            <FontAwesomeIcon icon={faSquarePlus} />
            <span className="accept icon-label">Accept</span>
          </button>
          <button type="button" onClick={handleOnReject}>
            <FontAwesomeIcon icon={faSquareMinus} />
            <span className="reject icon-label">Reject</span>
          </button>
        </div>
        <div className="center-toolbar"></div>
        <div className="right-toolbar">
          <Paginate
            totalPages={Number(submitsMeta?.totalPages)}
            onPageClick={handlePageClick}
            forcePage={currentPage}
          />
        </div>
      </div>
      <ul className="submit-list">
        {submits.map((submitItem, inx) => {
          return (
            <div className="submit-item-container" key={inx}>
              <input
                type="checkbox"
                checked={handleIsChecked(submitItem)}
                onChange={() => handleCheckboxChange(submitItem)}
              />
              <li
                className="submit-item"
                key={inx}
                onClick={() => handleSubmitClick(submitItem)}
              >
                <div
                  className={`submit-message-container ${
                    submitItem.id === currentSubmit?.id ? 'selected' : ''
                  }`}
                >
                  <div>
                    <FontAwesomeIcon
                      className="icon"
                      icon={faCodePullRequest}
                    ></FontAwesomeIcon>{' '}
                    <span className="submit-id">#{submitItem.id} </span>
                    <span className="submit-message">{submitItem.message}</span>
                  </div>
                  <FontAwesomeIcon
                    className="icon"
                    icon={
                      submitItem.id === currentSubmit?.id
                        ? faCaretUp
                        : faCaretDown
                    }
                  ></FontAwesomeIcon>{' '}
                </div>
                <div className="commit-list">
                  {submitItem.id === currentSubmit?.id ? children : null}
                </div>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default Submit;
