import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { actionManyMap, actionResponseMap } from '../../../common/state/types';
import type { StateType } from '../../../common/state/types';
import type GroupType from '../../../models/group';
import { handleError } from '../../alert/alertActions';

const domainSize = process.env.REACT_APP_GROUPS_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const loadGroups =
  (
    fileId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<GroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/file-groups/files/${fileId}/share`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.GROUPBYFILESHARE_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUPBYFILESHARE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const removeGroups =
  (
    fileId: number,
    payload: GroupType[]
  ): ThunkAction<void, StateType<GroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const normPayload = payload.map((item) => ({ ...item, fileId }));
    try {
      dispatch({
        type: actionManyMap.GROUPBYFILESHARE_DATALIST_DELETED,
        payload: normPayload,
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUPBYFILESHARE_DATA_FAILED,
        payload: null,
      });
    }
  };
