import React, { useEffect, useCallback, ReactNode } from 'react';
import './dialog.css';

const Dialog = ({
  selector,
  open,
  setOpen,
  children,
}: {
  selector: string;
  open: boolean;
  setOpen: (data: boolean) => void;
  children: ReactNode;
}) => {
  const targetDialog = (classStr: string) => {
    const selectorStr = classStr.replace(/ /g, '.');
    const dialog = document.querySelector(
      `.${selectorStr}`
    ) as HTMLDialogElement | null;
    return dialog;
  };
  const handleOpen = useCallback(
    (isOpen: boolean) => {
      const dialog = targetDialog(selector);
      if (dialog != null) {
        if (isOpen) {
          dialog.showModal();
        } else {
          dialog.close();
        }
      }
    },
    [selector]
  );
  const dialogListener = useCallback(
    (action: 'add' | 'remove') => {
      const dialog = targetDialog(selector);
      if (dialog != null) {
        const dialogOutside = (e: any) => {
          const dialogDimensions = dialog.getBoundingClientRect();
          if (
            e.clientX < dialogDimensions.left ||
            e.clientX > dialogDimensions.right ||
            e.clientY < dialogDimensions.top ||
            e.clientY > dialogDimensions.bottom
          ) {
            setOpen(false);
            dialog.close();
          }
        };
        if (action === 'add') {
          dialog.addEventListener('click', dialogOutside);
        } else {
          dialog.removeEventListener('click', dialogOutside);
        }
      }
    },
    [setOpen, selector]
  );

  useEffect(() => {
    handleOpen(open);
  }, [open, handleOpen]);

  useEffect(() => {
    dialogListener('add');
    return dialogListener('remove');
  }, [dialogListener, selector]);

  return <dialog className={selector}>{children}</dialog>;
};

export default Dialog;
