import type BranchType from '../../models/branch';
import type { ReducerType, StateType } from '../../common/state/types';
import {
  actionOneMap,
  actionResponseMap,
  actionFlagMap,
} from '../../common/state/types';
import { initialState } from '../../common/state/utils/constant';
import {
  dataListLoadedReducer,
  dataAddedReducer,
  dataUpdatedReducer,
  dataDeletedReducer,
  dataLoadedReducer,
  dataFailedReducer,
  flagReducer,
  dataFirstReducer,
} from '../../common/state/reducers';

const branchReducer: ReducerType<BranchType> = (
  state = {
    active: initialState as StateType<BranchType>,
    rollback: initialState as StateType<BranchType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.BRANCH_DATALIST_LOADED:
      return dataListLoadedReducer<BranchType>({
        state,
        action,
      });
    case actionOneMap.BRANCH_DATA_ADDED:
      return dataAddedReducer<BranchType>({ state, action });
    case actionOneMap.BRANCH_DATA_UPDATED:
      return dataUpdatedReducer<BranchType>({ state, action });
    case actionOneMap.BRANCH_DATA_DELETED:
      return dataDeletedReducer<BranchType>({ state, action });
    case actionOneMap.BRANCH_DATA_LOADED:
      return dataLoadedReducer<BranchType>({ state, action });
    case actionOneMap.BRANCH_DATA_FIRST:
      return dataFirstReducer<BranchType>({ state, action });
    case actionResponseMap.BRANCH_DATA_FAILED:
      return dataFailedReducer<BranchType>({ state, action });
    case actionFlagMap.BRANCH_LOADING:
      return flagReducer<BranchType>({
        state,
        action: { ...action, payload: { loading: true } },
      });
    case actionFlagMap.BRANCH_COMPLETED:
      return flagReducer<BranchType>({
        state,
        action: { ...action, payload: { loading: false } },
      });
    case actionFlagMap.BRANCH_RELOAD:
      return flagReducer<BranchType>({
        state,
        action: { ...action, payload: { reload: true } },
      });
    default:
      return state;
  }
};

export default branchReducer;
