import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableColumns, faTableList } from '@fortawesome/free-solid-svg-icons';
import { loadTables } from '../../../state/table/tableActions';
import { loadFileById } from '../../../state/file/fileActions';
import { loadBranchById } from '../../../state/branch/branchActions';
import { RootState } from '../../../store';
import type TableType from '../../../models/table';
import TableDialog from '../dialog/grid/Table';
import './table.css';

const TableList = ({
  fileId,
  branchId,
  params,
  selectedRowFilter,
  onSelectOption,
  onSelectTable,
}: {
  fileId: number;
  branchId: number;
  onSelectOption?: (a: boolean) => void;
  params?: { [k: string]: any };
  selectedRowFilter?: Partial<{ [k in keyof TableType]: any }>;
  onSelectTable?: (selectedData: TableType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const dispatch = useDispatch();
  const tables = useSelector((state: RootState) => state.table.active.data);
  const selectedTable = useSelector(
    (state: RootState) => state.table.active.selected
  );
  const tablesMeta = useSelector((state: RootState) => state.table.active.meta);
  const [openDialog, setOpenDialog] = useState(false);
  const handleOnSelect = (currentTable: TableType) => {
    setOpenDialog(false);
    onSelectTable && onSelectTable(currentTable);
  };

  const scrollToSelectedElem = () => {
    const selectedElem = document.getElementById('table selected');
    selectedElem?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleOnSelectOption = () => {
    if (!onSelectOption) return;
    onSelectOption(true);
  };

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadBranchById(fileId, branchId));
    dispatch(loadTables(fileId, baseParams));
    scrollToSelectedElem();
  }, [dispatch, fileId, branchId, baseParams]);

  useEffect(() => {
    scrollToSelectedElem();
  }, [dispatch, selectedTable]);

  return (
    <div className="master-table-list-container">
      <div className="table-summary-container">
        <button onClick={() => setOpenDialog(true)}>
          <FontAwesomeIcon
            className="tab-icon"
            icon={faTableList}
          ></FontAwesomeIcon>
          <span className="icon-label">
            {`${tablesMeta.endRecord - tablesMeta.startRecord + 1} of ${
              tablesMeta.totalRecords
            }`}
          </span>
        </button>
        {openDialog ? (
          <TableDialog
            fileId={fileId}
            branchId={branchId}
            open={openDialog}
            setOpen={setOpenDialog}
            selectedRowFilter={selectedRowFilter}
            onSelectTable={handleOnSelect}
          ></TableDialog>
        ) : null}
      </div>
      <div className="table-list-container">
        <ul className="table-list">
          {tables.map((item, inx) => {
            const [[key, value]] = Object.entries(selectedRowFilter || {});
            const isSelected =
              item.hasOwnProperty(key) &&
              item[key as keyof TableType] === value;
            const selectionTag = isSelected ? 'table selected' : '';
            return (
              <li
                key={inx}
                onClick={() => handleOnSelect(item)}
                className={selectionTag}
                id={selectionTag}
              >
                <span className="table-name icon-label">{item.name}</span>
                <button
                  className="icon caret table"
                  onClick={() => handleOnSelectOption()}
                >
                  <FontAwesomeIcon icon={faTableColumns}></FontAwesomeIcon>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TableList;
