import React from 'react';
import Table from '../../grid/Table';
import TableType from '../../../../models/table';
import Dialog from '../../../../components/Dialog/Dialog';
import './table.css';

const TableDialog = ({
  fileId,
  branchId,
  open,
  setOpen,
  onSubmit,
  selectedRowFilter,
  onSelectTable,
}: {
  fileId: number;
  branchId: number;
  open: boolean;
  setOpen: (data: boolean) => void;
  onSubmit?: () => void;
  selectedRowFilter?: Partial<{ [k in keyof TableType]: any }>;
  onSelectTable?: (selectedData: TableType) => void;
}) => {
  return (
    <Dialog selector="table-dialog" open={open} setOpen={setOpen}>
      <div className="dialog-content">
        <Table
          fileId={fileId}
          branchId={branchId}
          onSelect={onSelectTable}
          selectedRowFilter={selectedRowFilter}
        ></Table>
      </div>
      <div className="menu-buttons">
        <button type="button" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
    </Dialog>
  );
};

export default TableDialog;
