import { createContext, useContext } from 'react';
import { GenericObjType, FilterType } from '../../../../common/types';
import { parseDiff } from '../../../../common/utils';
import DiffText from '../../../DiffText';
import FilterByCondition from './Condition';

const handleColumnName = ({ column }: { column: any }) => {
  const { name, props } = column;
  const columnDiff = parseDiff(props?.diff);
  if (!columnDiff.isDiff) return name;
  return (
    <>
      {props.status === 'submitted' ? (
        <span className="change-tag">{`#${props.submitId}-${props.commitId}`}</span>
      ) : null}
      <DiffText keyValue={columnDiff.value}></DiffText>
    </>
  );
};

const FilterContext = createContext<FilterType<GenericObjType | {}>>({});
const FilterFlagContext = createContext<boolean>(false);

const FilterRenderer = ({
  tabIndex,
  column,
  children,
}: any & {
  children: (args: {
    tabIndex: number;
    filters: FilterType<GenericObjType>;
  }) => React.ReactElement;
}) => {
  const filters = useContext(FilterContext);
  const filterFlag = useContext(FilterFlagContext);
  return (
    <>
      <div>{handleColumnName({ column })}</div>
      {filterFlag && <div>{children({ tabIndex, filters })}</div>}
    </>
  );
};

const HeaderRenderer = ({
  p,
  targetColumn,
  setFilters,
}: {
  p: any;
  targetColumn: string;
  setFilters: (currentFilters: FilterType<GenericObjType>) => void;
}) => {
  return (
    <FilterRenderer {...p}>
      {({
        filterFlag,
        filters,
        ...rest
      }: {
        filters: FilterType<GenericObjType>;
        filterFlag: boolean;
      }) => {
        return (
          <div className="datagrid-filter-renderer">
            <FilterByCondition<FilterType<GenericObjType>>
              filters={filters}
              targetColumn={targetColumn}
              setFilters={setFilters}
              {...rest}
            ></FilterByCondition>
          </div>
        );
      }}
    </FilterRenderer>
  );
};

export { HeaderRenderer, FilterContext, FilterFlagContext };
