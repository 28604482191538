import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { actionResponseMap, actionOneMap } from '../../common/state/types';
import type TableType from '../../models/table';
import type { StateType } from '../../common/state/types';
import { handleError } from '../alert/alertActions';

const domainSize = process.env.REACT_APP_TABLES_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const loadTableById =
  (
    fileId: number,
    tableId: number
  ): ThunkAction<void, StateType<TableType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      const res = await axios.get(`/api/files/${fileId}/tables/${tableId}`);
      dispatch({
        type: actionOneMap.TABLE_DATA_LOADED,
        payload: res.data,
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.TABLE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadTables =
  (
    fileId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<TableType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/files/${fileId}/tables/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.TABLE_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.TABLE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const loadFirstTable =
  (
    fileId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<TableType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: 1,
      size: 1,
    };
    const queryPath = `/api/files/${fileId}/tables/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionOneMap.TABLE_DATA_FIRST,
        payload: res.data.data[0],
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.TABLE_DATA_FAILED,
        payload: null,
      });
    }
  };
