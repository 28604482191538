import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import type BranchType from '../../models/branch';
import type { StateType } from '../../common/state/types';
import {
  actionOneMap,
  actionResponseMap,
  actionFlagMap,
} from '../../common/state/types';
import { handleError, setAlert } from '../alert/alertActions';
import domainModule from '../../workers/branch/worker';
import workerManager from '../../workers';
import { attachHeaders } from '../../common/utils';

const domainSize = process.env.REACT_APP_BRANCH_DATA_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const reloadBranches =
  (): ThunkAction<void, StateType<BranchType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({ type: actionFlagMap.BRANCH_RELOAD });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.BRANCH_DATA_FAILED,
        payload: null,
      });
    }
  };

export const syncBranch =
  (branchInfo: BranchType) => async (dispatch: Dispatch) => {
    const eventListener = (event: MessageEvent<any>) => {
      const branchLatest = event?.data;
      const { fileId, name, status, statusMessage } = branchLatest;
      if (status === 'success') {
        dispatch(setAlert(`Adding "${name}" branch successful`, 'success'));
        dispatch({
          type: actionOneMap.BRANCH_DATA_UPDATED,
          payload: branchLatest,
        });
      }
      if (status === 'error') {
        console.log(statusMessage, 'error');
        dispatch(setAlert(statusMessage, 'error'));
      }
      dispatch(loadBranches(fileId));
    };

    if (branchInfo?.status === 'pending') {
      const eventMessage = attachHeaders(branchInfo);
      if (window.SharedWorker) {
        const domainWorker = workerManager.getWorker('branch', domainModule);
        domainWorker.port.addEventListener('message', eventListener, false);
        domainWorker.port.start();
        domainWorker.port.postMessage(eventMessage);
      } else {
        const statusMessage = 'Worker not found. You may refresh manually';
        dispatch(setAlert(statusMessage, 'warning'));
      }
    }
  };

export const loadBranches =
  (
    fileId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<BranchType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/files/${fileId}/branches/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.BRANCH_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: 'BRANCH_DATA_FAILED',
        payload: null,
      });
      handleError(dispatch, err);
    }
  };

export const loadFirstBranch =
  (
    fileId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<BranchType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: 1,
      size: 1,
    };
    const queryPath = `/api/files/${fileId}/branches/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionOneMap.BRANCH_DATA_FIRST,
        payload: res.data.data[0],
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: 'BRANCH_DATA_FAILED',
        payload: null,
      });
      handleError(dispatch, err);
    }
  };

export const loadBranchById =
  (
    fileId: number,
    branchId: number
  ): ThunkAction<void, StateType<BranchType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = `/api/files/${fileId}/branches/${branchId}`;
    try {
      const res = await axios.get(queryPath);
      dispatch(syncBranch(res.data));
      dispatch({
        type: actionOneMap.BRANCH_DATA_LOADED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.BRANCH_DATA_FAILED,
        payload: null,
      });
      handleError(dispatch, err);
    }
  };

export const loadDefaultBranch =
  (
    fileId: number
  ): ThunkAction<void, StateType<BranchType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = `/api/files/${fileId}/branches/default`;
    try {
      const res = await axios.get(queryPath);
      dispatch(syncBranch(res.data));
      dispatch({
        type: actionOneMap.BRANCH_DATA_LOADED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.BRANCH_DATA_FAILED,
        payload: null,
      });
      handleError(dispatch, err);
    }
  };

export const addBranch =
  (
    fileId: number,
    payload: BranchType
  ): ThunkAction<void, StateType<BranchType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payload });
    const queryPath = `/api/files/${fileId}/branches/`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch({
        type: actionOneMap.BRANCH_DATA_ADDED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
      dispatch(syncBranch(res.data));
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.BRANCH_DATA_FAILED,
        payload: null,
      });
    }
  };

export const updateBranchById =
  (
    fileId: number,
    branchId: number,
    payload: BranchType
  ): ThunkAction<void, StateType<BranchType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const payloadData = { ...payload, id: branchId, fileId };
    const body = JSON.stringify({ data: payloadData });
    const queryPath = `/api/files/${fileId}/branches/${branchId}`;
    try {
      dispatch({
        type: actionOneMap.BRANCH_DATA_UPDATED,
        payload: payloadData,
        path: { url: queryPath, params: {} },
      });
      await axios.put(queryPath, body, config);
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.BRANCH_DATA_FAILED,
        payload: null,
      });
    }
  };

export const removeBranchById =
  (
    fileId: number,
    branchId: number,
    payload: BranchType
  ): ThunkAction<void, StateType<BranchType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const payloadData = { ...payload, id: branchId, fileId };
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
      data: JSON.stringify({ data: payloadData }),
    };
    const queryPath = `/api/files/${fileId}/branches/${branchId}`;
    try {
      dispatch({
        type: actionOneMap.BRANCH_DATA_DELETED,
        payload: payloadData,
        path: { url: queryPath, params: {} },
      });
      await axios.delete(queryPath, config);
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.BRANCH_DATA_FAILED,
        payload: null,
      });
    }
  };

export const refreshBranchById =
  (
    fileId: number,
    branchId: number
  ): ThunkAction<void, StateType<BranchType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = `/api/files/${fileId}/branches/${branchId}/refresh`;
    try {
      const res = await axios.get(queryPath);
      dispatch({
        type: actionOneMap.BRANCH_DATA_LOADED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.BRANCH_DATA_FAILED,
        payload: null,
      });
      handleError(dispatch, err);
    }
  };
