import { faCog, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './spinner.css';

const Spinner = ({ loading = true }: { loading?: boolean }) => {
  return (
    <div className="spinner-container">
      <div>
        {loading ? (
          <FontAwesomeIcon
            className="icon fa-spin"
            icon={faCog}
          ></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon
            className="fa-spin"
            icon={faCircleCheck}
          ></FontAwesomeIcon>
        )}
      </div>
    </div>
  );
};

export default Spinner;
