import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { actionOneMap, actionResponseMap } from '../../../common/state/types';
import type ProcessLogType from '../../../models/process-log';
import type { StateType } from '../../../common/state/types';
import { setAlert } from '../../alert/alertActions';
import { attachHeaders } from '../../../common/utils';
import domainModule from '../../../workers/merge/worker';
import workerManager from '../../../workers';
import { loadBranchById } from '../../branch/branchActions';
import { reloadDraftRows } from '../../draft-row/draftRowActions';
import { reloadDraftColumns } from '../../draft-column/draftColumnActions';
import { reloadDraftTables } from '../../draft-table/draftTableActions';
import { reloadCommits } from '../commit/commitActions';
import { reloadSubmits } from '../submit/submitActions';
import { reloadRebases } from '../rebase/rebaseActions';
import { handleError } from '../../alert/alertActions';

const domainSize = process.env.REACT_APP_MERGES_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const syncMerge =
  (mergeInfo: ProcessLogType) => async (dispatch: Dispatch) => {
    const eventListener = (event: MessageEvent<any>) => {
      const mergeLatest = event?.data;
      const { fileId, branchId, status, statusMessage } = mergeLatest;
      if (['merged', 'rejected'].includes(status)) {
        dispatch({
          type: actionOneMap.MERGE_DATA_UPDATED,
          payload: mergeLatest,
        });
        if (status === 'merged') {
          dispatch(setAlert('Merge acceptance successful', 'success'));
        }
        if (status === 'rejected') {
          dispatch(setAlert('Merge rejection successful', 'success'));
        }
        dispatch(loadBranchById(fileId, branchId));
        dispatch(reloadDraftRows());
        dispatch(reloadDraftColumns());
        dispatch(reloadDraftTables());
        dispatch(reloadCommits());
        dispatch(reloadSubmits());
        dispatch(reloadRebases());
      }
      if (status === 'error') {
        dispatch(setAlert(statusMessage, 'error'));
        dispatch(loadBranchById(fileId, branchId));
        dispatch(reloadDraftRows());
        dispatch(reloadDraftColumns());
        dispatch(reloadDraftTables());
        dispatch(reloadCommits());
        dispatch(reloadSubmits());
        dispatch(reloadRebases());
      }
    };

    if (mergeInfo?.status === 'pending') {
      const eventMessage = attachHeaders(mergeInfo);
      if (window.SharedWorker) {
        const domainWorker = workerManager.getWorker('merge', domainModule);
        domainWorker.port.addEventListener('message', eventListener, false);
        domainWorker.port.start();
        domainWorker.port.postMessage(eventMessage);
      } else {
        const statusMessage = 'Worker not found. You may refresh manually';
        dispatch(setAlert(statusMessage, 'warning'));
      }
    }
  };

export const loadMerges =
  (
    fileId: number,
    branchId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/files/${fileId}/branches/${branchId}/merges/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.MERGE_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.MERGE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const acceptMerge =
  (
    fileId: number,
    branchId: number,
    submits: ProcessLogType[],
    message: string
  ): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: submits, meta: { message } });
    const queryPath = `/api/files/${fileId}/branches/${branchId}/merges/accept`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch({
        type: actionOneMap.MERGE_DATA_ADDED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
      dispatch(syncMerge(res.data));
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.MERGE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const rejectMerge =
  (
    fileId: number,
    branchId: number,
    submits: ProcessLogType[],
    message: string
  ): ThunkAction<void, StateType<ProcessLogType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: submits, meta: { message } });
    const queryPath = `/api/files/${fileId}/branches/${branchId}/merges/reject`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch({
        type: actionOneMap.MERGE_DATA_ADDED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
      dispatch(syncMerge(res.data));
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.MERGE_DATA_FAILED,
        payload: null,
      });
    }
  };
