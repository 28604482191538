import type DraftColumnType from '../../models/draft-column';
import type { ReducerType, StateType } from '../../common/state/types';
import { initialState } from '../../common/state/utils/constant';
import {
  actionOneMap,
  actionResponseMap,
  actionManyMap,
  actionFlagMap,
} from '../../common/state/types';
import {
  dataListLoadedReducer,
  dataListAddedReducer,
  dataListUpdatedReducer,
  dataListDeletedReducer,
  dataLoadedReducer,
  dataFailedReducer,
  flagReducer,
} from '../../common/state/reducers';

const draftColumnReducer: ReducerType<DraftColumnType> = (
  state = {
    active: initialState as StateType<DraftColumnType>,
    rollback: initialState as StateType<DraftColumnType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.DRAFTCOLUMN_DATALIST_LOADED:
      return dataListLoadedReducer<DraftColumnType>({
        state,
        action,
      });
    case actionOneMap.DRAFTCOLUMN_DATA_LOADED:
      return dataLoadedReducer<DraftColumnType>({
        state,
        action,
      });
    case actionManyMap.DRAFTCOLUMN_DATALIST_ADDED:
      return dataListAddedReducer<DraftColumnType>({
        state,
        action,
      });
    case actionManyMap.DRAFTCOLUMN_DATALIST_UPDATED:
      return dataListUpdatedReducer<DraftColumnType>({
        state,
        action,
      });
    case actionManyMap.DRAFTCOLUMN_DATALIST_DELETED:
      return dataListDeletedReducer<DraftColumnType>({
        state,
        action,
      });
    case actionResponseMap.DRAFTCOLUMN_DATA_FAILED:
      return dataFailedReducer<DraftColumnType>({
        state,
        action,
      });
    case actionFlagMap.DRAFTCOLUMN_LOADING:
      return flagReducer<DraftColumnType>({
        state,
        action: { ...action, payload: { loading: true } },
      });
    case actionFlagMap.DRAFTCOLUMN_COMPLETED:
      return flagReducer<DraftColumnType>({
        state,
        action: { ...action, payload: { loading: false } },
      });
    case actionFlagMap.DRAFTCOLUMN_RELOAD:
      return flagReducer<DraftColumnType>({
        state,
        action: { ...action, payload: { reload: true } },
      });
    default:
      return state;
  }
};

export default draftColumnReducer;
