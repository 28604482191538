import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faFile,
  faChartLine,
  faRightFromBracket,
  faUsersGear,
  faPlug,
  faFileCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { logoutUser } from '../../state/user/userActions';
import { logoutGroup } from '../../state/group/groupActions';
import './sidebar.css';

const Sidebar = ({
  open,
  onToggle,
}: {
  open: boolean;
  onToggle: (open: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectToUserPage = () => {
    navigate('/users');
  };
  const redirectToHomePage = () => {
    navigate('/');
  };
  const redirectToConnectPage = () => {
    navigate('/connects/masters');
  };
  const redirectToReviewPage = () => {
    navigate('/reviews');
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(logoutGroup());
    redirectToHomePage();
  };

  return (
    <>
      <div className="sidebar-container">
        <div className="header">
          <div className="brand-container" onClick={() => redirectToHomePage()}>
            <img
              className="brand-icon"
              src={`${process.env.PUBLIC_URL}/tablea.png`}
              alt="brand"
            />
            <span className="brand-label text">tablea</span>
          </div>
          <FontAwesomeIcon
            className={`sidebar-toggle${open ? '' : ' close'}`}
            onClick={() => onToggle(!open)}
            icon={open ? faCircleChevronLeft : faCircleChevronRight}
          ></FontAwesomeIcon>
        </div>
        <div className="center">
          <ul className="menu-bar">
            <li className="nav-bar" onClick={() => redirectToHomePage()}>
              <div className="link-container">
                <FontAwesomeIcon
                  className="icon"
                  icon={faFile}
                ></FontAwesomeIcon>
                <span className="user text">home</span>
              </div>
            </li>
          </ul>
          <ul className="menu-bar">
            <li className="nav-bar" onClick={() => redirectToReviewPage()}>
              <div className="link-container">
                <FontAwesomeIcon
                  className="icon"
                  icon={faFileCircleCheck}
                ></FontAwesomeIcon>
                <span className="user text">review</span>
              </div>
            </li>
          </ul>
          <ul className="menu-bar">
            <li className="nav-bar" onClick={() => console.log('insights')}>
              <div className="link-container">
                <FontAwesomeIcon
                  className="icon"
                  icon={faChartLine}
                ></FontAwesomeIcon>
                <span className="user text">insights</span>
              </div>
            </li>
          </ul>
          <ul className="menu-bar">
            <li className="nav-bar" onClick={() => redirectToUserPage()}>
              <div className="link-container">
                <FontAwesomeIcon
                  className="icon"
                  icon={faUsersGear}
                ></FontAwesomeIcon>
                <span className="user text">users</span>
              </div>
            </li>
          </ul>
          <ul className="menu-bar">
            <li className="nav-bar" onClick={() => redirectToConnectPage()}>
              <div className="link-container">
                <FontAwesomeIcon
                  className="icon"
                  icon={faPlug}
                ></FontAwesomeIcon>
                <span className="connect text">connect</span>
              </div>
            </li>
          </ul>
        </div>
        <div className="footer">
          <ul className="menu-bar">
            <li className="nav-bar" onClick={() => handleLogout()}>
              <div className="link-container">
                <FontAwesomeIcon
                  className="icon"
                  icon={faRightFromBracket}
                ></FontAwesomeIcon>
                <span className="group text">sign out</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
