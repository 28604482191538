import React from 'react';
import User from '../../grid/User';
import Dialog from '../../../../../components/Dialog/Dialog';
import type UserType from '../../../../../models/user';
import './user.css';

const UserDialog = ({
  groupId,
  params,
  open,
  setOpen,
  onSelect,
}: {
  groupId: number;
  params?: { [k: string]: any };
  open: boolean;
  setOpen: (data: boolean) => void;
  onSelect: (data: UserType[]) => void;
}) => {
  return (
    <Dialog selector="user-dialog join-dialog" open={open} setOpen={setOpen}>
      <div className="dialog-content">
        <User groupId={groupId} params={params} onSelect={onSelect}></User>
      </div>
      <div className="menu-buttons">
        <button type="button" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
    </Dialog>
  );
};

export default UserDialog;
