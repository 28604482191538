import React from 'react';
import Group from '../../grid/Group';
import Dialog from '../../../../../components/Dialog/Dialog';
import UserGroupType from '../../../../../models/user-group';
import './group.css';

const GroupDialog = ({
  userId,
  params,
  open,
  setOpen,
  onSelect,
}: {
  userId: number;
  params?: { [k: string]: any };
  open: boolean;
  setOpen: (data: boolean) => void;
  onSelect?: (selectedData: UserGroupType) => void;
}) => {
  const handleOnSelect = (selectedData: UserGroupType) => {
    if (onSelect) {
      onSelect(selectedData);
    }
    setOpen(false);
  };
  return (
    <Dialog selector="group-dialog" open={open} setOpen={setOpen}>
      <div className="dialog-content">
        <Group
          userId={userId}
          params={params}
          onSelect={handleOnSelect}
        ></Group>
      </div>
      <div className="menu-buttons">
        <button type="button" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
    </Dialog>
  );
};

export default GroupDialog;
