import React, { useState } from 'react';
import BranchType from '../../../../../models/branch';
import Dialog from '../../../../../components/Dialog/Dialog';
import './add.css';

const AddDialog = ({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: (data: boolean) => void;
  onSubmit: (data: BranchType) => void;
}) => {
  const defaultValue = { name: '', type: '', map: '' };
  const [inputValue, setInputValue] = useState(defaultValue);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(inputValue);
    setOpen(false);
  };

  return (
    <Dialog selector="branch add-dialog" open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit} method="dialog">
        <div>Please provide a name for the branch</div>
        <input
          type="text"
          name="name"
          value={inputValue.name}
          onChange={(e) => {
            setInputValue({ ...inputValue, name: e.target.value });
          }}
        />
        <div className="menu-buttons">
          <button type="button" onClick={() => setOpen(false)}>
            Cancel
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddDialog;
