import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { actionResponseMap } from '../../../common/state/types';
import type DraftColumnType from '../../../models/draft-column';
import type { StateType } from '../../../common/state/types';
import { handleError } from '../../alert/alertActions';

const domainSize = process.env.REACT_APP_DRAFTCOLUMNS_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const loadDraftColumns =
  (
    fileId: number,
    branchId: number,
    tableId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<DraftColumnType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/files/${fileId}/branches/${branchId}/tables/${tableId}/columns/review/merge-diff/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.DRAFTCOLUMNMERGEDIFF_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.DRAFTCOLUMNMERGEDIFF_DATA_FAILED,
        payload: null,
      });
    }
  };
