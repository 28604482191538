import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import {
  faTrash,
  faPencil,
  faFolderOpen,
  faShare,
  faSquarePlus,
  faCircleDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  loadFiles,
  addFile,
  updateFileById,
  removeFileById,
  downloadMaster,
} from '../../../state/file/fileActions';
import { RootState } from '../../../store';
import Paginate from '../../../components/Paginate/Paginate';
import { timeSince } from '../../../common/utils';
import type FileType from '../../../models/file';
import AddDialog from './dialog/add/Add';
import UpdateDialog from './dialog/update/Update';
import RemoveDialog from './dialog/remove/Remove';
import './file.css';

const FileCard = ({
  params,
  onSelect,
  onShare,
  selectedRowFilter,
}: {
  params?: { [k: string]: any };
  onSelect?: (selectedData: FileType) => void;
  onShare?: (selectedData: FileType) => void;
  selectedRowFilter?: Partial<{ [k in keyof FileType]: any }>;
}) => {
  const dispatch = useDispatch();
  const baseParams = useMemo(() => params || {}, [params]);
  const [currentPage, setCurrentPage] = useState(0);
  const files = useSelector((state: RootState) => state.file.active.data);
  const filesMeta = useSelector((state: RootState) => state.file.active.meta);
  const [selectedRow, setSelectedRow] = useState<FileType | undefined>(
    undefined
  );
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

  const handlePageClick = async (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    setCurrentPage(currentPageInx);
  };

  const handleOnSelect = (selectedData: FileType) => {
    if (_.isEmpty(selectedData)) return;
    if (!onSelect) return;
    onSelect(selectedData);
  };

  const handleOnShare = (selectedData: FileType) => {
    if (_.isEmpty(selectedData)) return;
    if (!onShare) return;
    onShare(selectedData);
  };

  const handleOnDownload = (selectedData: FileType) => {
    if (_.isEmpty(selectedData)) return;
    dispatch(downloadMaster(Number(selectedData.id)));
  };

  const handleCellSelection = (data: FileType) => {
    setSelectedRow(data);
  };

  const handleAddRecords = (data: FileType) => {
    dispatch(addFile(data));
  };

  const handleUpdateRecords = (data: FileType) => {
    if (selectedRow) {
      const fileId = Number(selectedRow?.id);
      dispatch(updateFileById(fileId, data));
    }
  };

  const handleRemoveRecords = () => {
    if (selectedRow) {
      const fileId = Number(selectedRow?.id);
      dispatch(removeFileById(fileId, selectedRow));
    }
  };

  useEffect(() => {
    dispatch(loadFiles({ ...baseParams, page: currentPage }));
  }, [dispatch, baseParams, currentPage]);

  return (
    <div className="file-card-container">
      <div className="card-toolbar">
        <div className="left-toolbar">
          <button type="button" onClick={() => setOpenAddDialog(true)}>
            <FontAwesomeIcon className="icon" icon={faSquarePlus} />
            <span className="add icon-label">Add</span>
          </button>
        </div>
        <div className="center-toolbar">
          <Paginate
            totalPages={Number(filesMeta?.totalPages)}
            onPageClick={handlePageClick}
            forcePage={currentPage}
          />
        </div>
        <div className="right-toolbar"></div>
      </div>
      <div className="file-data">
        <div className="datacard-container">
          {files.map((fileItem, inx) => {
            return (
              <div
                className="datacard"
                key={inx}
                onClick={() => handleCellSelection(fileItem)}
              >
                <div className="datacard-title">{fileItem.name}</div>
                <div className="datacard-createdat">{`created ${timeSince(
                  fileItem.createdAt
                )}`}</div>
                <div className="datacard-updatedat">{`updated ${timeSince(
                  fileItem.updatedAt
                )}`}</div>
                <div className="action-container">
                  <button
                    className="row-button"
                    onClick={() => setOpenRemoveDialog(true)}
                  >
                    <FontAwesomeIcon className="icon" icon={faTrash} />
                    delete
                  </button>
                  <button
                    className="row-button"
                    onClick={() => setOpenUpdateDialog(true)}
                  >
                    <FontAwesomeIcon className="icon" icon={faPencil} />
                    edit
                  </button>
                  <button
                    className="row-button"
                    onClick={() => handleOnSelect(fileItem)}
                  >
                    <FontAwesomeIcon className="icon" icon={faFolderOpen} />
                    open
                  </button>
                  <button
                    className="row-button"
                    onClick={() => handleOnShare(fileItem)}
                  >
                    <FontAwesomeIcon className="icon" icon={faShare} />
                    share
                  </button>
                  <button
                    className="row-button"
                    onClick={() => handleOnDownload(fileItem)}
                  >
                    <FontAwesomeIcon className="icon" icon={faCircleDown} />
                    download
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {openAddDialog ? (
        <AddDialog
          open={openAddDialog}
          setOpen={setOpenAddDialog}
          onSubmit={handleAddRecords}
        ></AddDialog>
      ) : null}
      {openUpdateDialog ? (
        <UpdateDialog
          open={openUpdateDialog}
          setOpen={setOpenUpdateDialog}
          selectedRow={selectedRow}
          onSubmit={handleUpdateRecords}
        ></UpdateDialog>
      ) : null}
      {openRemoveDialog ? (
        <RemoveDialog
          open={openRemoveDialog}
          setOpen={setOpenRemoveDialog}
          selectedRow={selectedRow}
          onSubmit={handleRemoveRecords}
        ></RemoveDialog>
      ) : null}
    </div>
  );
};

export default FileCard;
