import React from 'react';
import Register from '../../form/Register';
import Dialog from '../../../../../components/Dialog/Dialog';
import './register.css';

const RegisterDialog = ({
  open,
  setOpen,
  signIn,
}: {
  open: boolean;
  setOpen: (data: boolean) => void;
  signIn: (data: boolean) => void;
}) => {
  return (
    <Dialog selector="user-register-dialog" open={open} setOpen={setOpen}>
      <div className="dialog-content">
        <Register
          onCancel={setOpen}
          onSubmit={setOpen}
          signIn={signIn}
        ></Register>
      </div>
    </Dialog>
  );
};

export default RegisterDialog;
