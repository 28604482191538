import React from 'react';
import FileUserType from '../../../../../../models/file-user';
import Dialog from '../../../../../../components/Dialog/Dialog';
import './remove.css';

const RemoveDialog = ({
  open,
  setOpen,
  selectedRow,
  onSubmit,
}: {
  open: boolean;
  setOpen: (data: boolean) => void;
  selectedRow: FileUserType | undefined;
  onSubmit: () => void;
}) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
    setOpen(false);
  };

  return (
    <Dialog selector="user-by-file remove-dialog" open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit} method="dialog">
        <div>{`Are you sure you want to delete user "${selectedRow?.user?.name}"?`}</div>
        <div className="menu-buttons">
          <button type="button" onClick={() => setOpen(false)}>
            Cancel
          </button>
          <button type="submit">Ok</button>
        </div>
      </form>
    </Dialog>
  );
};

export default RemoveDialog;
