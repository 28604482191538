import type UserType from '../../../models/user';
import type { ReducerType, StateType } from '../../../common/state/types';
import { initialState } from '../../../common/state/utils/constant';
import { actionManyMap, actionResponseMap } from '../../../common/state/types';
import {
  dataListLoadedReducer,
  dataListDeletedReducer,
  dataFailedReducer,
} from '../../../common/state/reducers';

const userReducer: ReducerType<UserType> = (
  state = {
    active: initialState as StateType<UserType>,
    rollback: initialState as StateType<UserType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.USERBYGROUPJOIN_DATALIST_LOADED:
      return dataListLoadedReducer<UserType>({
        state,
        action,
      });
    case actionManyMap.USERBYGROUPJOIN_DATALIST_DELETED:
      return dataListDeletedReducer<UserType>({
        state,
        action,
      });
    case actionResponseMap.USERBYGROUPJOIN_DATA_FAILED:
      return dataFailedReducer<UserType>({ state, action });
    default:
      return state;
  }
};

export default userReducer;
