import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../store';
import { loadDefaultBranch } from '../../../../state/branch/branchActions';
import { isNothing } from '../../../../common/utils';
import DraftResource from '../detail/Draft';

const Connect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultBranch = useSelector(
    (state: RootState) => state.branch.active.selected
  );
  const params = useParams();
  const fileId = Number(params.fileId);
  const branchId = Number(params.branchId);
  const tableId = Number(params.tableId);
  const hasBranch = !isNothing(branchId);
  const hasTable = hasBranch && !isNothing(tableId);

  const selectDefaultBranch = useCallback(() => {
    if (hasTable) return;
    if (hasBranch) return;
    if (isNothing(defaultBranch)) return;
    const filePath = `/files/${defaultBranch?.fileId}`;
    const branchPath = `/branches/${defaultBranch?.id}`;
    const redirectPath = `/connects/drafts${filePath}${branchPath}`;
    navigate(redirectPath);
  }, [navigate, defaultBranch, hasTable, hasBranch]);

  useEffect(() => {
    selectDefaultBranch();
  }, [selectDefaultBranch]);

  useEffect(() => {
    if (!fileId) return;
    dispatch(loadDefaultBranch(fileId));
  }, [dispatch, fileId]);

  return <DraftResource></DraftResource>;
};

export default Connect;
