import type FileGroupType from '../../../models/file-group';
import type { ReducerType, StateType } from '../../../common/state/types';
import { initialState } from '../../../common/state/utils/constant';
import {
  actionOneMap,
  actionManyMap,
  actionResponseMap,
} from '../../../common/state/types';
import {
  dataListLoadedReducer,
  dataListAddedReducer,
  dataListUpdatedReducer,
  dataListDeletedReducer,
  dataLoadedReducer,
  dataFailedReducer,
} from '../../../common/state/reducers';

const groupReducer: ReducerType<FileGroupType> = (
  state = {
    active: initialState as StateType<FileGroupType>,
    rollback: initialState as StateType<FileGroupType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.GROUPBYFILE_DATALIST_LOADED:
      return dataListLoadedReducer<FileGroupType>({
        state,
        action,
      });
    case actionOneMap.GROUPBYFILE_DATA_LOADED:
      return dataLoadedReducer<FileGroupType>({ state, action });
    case actionManyMap.GROUPBYFILE_DATALIST_ADDED:
      return dataListAddedReducer<FileGroupType>({
        state,
        action,
      });
    case actionManyMap.GROUPBYFILE_DATALIST_UPDATED:
      return dataListUpdatedReducer<FileGroupType>({
        state,
        action,
      });
    case actionManyMap.GROUPBYFILE_DATALIST_DELETED:
      return dataListDeletedReducer<FileGroupType>({
        state,
        action,
      });
    case actionResponseMap.GROUPBYFILE_DATA_FAILED:
      return dataFailedReducer<FileGroupType>({ state, action });
    default:
      return state;
  }
};

export default groupReducer;
