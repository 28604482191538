import React from 'react';
import { useDispatch } from 'react-redux';
import AddDialog from '../../grid/dialog/add/Add';
import { addCommit } from '../../../../../state/process-log/commit/commitActions';
import ProcessLogType from '../../../../../models/process-log';
import './commit.css';

const CommitDialog = ({
  fileId,
  branchId,
  open,
  setOpen,
}: {
  fileId: number;
  branchId: number;
  open: boolean;
  setOpen: (data: boolean) => void;
}) => {
  const dispatch = useDispatch();

  const handleAddRecords = (data: ProcessLogType) => {
    dispatch(addCommit(fileId, branchId, data));
  };

  return (
    <AddDialog
      open={open}
      setOpen={setOpen}
      onSubmit={handleAddRecords}
    ></AddDialog>
  );
};

export default CommitDialog;
