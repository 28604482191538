import ExcelJS from 'exceljs';
import { isNothing } from '../../../common/utils';
import type { Nullable } from '../../../common/types';
import DraftTableType from '../../../models/draft-table';
import DraftColumnType from '../../../models/draft-column';
import TableType from '../../../models/table';
import ColumnType from '../../../models/column';

export type TableIdentType = {
  tableDraftId?: Nullable<number>;
  tableMasterId: Nullable<number>;
};
export type ColumnIdentType = {
  columnDraftId?: Nullable<number>;
  columnMasterId: Nullable<number>;
};
export type RowIdentType = {
  rowDraftId?: Nullable<number>;
  rowMasterId: Nullable<number>;
};
export type CellCommentType = {
  fileId: number;
  branchId?: number;
  column?: TableIdentType & ColumnIdentType;
  row?: TableIdentType & RowIdentType;
};

export type TableDataType = DraftTableType | TableType;
export type ColumnDataType = DraftColumnType | ColumnType;

const reservedColumnList = ['rowId'];
export const addCellNotes = (commentStr: string): ExcelJS.Comment => ({
  texts: [{ text: commentStr, font: { size: 10 } }],
  margins: {
    insetmode: 'custom',
    inset: [0.25, 0.25, 0.35, 0.35],
  },
  protection: {
    locked: 'True',
    lockText: 'True',
  },
  editAs: 'twoCells',
});

export const addHeaders = (
  worksheet: ExcelJS.Worksheet,
  columnDataList: ColumnDataType[]
) => {
  const columnNameList: string[] = [
    'rowId',
    ...columnDataList.map((columnItem) => String(columnItem.name)),
  ];
  const columnListProps = columnNameList.map((columnName) => {
    const columnProp = {
      header: columnName,
      key: columnName,
      width: 10,
    };
    return columnProp;
  });
  worksheet.columns = columnListProps;
  return columnNameList;
};

export const addHeaderComments = (
  worksheet: ExcelJS.Worksheet,
  tableData: TableDataType,
  columnDataList: ColumnDataType[]
) => {
  worksheet.getRow(1).eachCell((headerCell) => {
    const header = headerCell.value as string;
    if (reservedColumnList.includes(header)) return;
    const columnData = columnDataList.find(
      (columnItem) => columnItem.name === header
    ) as DraftColumnType;
    const headerCommentData: CellCommentType = {
      fileId: Number(tableData.fileId),
      branchId: tableData.branchId,
      column: {
        tableDraftId: tableData.id,
        tableMasterId: tableData.tableId,
        columnDraftId: columnData.id,
        columnMasterId: columnData.columnId,
      },
    };
    const headerCommentStr = JSON.stringify(headerCommentData);
    headerCell.note = addCellNotes(headerCommentStr);
  });
};

export const getCellComments = (
  cell: ExcelJS.Cell
): CellCommentType | undefined => {
  if (!cell?.note) return;
  const cellComments = cell.note as ExcelJS.Comment;
  if (!cellComments.texts) return;
  const cellCommentsStr = cellComments.texts
    .map((item) => item.text)
    .join('')
    .replace('Author:', '')
    .trim();
  try {
    const commentJson = JSON.parse(cellCommentsStr);
    return commentJson;
  } catch (e) {
    console.log('Ignoring unknown comment');
    return;
  }
};

export const getValidatedComments = (
  fileId: number,
  branchId: number,
  cellComments: CellCommentType | undefined
): CellCommentType | undefined => {
  if (!cellComments) return;
  const isSameFile = cellComments.fileId === fileId;
  const isSameBranch = cellComments.branchId === branchId;
  const isSameRecord = isSameFile && isSameBranch;
  if (!isSameRecord) return;
  return cellComments;
};

export const getRowCells = (worksheet: ExcelJS.Worksheet, rowNum: number) => {
  const headerRow = worksheet.getRow(1);
  const row = worksheet.getRow(rowNum);
  const currentRowCellList: ExcelJS.Cell[] = [];
  row.eachCell({ includeEmpty: true }, (cell) => {
    currentRowCellList.push(cell);
  });
  const currentRowCells: { [k: string]: ExcelJS.Cell } = {};
  headerRow.eachCell({ includeEmpty: true }, (headerCell, headerNum) => {
    if (isNothing(headerCell.value)) return;
    currentRowCells[headerCell.value as string] =
      currentRowCellList[headerNum - 1];
  });
  return currentRowCells;
};

export const getRowCellComments = (
  fileId: number,
  branchId: number,
  rowCell: ExcelJS.Cell
) => {
  const cellComments = getCellComments(rowCell);
  const cellValidComments = getValidatedComments(
    fileId,
    branchId,
    cellComments
  );
  return cellValidComments;
};
