import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';
import { actionOneMap } from '../../common/state/types';

export const setAlert =
  (msg: string, type: string, timeout = 5000) =>
  (dispatch: Dispatch) => {
    const id = uuid();
    dispatch({
      type: actionOneMap.ALERT_DATA_ADDED,
      payload: { msg, type, id },
    });
    setTimeout(
      () =>
        dispatch({ type: actionOneMap.ALERT_DATA_DELETED, payload: { id } }),
      timeout
    );
  };

export const handleError = (dispatch: Dispatch, err: any) => {
  const errResponse = err?.response;
  if (errResponse) {
    if ([400, 401, 404].includes(errResponse.status)) {
      const defaultMessage = 'Bad request found.';
      const responseMessage = errResponse?.data?.msg;
      const responseErrors = errResponse?.data?.errors;
      if (responseErrors) {
        responseErrors.forEach((error: any) =>
          dispatch(setAlert(error.msg, 'error'))
        );
      } else {
        const errMessage = responseMessage || defaultMessage;
        dispatch(setAlert(errMessage, 'error'));
      }
    } else {
      const defaultMessage = 'Encountered an error. Please try again.';
      const errMessage = errResponse?.data?.msg || defaultMessage;
      dispatch(setAlert(errMessage, 'info'));
    }
  }
};
