import React, { useState, useEffect } from 'react';
import BranchType from '../../../../../models/branch';
import Dialog from '../../../../../components/Dialog/Dialog';
import './update.css';

const UpdateDialog = ({
  open,
  setOpen,
  selectedRow,
  onSubmit,
}: {
  open: boolean;
  setOpen: (data: boolean) => void;
  selectedRow: BranchType | undefined;
  onSubmit: (data: BranchType) => void;
}) => {
  const [inputValue, setInputValue] = useState(selectedRow?.name);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({ ...selectedRow, name: String(inputValue) });
    setOpen(false);
  };

  useEffect(() => {
    if (selectedRow?.name !== undefined) {
      setInputValue(selectedRow.name);
    }
  }, [selectedRow]);

  return (
    <Dialog selector="branch update-dialog" open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit} method="dialog">
        <div>{`You are updating branch name "${selectedRow?.name}"`}</div>
        <input
          type="text"
          value={inputValue || ''}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
        <div className="menu-buttons">
          <button type="button" onClick={() => setOpen(false)}>
            Cancel
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </Dialog>
  );
};

export default UpdateDialog;
