import type ColumnType from '../../models/column';
import type { ReducerType, StateType } from '../../common/state/types';
import { initialState } from '../../common/state/utils/constant';
import { actionOneMap, actionResponseMap } from '../../common/state/types';
import {
  dataListLoadedReducer,
  dataLoadedReducer,
  dataFailedReducer,
} from '../../common/state/reducers';

const columnReducer: ReducerType<ColumnType> = (
  state = {
    active: initialState as StateType<ColumnType>,
    rollback: initialState as StateType<ColumnType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.COLUMN_DATALIST_LOADED:
      return dataListLoadedReducer<ColumnType>({
        state,
        action,
      });
    case actionOneMap.COLUMN_DATA_LOADED:
      return dataLoadedReducer<ColumnType>({ state, action });
    case actionResponseMap.COLUMN_DATA_FAILED:
      return dataFailedReducer<ColumnType>({ state, action });
    default:
      return state;
  }
};

export default columnReducer;
