import React from 'react';
import File from '../..//grid/File';
import Dialog from '../../../../components/Dialog/Dialog';
import FileType from '../../../../models/file';
import './file.css';

const FileDialog = ({
  open,
  setOpen,
  onSubmit,
  selectedRowFilter,
  onSelect,
}: {
  open: boolean;
  setOpen: (data: boolean) => void;
  onSubmit?: () => void;
  selectedRowFilter?: Partial<{ [k in keyof FileType]: any }>;
  onSelect?: (selectedData: FileType) => void;
}) => {
  return (
    <Dialog selector="file-dialog" open={open} setOpen={setOpen}>
      <div className="dialog-content">
        <File onSelect={onSelect} selectedRowFilter={selectedRowFilter}></File>
      </div>
      <div className="menu-buttons">
        <button type="button" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
    </Dialog>
  );
};

export default FileDialog;
