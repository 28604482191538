import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import User from './pages/user/User';
import Home from './pages/home/Home';
import Draft from './pages/draft/Draft';
import Master from './pages/master/Master';
import ReviewDetail from './pages/review/detail/Review';
import ReviewHome from './pages/review/home/Review';
import ConnectMasterHome from './pages/connect/master/home/Master';
import ConnectMasterDetail from './pages/connect/master/detail/Master';
import ConnectDraftHome from './pages/connect/draft/home/Draft';
import ConnectDraft from './pages/connect/draft/detail/Draft';
import { loadPublicGroup, loadLoginGroup } from './state/group/groupActions';
import {
  loadGuestUser,
  loadLoginUser,
  loadLoginContext,
} from './state/user/userActions';
import store from './store';

const App = () => {
  store.dispatch(loadPublicGroup());
  store.dispatch(loadGuestUser());
  if (localStorage.token) {
    store.dispatch(loadLoginUser(localStorage.token));
  }
  if (localStorage.group) {
    store.dispatch(loadLoginGroup(localStorage.group));
  }
  if (localStorage.context) {
    store.dispatch(loadLoginContext(localStorage.context));
  }
  useEffect(() => {
    document.title = 'filexbox';
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Home></Home>}></Route>
          <Route path="/users" element={<User></User>}></Route>
          <Route
            path="/drafts/files/:fileId/branches/:branchId/tables/:tableId"
            element={<Draft></Draft>}
          ></Route>
          <Route
            path="/drafts/files/:fileId/branches/:branchId"
            element={<Draft></Draft>}
          ></Route>
          <Route path="/drafts/files/:fileId" element={<Draft></Draft>}></Route>
          <Route path="/drafts" element={<Draft></Draft>}></Route>
          <Route
            path="/reviews/files/:fileId/branches/:branchId/submits/:submitId/tables/:tableId"
            element={<ReviewDetail></ReviewDetail>}
          ></Route>
          <Route
            path="/reviews/files/:fileId/branches/:branchId/submits/:submitId"
            element={<ReviewDetail></ReviewDetail>}
          ></Route>
          <Route
            path="/reviews/files/:fileId/branches/:branchId"
            element={<ReviewDetail></ReviewDetail>}
          ></Route>
          <Route
            path="/reviews/files/:fileId"
            element={<ReviewDetail></ReviewDetail>}
          ></Route>
          <Route path="/reviews" element={<ReviewHome></ReviewHome>}></Route>
          <Route
            path="/masters/files/:fileId/branches/:branchId/tables/:tableId"
            element={<Master></Master>}
          ></Route>
          <Route
            path="/masters/files/:fileId/branches/:branchId"
            element={<Master></Master>}
          ></Route>
          <Route
            path="/masters/files/:fileId"
            element={<Master></Master>}
          ></Route>
          <Route path="/masters" element={<Master></Master>}></Route>
          <Route
            path="/connects/masters/files/:fileId/branches/:branchId/tables/:tableId"
            element={<ConnectMasterDetail></ConnectMasterDetail>}
          ></Route>
          <Route
            path="/connects/masters/files/:fileId/branches/:branchId"
            element={<ConnectMasterDetail></ConnectMasterDetail>}
          ></Route>
          <Route
            path="/connects/masters/files/:fileId"
            element={<ConnectMasterHome></ConnectMasterHome>}
          ></Route>
          <Route
            path="/connects/masters"
            element={<ConnectMasterHome></ConnectMasterHome>}
          ></Route>
          <Route
            path="/connects/drafts/files/:fileId/branches/:branchId/tables/:tableId"
            element={<ConnectDraft></ConnectDraft>}
          ></Route>
          <Route
            path="/connects/drafts/files/:fileId/branches/:branchId"
            element={<ConnectDraft></ConnectDraft>}
          ></Route>
          <Route
            path="/connects/drafts/files/:fileId"
            element={<ConnectDraft></ConnectDraft>}
          ></Route>
          <Route
            path="/connects/drafts"
            element={<ConnectDraftHome></ConnectDraftHome>}
          ></Route>
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
