import type { AlertType } from '../../state/alert/alertTypes';
import type { ReducerType, StateType } from '../../common/state/types';
import { actionOneMap, actionResponseMap } from '../../common/state/types';
import { initialState } from '../../common/state/utils/constant';
import {
  dataAddedReducer,
  dataDeletedReducer,
  dataFailedReducer,
} from '../../common/state/reducers';

const alertReducer: ReducerType<AlertType> = (
  state = {
    active: initialState as StateType<AlertType>,
    rollback: initialState as StateType<AlertType>,
  },
  action
) => {
  switch (action.type) {
    case actionOneMap.ALERT_DATA_ADDED:
      return dataAddedReducer<AlertType>({ state, action });
    case actionOneMap.ALERT_DATA_DELETED:
      return dataDeletedReducer<AlertType>({ state, action });
    case actionResponseMap.ALERT_DATA_FAILED:
      return dataFailedReducer<AlertType>({ state, action });
    default:
      return state;
  }
};

export default alertReducer;
