import React from 'react';
import './diffText.css';

const DiffText = ({ keyValue }: { keyValue: any }) => {
  const beforeValue = keyValue?.before || 'empty';
  const afterValue = keyValue?.after || 'empty';
  switch (keyValue?.action) {
    case 'deleted-both':
      return (
        <span>
          <i>
            <del className="old-value">{beforeValue}</del>{' '}
            <del className="new-value">{afterValue}</del>
          </i>
        </span>
      );
    case 'deleted-before':
      return (
        <span>
          <i>
            <del className="old-value">{beforeValue}</del>{' '}
            <span className="new-value">{afterValue}</span>
          </i>
        </span>
      );
    case 'deleted-after':
      return (
        <span>
          <i>
            <span className="old-value">{beforeValue}</span>{' '}
            <del className="new-value">{afterValue}</del>
          </i>
        </span>
      );
    case 'deleted-column':
      return (
        <span>
          <i>
            <del className="old-value">{beforeValue}</del>
          </i>
        </span>
      );
    case 'added-column':
      return (
        <span>
          <i>
            <span className="new-value">{afterValue}</span>
          </i>
        </span>
      );
    case 'updated':
      return (
        <span>
          <i>
            <span className="old-value">{beforeValue}</span>{' '}
            <span className="new-value">{afterValue}</span>
          </i>
        </span>
      );
    default:
      return <span>{keyValue}</span>;
  }
};

export default DiffText;
