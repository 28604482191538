import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from '../../../../components/Sidebar/Sidebar';
import Alert from '../../../../components/Alert/Alert';
import { isNothing } from '../../../../common/utils';
import Header from '../../header/Header';
import { RootState } from '../../../../store';
import {
  loadFirstTable,
  loadTableById,
} from '../../../../state/table/tableActions';
import {
  loadBranchById,
  loadFirstBranch,
} from '../../../../state/branch/branchActions';
import TableList from '../../../../domain/table/list/Table';
import ColumnDialog from '../../../../domain/column/dialog/grid/Column';
import MasterRowResource from '../../resources/row/Row';
import MasterTableResource from '../../resources/table/Table';
import MasterColumnResource from '../../resources/column/Column';
import FileResource from '../../resources/file/File';
import type TableType from '../../../../models/table';
import './master.css';

const MasterConnect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState('master-row');
  const [openColumns, setOpenColumns] = useState(false);
  const currentFile = useSelector(
    (state: RootState) => state.file.active.selected
  );
  const currentBranch = useSelector(
    (state: RootState) => state.branch.active.selected
  );
  const currentTable = useSelector(
    (state: RootState) => state.table.active.selected
  );

  const params = useParams();
  const fileId = Number(params.fileId);
  const branchId = Number(params.branchId);
  const tableId = Number(params.tableId);

  const hasFile = fileId && !_.isEmpty(currentFile);
  const hasBranch =
    hasFile && !isNothing(branchId) && !_.isEmpty(currentBranch);
  const hasTable = hasBranch && !isNothing(tableId) && !_.isEmpty(currentTable);

  const firstBranch = useSelector(
    (state: RootState) => state.branch.active.first
  );
  const firstTable = useSelector(
    (state: RootState) => state.table.active.first
  );

  const selectDefaultBranch = useCallback(() => {
    if (!hasFile) return;
    if (hasBranch) return;
    if (!firstBranch) return;
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${firstBranch?.id}`;
    const redirectPath = `/connects/masters${filePath}${branchPath}`;
    navigate(redirectPath);
  }, [fileId, firstBranch, hasFile, hasBranch, navigate]);

  const selectDefaultTable = useCallback(() => {
    if (!hasFile) return;
    if (!hasBranch) return;
    if (tableId) return;
    if (!firstTable) return;
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const tablePath = `/tables/${firstTable.tableId}`;
    const redirectPath = `/connects/masters${filePath}${branchPath}${tablePath}`;
    navigate(redirectPath);
  }, [hasFile, hasBranch, fileId, branchId, firstTable, navigate, tableId]);

  const handleSelectTable = (currentTable: TableType) => {
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const tablePath = `/tables/${currentTable.tableId}`;
    const redirectPath = `/connects/masters${filePath}${branchPath}${tablePath}`;
    navigate(redirectPath);
  };

  useEffect(() => {
    selectDefaultBranch();
  }, [selectDefaultBranch]);

  useEffect(() => {
    selectDefaultTable();
  }, [selectDefaultTable]);

  useEffect(() => {
    if (!fileId) return;
    dispatch(loadFirstTable(fileId));
  }, [dispatch, fileId]);

  useEffect(() => {
    if (!fileId) return;
    if (!tableId) return;
    dispatch(loadTableById(fileId, tableId));
  }, [dispatch, fileId, tableId]);

  useEffect(() => {
    if (!fileId) return;
    dispatch(loadFirstBranch(fileId, { where: { name: 'master' } }));
  }, [dispatch, fileId]);

  useEffect(() => {
    if (!fileId) return;
    if (!branchId) return;
    dispatch(loadBranchById(fileId, branchId));
  }, [dispatch, fileId, branchId]);

  return (
    <div className="master-connect-container">
      <Alert></Alert>
      <div className="content-container">
        <div className={`sidebar${sidebarOpen ? '' : ' close'}`}>
          <Sidebar
            open={sidebarOpen}
            onToggle={(open) => setSidebarOpen(open)}
          ></Sidebar>
        </div>
        <div className={`content${sidebarOpen ? '' : ' open'}`}>
          <div className="connect-header">
            <Header fileId={fileId} branchId={branchId}></Header>
          </div>
          <div className="connect-data">
            <div className="resource-tab">
              <div className="resource-label-container">
                <FontAwesomeIcon className="icon" icon={faTable} />
                <label className="resource-label" htmlFor="resource-buttons">
                  Resource:
                </label>
              </div>
              <div className="resource-buttons">
                <button
                  disabled={!hasFile}
                  className={`resource params text ${
                    selectedResource === 'file' ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedResource('file')}
                >
                  File
                </button>
                <button
                  disabled={!hasTable}
                  className={`resource params text ${
                    selectedResource === 'master-table' ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedResource('master-table')}
                >
                  Table
                </button>
                <button
                  disabled={!hasTable}
                  className={`resource params text ${
                    selectedResource === 'master-column' ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedResource('master-column')}
                >
                  Column
                </button>
                <button
                  disabled={!hasTable}
                  className={`resource params text ${
                    selectedResource === 'master-row' ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedResource('master-row')}
                >
                  Row
                </button>
              </div>
            </div>
            <div className="resource-data">
              {hasFile && selectedResource === 'file' ? (
                <FileResource fileId={fileId}></FileResource>
              ) : null}
              {hasFile && hasBranch && selectedResource === 'master-table' ? (
                <MasterTableResource
                  fileId={fileId}
                  branchId={branchId}
                ></MasterTableResource>
              ) : null}
              {hasFile &&
              hasBranch &&
              hasTable &&
              selectedResource === 'master-column' ? (
                <MasterColumnResource
                  fileId={fileId}
                  branchId={branchId}
                  tableId={tableId}
                ></MasterColumnResource>
              ) : null}
              {hasFile &&
              hasBranch &&
              hasTable &&
              selectedResource === 'master-row' ? (
                <MasterRowResource
                  fileId={fileId}
                  branchId={branchId}
                  tableId={tableId}
                ></MasterRowResource>
              ) : null}
            </div>
          </div>
          <div className="connect-footer">
            {hasFile && hasBranch ? (
              <div className="connect-footer-container">
                <div className="footer-content">
                  {hasTable ? (
                    <TableList
                      fileId={fileId}
                      branchId={branchId}
                      selectedRowFilter={{ tableId }}
                      onSelectOption={setOpenColumns}
                      onSelectTable={handleSelectTable}
                    ></TableList>
                  ) : null}
                </div>
                {hasTable ? (
                  <ColumnDialog
                    fileId={fileId}
                    branchId={branchId}
                    tableId={tableId}
                    open={openColumns}
                    setOpen={setOpenColumns}
                  ></ColumnDialog>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterConnect;
