import { actionTypeList } from './constant';

export const createActionMap = <
  T extends readonly string[],
  K extends T[number]
>(
  list: T
): Record<K, K> => {
  return list.reduce((acc, item) => {
    acc[item as K] = item as K;
    return acc;
  }, {} as Record<K, K>);
};

export const createActionList = <ActionType>(
  targetType: keyof typeof actionTypeList
): ActionType[] => {
  return actionTypeList.domain.reduce((result, domain) => {
    const domainActionList = actionTypeList[targetType].map((action) => {
      return `${domain}_${action}` as ActionType;
    });
    result.push(...domainActionList);
    return result;
  }, [] as ActionType[]);
};
