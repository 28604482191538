// https://github.com/reduxjs/redux-thunk/issues/333
import type {} from 'redux-thunk/extend-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import branch from './state/branch/branchReducers';
import group from './state/group/groupReducers';
import groupByFile from './state/file-group/group/groupReducers';
import groupByFileShare from './state/file-group/group-share/groupReducers';
import groupByUser from './state/user-group/group/groupReducers';
import groupByUserJoin from './state/user-group/group-join/groupReducers';
import user from './state/user/userReducers';
import userByFile from './state/file-user/user/userReducers';
import userByFileShare from './state/file-user/user-share/userReducers';
import userByGroup from './state/user-group/user/userReducers';
import userByGroupJoin from './state/user-group/user-join/userReducers';
import file from './state/file/fileReducers';
import permission from './state/permission/permissionReducers';
import column from './state/column/columnReducers';
import table from './state/table/tableReducers';
import row from './state/row/rowReducers';
import draftColumn from './state/draft-column/draftColumnReducers';
import draftRow from './state/draft-row/draftRowReducers';
import draftTable from './state/draft-table/draftTableReducers';
import draftColumnMergeDiff from './state/draft-column/merge-diff/draftColumnReducers';
import draftRowMergeDiff from './state/draft-row/merge-diff/draftRowReducers';
import draftTableMergeDiff from './state/draft-table/merge-diff/draftTableReducers';
import commit from './state/process-log/commit/commitReducers';
import rebase from './state/process-log/rebase/rebaseReducers';
import submit from './state/process-log/submit/submitReducers';
import merge from './state/process-log/merge/mergeReducers';
import alert from './state/alert/alertReducers';

const rootReducer = combineReducers({
  alert,
  branch,
  group,
  groupByFile,
  groupByFileShare,
  groupByUser,
  groupByUserJoin,
  user,
  userByFile,
  userByFileShare,
  userByGroup,
  userByGroupJoin,
  file,
  column,
  table,
  row,
  draftColumn,
  draftRow,
  draftTable,
  draftColumnMergeDiff,
  draftRowMergeDiff,
  draftTableMergeDiff,
  commit,
  rebase,
  submit,
  merge,
  permission,
});

const initialState = {};
const middleware = [thunk];
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(...middleware))
    : compose(applyMiddleware(...middleware));
const store = createStore(rootReducer, initialState, composeEnhancers);

export type RootState = ReturnType<typeof store.getState>;
export default store;
