import _ from 'lodash';
import { useState, ReactNode, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  faFile,
  faCodeBranch,
  faCaretDown,
  faUserNinja,
  faUsers,
  faUserLock,
  faKey,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BranchDialog from '../../../domain/branch/dialog/grid/Branch';
import FileDialog from '../../../domain/file/dialog/grid/File';
import GroupByUserDialog from '../../../domain/user-group/group/dialog/grid/Group';
import LoginDialog from '../../../domain/user/login/dialog/form/Login';
import RegisterDialog from '../../../domain/user/register/dialog/form/Register';
import { RootState } from '../../../store';
import { loadFileById } from '../../../state/file/fileActions';
import { loadBranchById } from '../../../state/branch/branchActions';
import { loadLoginContext } from '../../../state/user/userActions';
import BranchType from '../../../models/branch';
import FileType from '../../../models/file';
import './header.css';

const Header = ({
  fileId,
  branchId,
  onSelect,
}: {
  fileId?: number;
  branchId?: number;
  onSelect?: ({ mergeReview }: { mergeReview: boolean }) => void;
}) => {
  const navigate = useNavigate();
  const orderDesc = useRef({ order: [['id', 'desc']] }).current;
  const loginUser = useSelector(
    (state: RootState) => state.user.active.custom?.loginUser
  );
  const loginGroup = useSelector(
    (state: RootState) => state.group.active.custom?.loginGroup
  );
  const guestUser = useSelector(
    (state: RootState) => state.user.active.custom?.guestUser
  );
  const publicGroup = useSelector(
    (state: RootState) => state.group.active.custom?.publicGroup
  );
  const currentFile = useSelector(
    (state: RootState) => state.file.active.selected
  );
  const currentBranch = useSelector(
    (state: RootState) => state.branch.active.selected
  );
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('branch');
  const [openGroupByUser, setOpenGroupByUser] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const handleSelection = (selectedTable: string) => {
    setSelected(selectedTable);
    const openDialog: { [k: string]: (a: boolean) => void } = {
      file: setOpenFiles,
      branch: setOpenBranches,
    };
    openDialog[selectedTable](true);
  };

  const handleBranchSelect = (currentBranch: BranchType) => {
    const isMaster = currentBranch?.name === 'master';
    const driver = isMaster ? 'masters' : 'drafts';
    const pagePath = `connects/${driver}`;
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${currentBranch.id}`;
    const redirectPath = `/${pagePath}${filePath}${branchPath}`;
    setOpenBranches(false);
    navigate(redirectPath);
  };

  const handleGroupSelect = () => {
    const isMaster = currentBranch?.name === 'master';
    const driver = isMaster ? 'masters' : 'drafts';
    const pagePath = `connects/${driver}`;
    const redirectPath = `/${pagePath}`;
    navigate(redirectPath);
    hasLoginUser && setOpenGroupByUser(true);
  };

  const handleFileSelect = (currentFile: FileType) => {
    const isMaster = currentBranch?.name === 'master';
    const driver = isMaster ? 'masters' : 'drafts';
    const pagePath = `connects/${driver}`;
    const filePath = `/files/${currentFile.id}`;
    const redirectPath = `/${pagePath}${filePath}`;
    setOpenFiles(false);
    navigate(redirectPath);
  };

  const handleTabSelection = (selectedButton: string) => {
    if (selectedButton === 'user') {
      dispatch(loadLoginContext('user'));
    }
    if (selectedButton === 'group') {
      dispatch(loadLoginContext('group'));
    }
    setSelected(selectedButton);
  };

  const handleSignIn = () => {
    setOpenRegister(false);
    setOpenLogin(true);
  };

  const handleSignUp = () => {
    setOpenLogin(false);
    setOpenRegister(true);
  };

  const [openBranches, setOpenBranches] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);

  useEffect(() => {
    if (fileId) dispatch(loadFileById(fileId));
    if (fileId && branchId) dispatch(loadBranchById(fileId, branchId));
  }, [dispatch, fileId, branchId]);

  const hasLoginUser = !_.isEmpty(loginUser);
  const hasLoginGroup = hasLoginUser && !_.isEmpty(loginGroup);
  const hasFile = fileId && !_.isEmpty(currentFile);
  const hasBranch = hasFile && branchId && !_.isEmpty(currentBranch);

  const showButton: { [k: string]: (a?: boolean) => ReactNode } = {
    file: (enable = true) => (
      <button
        className={`file button${openFiles ? ' selected' : ''}`}
        onClick={() => handleSelection('file')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className={`file-icon`}
          icon={faFile}
        ></FontAwesomeIcon>
        <span className="file-name icon-label">
          {hasFile ? currentFile.name : `select file`}
        </span>
        <FontAwesomeIcon
          className="file-icon"
          icon={faCaretDown}
        ></FontAwesomeIcon>
      </button>
    ),
    branch: (enable = true) => (
      <button
        className={`branch button${openBranches ? ' selected' : ''}`}
        onClick={() => handleSelection('branch')}
        disabled={!enable}
      >
        <FontAwesomeIcon
          className={`branch-icon`}
          icon={faCodeBranch}
        ></FontAwesomeIcon>
        <span className="branch-name icon-label">
          {hasBranch ? currentBranch.name : `select branch`}
        </span>
        <FontAwesomeIcon
          className="branch-icon"
          icon={faCaretDown}
        ></FontAwesomeIcon>
      </button>
    ),
    user: (enable = true) => (
      <button
        className={`shared-user button${
          selected === 'user' ? ' selected' : ''
        }`}
        onClick={() => handleTabSelection('user')}
        disabled={!hasLoginUser}
      >
        <FontAwesomeIcon
          className="icon"
          icon={hasLoginUser ? faUserLock : faUserNinja}
        ></FontAwesomeIcon>
        <span className="icon-label">
          {hasLoginUser ? loginUser.name : guestUser?.name}
        </span>
      </button>
    ),
    group: (enable = true) => (
      <button
        className={`shared-group button${
          selected === 'group' ? ' selected' : ''
        }`}
        onClick={() => handleTabSelection('group')}
      >
        <FontAwesomeIcon className="icon" icon={faUsers}></FontAwesomeIcon>
        <span className="icon-label">
          {hasLoginGroup ? loginGroup.name : publicGroup?.name}
        </span>
        <FontAwesomeIcon
          className="icon caret group-by-user"
          icon={faCaretDown}
          onClick={() => handleGroupSelect()}
        ></FontAwesomeIcon>
      </button>
    ),
    loginUser: (enable = true) => (
      <button
        className={`login-user button${openLogin ? ' selected' : ''}`}
        onClick={() => setOpenLogin(true)}
        disabled={hasLoginUser}
      >
        <FontAwesomeIcon
          className="icon"
          icon={hasLoginUser ? faUserLock : faKey}
        ></FontAwesomeIcon>
        <span className="icon-label">
          {hasLoginUser ? loginUser.name : 'sign in'}
        </span>
      </button>
    ),
  };

  return (
    <div className="connect-header-container">
      <FileDialog
        open={openFiles}
        setOpen={setOpenFiles}
        selectedRowFilter={{ id: fileId }}
        onSelect={handleFileSelect}
      ></FileDialog>
      <div className="header-left">
        {showButton.user()} {showButton.group()}
        {showButton.file()}
        {hasFile ? showButton.branch() : null}
      </div>
      <div className="header-center"></div>
      <div className="header-right">{showButton.loginUser()}</div>
      <RegisterDialog
        open={openRegister}
        setOpen={setOpenRegister}
        signIn={handleSignIn}
      ></RegisterDialog>
      <LoginDialog
        signUp={handleSignUp}
        open={openLogin}
        setOpen={setOpenLogin}
      ></LoginDialog>
      {hasFile && openBranches ? (
        <BranchDialog
          fileId={fileId}
          open={openBranches}
          setOpen={setOpenBranches}
          selectedRowFilter={{ id: branchId }}
          params={orderDesc}
          onSelect={handleBranchSelect}
        ></BranchDialog>
      ) : null}
      {openGroupByUser ? (
        <GroupByUserDialog
          userId={loginUser ? Number(loginUser?.id) : Number(guestUser?.id)}
          open={openGroupByUser}
          setOpen={setOpenGroupByUser}
        ></GroupByUserDialog>
      ) : null}
    </div>
  );
};
export default Header;
