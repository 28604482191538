import _ from 'lodash';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import UserByGroup from '../../domain/user-group/user/grid/User';
import Sidebar from '../../components/Sidebar/Sidebar';
import Alert from '../../components/Alert/Alert';
import Header from './header/Header';
import { isNothing } from '../../common/utils';
import './user.css';

const User = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [searchParams, setSearchParams] = useState({});
  const loginGroup = useSelector(
    (state: RootState) => state.group.active.custom?.loginGroup
  );
  const publicGroup = useSelector(
    (state: RootState) => state.group.active.custom?.publicGroup
  );
  const debouncedOnSearch = useRef(
    _.debounce((filterParams) => {
      setSearchParams(filterParams);
    }, 1000)
  ).current;
  const handleOnSearch = (value: any) => {
    if (isNothing(value)) {
      debouncedOnSearch({});
    } else {
      debouncedOnSearch({ where: { 'user.name': { substring: value } } });
    }
  };
  return (
    <div className="user-container">
      <Alert></Alert>
      <div className="content-container">
        <div className={`sidebar${sidebarOpen ? '' : ' close'}`}>
          <Sidebar
            open={sidebarOpen}
            onToggle={(open) => setSidebarOpen(open)}
          ></Sidebar>
        </div>
        <div className={`content${sidebarOpen ? '' : ' open'}`}>
          <Header onSearch={handleOnSearch}></Header>
          <div className="user-data">
            {loginGroup || publicGroup ? (
              <UserByGroup
                groupId={
                  loginGroup ? Number(loginGroup?.id) : Number(publicGroup?.id)
                }
                params={searchParams}
              ></UserByGroup>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
