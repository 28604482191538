import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { actionManyMap, actionResponseMap } from '../../../common/state/types';
import type GroupType from '../../../models/group';
import type FileGroupType from '../../../models/file-group';
import type { StateType } from '../../../common/state/types';
import { handleError } from '../../alert/alertActions';

const domainSize = process.env.REACT_APP_GROUPS_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const loadFileGroups =
  (
    fileId: number,
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<FileGroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      where: { fileId, ...params.where },
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/file-groups/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.GROUPBYFILE_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUPBYFILE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const addGroups =
  (
    fileId: number,
    payload: GroupType[]
  ): ThunkAction<void, StateType<FileGroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payload });
    const queryPath = `/api/file-groups/files/${fileId}/share`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch({
        type: actionManyMap.GROUPBYFILE_DATALIST_ADDED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUPBYFILE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const updateFileGroups =
  (
    fileId: number,
    payload: FileGroupType[]
  ): ThunkAction<void, StateType<FileGroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const normPayload = payload.map((item) => ({ ...item, fileId }));
    const body = JSON.stringify({ data: normPayload });
    const queryPath = `/api/file-groups/`;
    try {
      const res = await axios.put(queryPath, body, config);
      dispatch({
        type: actionManyMap.GROUPBYFILE_DATALIST_UPDATED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUPBYFILE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const removeFileGroups =
  (
    fileId: number,
    payload: FileGroupType[]
  ): ThunkAction<void, StateType<FileGroupType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const normPayload = payload.map((item) => ({ ...item, fileId }));
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
      data: JSON.stringify({ data: normPayload }),
    };
    const queryPath = `/api/file-groups/`;
    try {
      dispatch({
        type: actionManyMap.GROUPBYFILE_DATALIST_DELETED,
        payload: payload,
        path: { url: queryPath, params: {} },
      });
      await axios.delete(queryPath, config);
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.GROUPBYFILE_DATA_FAILED,
        payload: null,
      });
    }
  };
