const commitWorker = () => {
  /* eslint-disable-next-line no-restricted-globals */
  self.addEventListener(
    'connect',
    (connectEvent: any) => {
      const workerListener = async (messageEvent: MessageEvent) => {
        console.log(messageEvent);
        if (!messageEvent) return;

        const messageEventData = messageEvent.data;
        const headers = messageEventData?.headers;
        const payload = messageEventData?.payload;

        /* eslint-disable-next-line no-restricted-globals */
        const host = self.location?.origin;

        const filePath = `/api/files/${payload?.fileId}`;
        const branchPath = `/branches/${payload?.branchId}`;
        const rebasePath = `/rebases/${payload?.id}`;
        const url = `${host}${filePath}${branchPath}${rebasePath}`;

        const delay = (timeout: number) =>
          new Promise<void>((resolve) => {
            setTimeout(resolve, timeout);
          });

        const startWorker = async () => {
          const res = await fetch(url, { headers });
          const result = await res.json();
          const isDone = result?.status !== 'pending';
          if (isDone) {
            port.postMessage(result);
          }
          return isDone;
        };

        let retryCount = 0;
        let keepGoing = true;
        const maxDelay = 400000;

        while (keepGoing) {
          const isDone = await startWorker();
          if (isDone) {
            keepGoing = false;
          }

          retryCount += 1;
          const calculatedTime = Math.pow(2, retryCount) * 100;
          const timeout =
            calculatedTime >= maxDelay ? maxDelay : calculatedTime;

          await delay(timeout);
        }
      };
      const port = (connectEvent.ports as MessagePort[])[0];
      port.addEventListener('message', workerListener, false);
      port.start();
    },
    false
  );
};

export default commitWorker;
