import _ from 'lodash';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faFilter, faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DataGrid,
  SelectColumn,
  EmptyDataGrid,
  HeaderRenderer,
  FilterContext,
  FilterFlagContext,
  pager,
} from '../../../../components/DataGrid/DataGrid';
import { isNothing } from '../../../../common/utils';
import { GenericObjType, FilterType } from '../../../../common/types';
import {
  loadUsers,
  removeUserGroups,
} from '../../../../state/user-group/user-join/userActions';
import { loadGroupById } from '../../../../state/group/groupActions';
import { RootState } from '../../../../store';
import type UserType from '../../../../models/user';
import Paginate from '../../../../components/Paginate/Paginate';
import './user.css';

const User = ({
  groupId,
  params,
  onSelect,
  onFilter = () => {},
  searchParams = {},
}: {
  groupId: number;
  params?: { [k: string]: any };
  onSelect: (data: UserType[]) => void;
  searchParams?: GenericObjType;
  onFilter?: (a: GenericObjType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const baseSearchParams = useMemo(() => searchParams || {}, [searchParams]);
  const dispatch = useDispatch();
  const users = useSelector(
    (state: RootState) => state.userByGroupJoin.active.data
  );
  const usersMeta = useSelector(
    (state: RootState) => state.userByGroupJoin.active.meta
  );
  const usersMetaList = useSelector(
    (state: RootState) => state.userByGroupJoin.active.metaList
  );
  const [selectedRows, setSelectedRows] = useState((): UserType[] => []);
  const [forcePage, setForcePage] = useState(0);
  const [selectedPageNum, setSelectedPageNum] = useState(0);
  const [selectedPageRowNum, setSelectedPageRowNum] = useState(0);

  const columns = [
    SelectColumn,
    {
      key: 'rowNum',
      name: 'Row',
      minWidth: 50,
      maxWidth: 50,
    },
    {
      key: 'name',
      name: 'Name',
      width: 300,
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'name'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
    },
  ];

  const [filterFlag, setFilterFlag] = useState(!isNothing(searchParams));
  const [filters, setFilters] = useState(baseSearchParams);

  const handleShareRecords = () => {
    dispatch(removeUserGroups(groupId, selectedRows));
    onSelect(selectedRows);
  };

  const debouncedOnFilter = useRef(
    _.debounce((currentFilters) => {
      dispatch(
        loadUsers(groupId, {
          ...baseParams,
          where: { ...currentFilters, ...baseParams.where },
        })
      );
    }, 1000)
  ).current;

  const handleFilter = (currentFilters: FilterType<UserType>) => {
    setFilters(currentFilters);
    debouncedOnFilter(currentFilters);
    onFilter(currentFilters);
  };

  const handleToggleFilter = (isEnabled: boolean) => {
    const currentFilters = isEnabled ? filters : {};
    setFilterFlag(isEnabled);
    handleFilter(currentFilters);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    const currentPageNum = currentPageInx + 1;
    const pageStartRowNum = pager.detectPageStartRowNum(
      currentPageNum,
      usersMetaList
    );
    setSelectedPageNum(currentPageNum);
    setSelectedPageRowNum(pageStartRowNum);
  };

  const loadPage = useCallback(
    (pageInx: number) => {
      const currentPage = pageInx + 1;
      const pageMeta = _.find(usersMetaList, { currentPage });
      if (!_.isEmpty(pageMeta)) return pageMeta;
      dispatch(
        loadUsers(groupId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          page: currentPage,
        })
      );
      return pageMeta;
    },
    [dispatch, baseParams, usersMetaList, filters, groupId]
  );

  const handleRowSelection = (selectedData: UserType[]) => {
    setSelectedRows(selectedData);
  };

  useEffect(() => {
    dispatch(loadGroupById(groupId));
    dispatch(
      loadUsers(groupId, {
        ...baseParams,
        where: { ...filters, ...baseParams.where },
      })
    );
  }, [dispatch, baseParams, filters, groupId]);

  const isEmptyGrid = columns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="user-container">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="user-container">
      <FilterFlagContext.Provider value={filterFlag}>
        <FilterContext.Provider value={filters}>
          <div className="grid-toolbar">
            <div className="left-toolbar">
              <button type="button" onClick={() => handleShareRecords()}>
                <FontAwesomeIcon icon={faShareFromSquare} />
                <span className="join icon-label">Join</span>
              </button>
            </div>
            <div className="center-toolbar">
              <Paginate
                totalPages={Number(usersMeta?.totalPages)}
                onPageClick={handlePageClick}
                forcePage={forcePage}
              />
            </div>
            <div className="right-toolbar">
              <button
                className={`filterable ${filterFlag ? 'selected' : ''}`}
                onClick={() => handleToggleFilter(!filterFlag)}
              >
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </div>
          </div>
          <DataGrid<UserType>
            rowKeyGetter={(row) => String(row.id)}
            columns={columns}
            rows={users}
            rowsMetaList={usersMetaList}
            rowHeight={30}
            onPageChange={setForcePage}
            loadPage={loadPage}
            selectedPageNum={selectedPageNum}
            selectedPageRowNum={selectedPageRowNum}
            onRowSelection={handleRowSelection}
          ></DataGrid>
        </FilterContext.Provider>
      </FilterFlagContext.Provider>
    </div>
  );
};

export default User;
