import React, { useState } from 'react';
import ProcessLogType from '../../../../../../models/process-log';
import Dialog from '../../../../../../components/Dialog/Dialog';
import './add.css';

const AddDialog = ({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: (data: boolean) => void;
  onSubmit: (data: ProcessLogType) => void;
}) => {
  const defaultValue = { message: '' };
  const [inputValue, setInputValue] = useState(defaultValue);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(inputValue);
    setOpen(false);
  };

  return (
    <Dialog selector="rebase add-dialog" open={open} setOpen={setOpen}>
      <form onSubmit={handleSubmit} method="dialog">
        <div>You are about to rebase the changes.</div>
        <textarea
          name="name"
          value={inputValue.message}
          placeholder="your message (max 255 characters)"
          onChange={(e) => {
            setInputValue({ ...inputValue, message: e.target.value });
          }}
          onInput={(e) => {
            const target = e.target as HTMLTextAreaElement;
            if (target.value.length > 255) {
              target.value = target.value.slice(0, 255);
            }
          }}
        />
        <div className="menu-buttons">
          <button type="button" onClick={() => setOpen(false)}>
            Cancel
          </button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </Dialog>
  );
};

export default AddDialog;
