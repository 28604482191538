import _ from 'lodash';
import {
  faPencil,
  faFilter,
  faSquarePlus,
  faSquareMinus,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGrid,
  textEditorNorm,
  SelectColumn,
  EmptyDataGrid,
  HeaderRenderer,
  FilterContext,
  FilterFlagContext,
  pager,
} from '../../../components/DataGrid/DataGrid';
import Paginate from '../../../components/Paginate/Paginate';
import DiffText from '../../../components/DiffText';
import { isNothing, parseDiff } from '../../../common/utils';
import { GenericObjType, FilterType } from '../../../common/types';
import {
  loadDraftTables,
  addDraftTables,
  updateDraftTables,
  removeDraftTables,
} from '../../../state/draft-table/draftTableActions';
import { loadFileById } from '../../../state/file/fileActions';
import { loadBranchById } from '../../../state/branch/branchActions';
import { RootState } from '../../../store';
import type DraftTableType from '../../../models/draft-table';
import './draftTable.css';
import AddDialog from './dialog/add/Add';
import RemoveDialog from './dialog/remove/Remove';

const DraftTable = ({
  fileId,
  branchId,
  params,
  onSelect,
  selectedRowFilter,
  lockTable = false,
  onFilter = () => {},
  searchParams = {},
}: {
  fileId: number;
  branchId: number;
  params?: { [k: string]: any };
  onSelect?: (selectedData: DraftTableType) => void;
  selectedRowFilter?: Partial<{ [k in keyof DraftTableType]: any }>;
  lockTable?: boolean;
  searchParams?: GenericObjType;
  onFilter?: (a: GenericObjType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const baseSearchParams = useMemo(() => searchParams || {}, [searchParams]);
  const dispatch = useDispatch();
  const draftTablesReload = useSelector(
    (state: RootState) => state.branch.active.flags.reload
  );
  const branchesReload = useSelector(
    (state: RootState) => state.branch.active.flags.reload
  );
  const draftTables = useSelector(
    (state: RootState) => state.draftTable.active.data
  );
  const draftTablesMetaList = useSelector(
    (state: RootState) => state.draftTable.active.metaList
  );
  const draftTablesMeta = useSelector(
    (state: RootState) => state.draftTable.active.meta
  );
  const [selectedRows, setSelectedRows] = useState((): DraftTableType[] => []);
  const hasSelectedRows = !_.isEmpty(selectedRows);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [editable, setEditable] = useState(false);
  const [forcePage, setForcePage] = useState(0);
  const [selectedPageNum, setSelectedPageNum] = useState(0);
  const [selectedPageRowNum, setSelectedPageRowNum] = useState(0);

  const userColumns = [
    SelectColumn,
    {
      key: 'rowNum',
      name: 'Row',
      maxWidth: 50,
      frozen: true,
      resizable: true,
    },
    {
      key: 'status',
      name: 'Status',
      resizable: true,
    },
    {
      key: 'name',
      name: 'Draft Table',
      minWidth: 200,
      editable: editable,
      resizable: true,
      headerCellClass: 'filter-cell',
      renderEditCell: (props: any) => {
        const rowValue = props.row.name;
        const rowDiff = parseDiff(rowValue);
        if (!rowDiff.isDiff) return textEditorNorm(props);
        return textEditorNorm({
          ...props,
          row: {
            ...props.row,
            name: `${rowDiff.value.before} ${rowDiff.value.after}`,
          },
        });
      },
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'name'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      renderCell: ({ row }: { row: DraftTableType }) => {
        const rowValue = row.name;
        const rowDiff = parseDiff(rowValue);
        if (!rowDiff.isDiff) return rowDiff.value;
        return (
          <>
            <DiffText keyValue={rowDiff.value}></DiffText>
          </>
        );
      },
    },
    {
      key: 'action',
      name: 'Action',
      width: 100,
      renderCell: ({ row }: { row: DraftTableType }) => {
        return (
          <>
            <button className="row-button" onClick={() => handleOnSelect(row)}>
              <FontAwesomeIcon icon={faFolderOpen} />
            </button>
          </>
        );
      },
      editable: false,
      resizable: true,
    },
  ];

  const [filterFlag, setFilterFlag] = useState(!isNothing(searchParams));
  const [filters, setFilters] = useState(baseSearchParams);

  const handleAddRecords = (data: DraftTableType) => {
    const payload: DraftTableType[] = [data];
    dispatch(addDraftTables(fileId, branchId, payload));
  };

  const handleUpdateRecords = (updatedData: DraftTableType[]) => {
    dispatch(updateDraftTables(fileId, branchId, updatedData));
  };

  const handleRemoveRecords = () => {
    dispatch(removeDraftTables(fileId, branchId, selectedRows));
    setSelectedRows([]);
  };

  const handleRowSelection = (selectedData: DraftTableType[]) => {
    setSelectedRows(selectedData);
  };

  const debouncedOnFilter = useRef(
    _.debounce((currentFilters) => {
      dispatch(
        loadDraftTables(fileId, branchId, {
          ...baseParams,
          where: { ...currentFilters, ...baseParams.where },
        })
      );
    }, 1000)
  ).current;

  const handleFilter = (currentFilters: FilterType<DraftTableType>) => {
    setFilters(currentFilters);
    debouncedOnFilter(currentFilters);
    onFilter(currentFilters);
  };

  const handleToggleFilter = (isEnabled: boolean) => {
    const currentFilters = isEnabled ? filters : {};
    setFilterFlag(isEnabled);
    handleFilter(currentFilters);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    const currentPageNum = currentPageInx + 1;
    const pageStartRowNum = pager.detectPageStartRowNum(
      currentPageNum,
      draftTablesMetaList
    );
    setSelectedPageNum(currentPageNum);
    setSelectedPageRowNum(pageStartRowNum);
  };

  const loadPage = useCallback(
    (pageInx: number) => {
      const currentPage = pageInx + 1;
      const pageMeta = _.find(draftTablesMetaList, { currentPage });
      if (!_.isEmpty(pageMeta)) return pageMeta;
      dispatch(
        loadDraftTables(fileId, branchId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          page: currentPage,
        })
      );
      return pageMeta;
    },
    [dispatch, fileId, branchId, baseParams, draftTablesMetaList, filters]
  );

  const handleOnSelect = (selectedData: DraftTableType) => {
    if (_.isEmpty(selectedData)) return;
    if (!onSelect) return;
    onSelect(selectedData);
  };

  useEffect(() => {
    if (branchesReload) {
      dispatch(loadBranchById(fileId, branchId));
    }
  }, [dispatch, fileId, branchId, branchesReload]);

  useEffect(() => {
    if (draftTablesReload) {
      dispatch(
        loadDraftTables(fileId, branchId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
        })
      );
    }
  }, [dispatch, fileId, branchId, baseParams, filters, draftTablesReload]);

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadBranchById(fileId, branchId));
    dispatch(
      loadDraftTables(fileId, branchId, {
        ...baseParams,
        where: { ...filters, ...baseParams.where },
      })
    );
  }, [dispatch, fileId, branchId, filters, baseParams]);

  const isEmptyGrid = userColumns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="draft-table-container">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="draft-table-container">
      <FilterFlagContext.Provider value={filterFlag}>
        <FilterContext.Provider value={filters}>
          <div className="grid-toolbar">
            <div className="left-toolbar">
              <button
                type="button"
                disabled={!editable}
                onClick={() => setOpenAddDialog(true)}
              >
                <FontAwesomeIcon icon={faSquarePlus} />
                <span className="add icon-label">Add</span>
              </button>
              <button
                type="button"
                disabled={!hasSelectedRows || !editable}
                onClick={() => setOpenRemoveDialog(true)}
              >
                <FontAwesomeIcon icon={faSquareMinus} />
                <span className="remove icon-label">Remove</span>
              </button>
            </div>
            <div className="center-toolbar">
              <Paginate
                totalPages={Number(draftTablesMeta?.totalPages)}
                onPageClick={handlePageClick}
                forcePage={forcePage}
              />
            </div>
            <div className="right-toolbar">
              <button
                className={`filterable ${filterFlag ? 'selected' : ''}`}
                onClick={() => handleToggleFilter(!filterFlag)}
              >
                <FontAwesomeIcon icon={faFilter} />
                <span className="filter icon-label">Filter</span>
              </button>
              <button
                className={`editable ${editable ? 'selected' : ''}`}
                onClick={() => setEditable(!editable)}
                disabled={lockTable}
              >
                <FontAwesomeIcon icon={faPencil} />
                <span className="edit icon-label">Edit</span>
              </button>
            </div>
          </div>
          <DataGrid<DraftTableType>
            rowKeyGetter={(row) => String(row.id)}
            columns={userColumns}
            rows={draftTables}
            rowsMetaList={draftTablesMetaList}
            onPageChange={setForcePage}
            loadPage={loadPage}
            selectedPageNum={selectedPageNum}
            selectedPageRowNum={selectedPageRowNum}
            rowHeight={30}
            onUpdate={handleUpdateRecords}
            onRowSelection={handleRowSelection}
            selectedRowFilter={selectedRowFilter}
          ></DataGrid>
          {openAddDialog ? (
            <AddDialog
              open={openAddDialog}
              setOpen={setOpenAddDialog}
              onSubmit={handleAddRecords}
            ></AddDialog>
          ) : null}
          {openRemoveDialog ? (
            <RemoveDialog
              open={openRemoveDialog}
              setOpen={setOpenRemoveDialog}
              onSubmit={handleRemoveRecords}
            ></RemoveDialog>
          ) : null}
        </FilterContext.Provider>
      </FilterFlagContext.Provider>
    </div>
  );
};

export default DraftTable;
