import _ from 'lodash';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  faFilter,
  faSquarePlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DataGrid,
  EmptyDataGrid,
  SelectOptions,
  HeaderRenderer,
  FilterContext,
  FilterFlagContext,
  pager,
} from '../../../../components/DataGrid/DataGrid';
import { isNothing } from '../../../../common/utils';
import { GenericObjType, FilterType } from '../../../../common/types';
import {
  loadUserGroups,
  addUsers,
  updateUserGroups,
  removeUserGroups,
} from '../../../../state/user-group/user/userActions';
import { loadGroupById } from '../../../../state/group/groupActions';
import { loadPermissions } from '../../../../state/permission/permissionActions';
import { RootState } from '../../../../store';
import type UserType from '../../../../models/user';
import type UserGroupType from '../../../../models/user-group';
import './user.css';
import JoinDialog from '../../user-join/dialog/grid/User';
import RemoveDialog from './dialog/remove/Remove';
import Paginate from '../../../../components/Paginate/Paginate';

const User = ({
  groupId,
  params,
  onFilter = () => {},
  searchParams = {},
}: {
  groupId: number;
  params?: { [k: string]: any };
  searchParams?: GenericObjType;
  onFilter?: (a: GenericObjType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const baseSearchParams = useMemo(() => searchParams || {}, [searchParams]);
  const dispatch = useDispatch();
  const permissions = useSelector(
    (state: RootState) => state.permission.active.data
  );
  const users = useSelector(
    (state: RootState) => state.userByGroup.active.data
  );
  const usersMeta = useSelector(
    (state: RootState) => state.userByGroup.active.meta
  );
  const usersMetaList = useSelector(
    (state: RootState) => state.userByGroup.active.metaList
  );
  const [selectedRow, setSelectedRow] = useState<UserGroupType | undefined>(
    undefined
  );
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [forcePage, setForcePage] = useState(0);
  const [selectedPageNum, setSelectedPageNum] = useState(0);
  const [selectedPageRowNum, setSelectedPageRowNum] = useState(0);

  const columns = [
    {
      key: 'rowNum',
      name: 'Row',
      width: 50,
      resizable: true,
    },
    {
      key: 'user',
      name: 'User Name',
      headerCellClass: 'filter-cell',
      renderCell: ({ row }: { row: UserGroupType }) => row.user?.name,
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'user.name'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      resizable: true,
    },
    {
      key: 'permission',
      name: 'Permission Name',
      headerCellClass: 'filter-cell',
      renderCell: ({ row }: { row: UserGroupType }) => row.permission?.role,
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'permission.role'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      resizable: true,
      editable: true,
      renderEditCell: (p: any) => {
        const permissionList = permissions.map((item) => ({
          permission: item,
        }));
        return (
          <SelectOptions<Pick<UserGroupType, 'permission'>>
            row={p.row}
            targetColumn={'permission.role'}
            onRowChange={p.onRowChange}
            values={permissionList}
          ></SelectOptions>
        );
      },
      width: 200,
    },
    {
      key: 'group',
      name: 'Group Name',
      headerCellClass: 'filter-cell',
      renderCell: ({ row }: { row: UserGroupType }) => row.group?.name,
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'group.name'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      resizable: true,
      width: 150,
    },
    {
      resizable: true,
      key: 'action',
      name: 'Action',
      width: 80,
      renderCell: () => {
        return (
          <>
            <button onClick={() => setOpenRemoveDialog(true)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        );
      },
    },
  ];

  const [filterFlag, setFilterFlag] = useState(!isNothing(searchParams));
  const [filters, setFilters] = useState(baseSearchParams);

  const handleAddRecords = (data: UserType[]) => {
    dispatch(addUsers(groupId, data));
  };

  const handleRemoveRecords = () => {
    if (selectedRow) {
      dispatch(removeUserGroups(groupId, [selectedRow]));
    }
  };

  const handleUpdateRecords = (updatedData: UserGroupType[]) => {
    const updatedPayload = updatedData.map((item) => ({
      ...item,
      permissionId: Number(item.permission?.id),
    }));
    dispatch(updateUserGroups(groupId, updatedPayload));
  };

  const debouncedOnFilter = useRef(
    _.debounce((currentFilters) => {
      dispatch(
        loadUserGroups(groupId, {
          ...baseParams,
          where: { ...currentFilters, ...baseParams.where },
        })
      );
    }, 1000)
  ).current;

  const handleFilter = (currentFilters: FilterType<UserType>) => {
    setFilters(currentFilters);
    debouncedOnFilter(currentFilters);
    onFilter(currentFilters);
  };

  const handleToggleFilter = (isEnabled: boolean) => {
    const currentFilters = isEnabled ? filters : {};
    setFilterFlag(isEnabled);
    handleFilter(currentFilters);
  };
  const handleCellSelection = (data: UserGroupType) => {
    setSelectedRow(data);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    const currentPageNum = currentPageInx + 1;
    const pageStartRowNum = pager.detectPageStartRowNum(
      currentPageNum,
      usersMetaList
    );
    setSelectedPageNum(currentPageNum);
    setSelectedPageRowNum(pageStartRowNum);
  };

  const loadPage = useCallback(
    (pageInx: number) => {
      const currentPage = pageInx + 1;
      const pageMeta = _.find(usersMetaList, { currentPage });
      if (!_.isEmpty(pageMeta)) return pageMeta;
      dispatch(
        loadUserGroups(groupId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          page: currentPage,
        })
      );
      return pageMeta;
    },
    [dispatch, baseParams, usersMetaList, filters, groupId]
  );

  useEffect(() => {
    dispatch(loadPermissions({ where: { type: 'group' } }));
    dispatch(loadGroupById(groupId));
    dispatch(
      loadUserGroups(groupId, {
        ...baseParams,
        where: { ...filters, ...baseParams.where },
      })
    );
  }, [dispatch, baseParams, filters, groupId]);

  const isEmptyGrid = columns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="user-container">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="user-container">
      <FilterFlagContext.Provider value={filterFlag}>
        <FilterContext.Provider value={filters}>
          <div className="grid-toolbar">
            <div className="left-toolbar">
              <button type="button" onClick={() => setOpenAddDialog(true)}>
                <FontAwesomeIcon icon={faSquarePlus} />
                <span className="add icon-label">Add</span>
              </button>
            </div>
            <div className="center-toolbar">
              <Paginate
                totalPages={Number(usersMeta?.totalPages)}
                onPageClick={handlePageClick}
                forcePage={forcePage}
              />
            </div>
            <div className="right-toolbar">
              <button
                className={`filterable ${filterFlag ? 'selected' : ''}`}
                onClick={() => handleToggleFilter(!filterFlag)}
              >
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </div>
          </div>
          <DataGrid<UserGroupType>
            rowKeyGetter={(row) => String(row.id)}
            columns={columns}
            rows={users}
            rowsMetaList={usersMetaList}
            rowHeight={30}
            onCellSelection={handleCellSelection}
            onPageChange={setForcePage}
            loadPage={loadPage}
            selectedPageNum={selectedPageNum}
            selectedPageRowNum={selectedPageRowNum}
            onUpdate={handleUpdateRecords}
          ></DataGrid>
          {openAddDialog ? (
            <JoinDialog
              groupId={groupId}
              params={baseParams}
              onSelect={handleAddRecords}
              open={openAddDialog}
              setOpen={setOpenAddDialog}
            ></JoinDialog>
          ) : null}
          {openRemoveDialog ? (
            <RemoveDialog
              open={openRemoveDialog}
              setOpen={setOpenRemoveDialog}
              selectedRow={selectedRow}
              onSubmit={handleRemoveRecords}
            ></RemoveDialog>
          ) : null}
        </FilterContext.Provider>
      </FilterFlagContext.Provider>
    </div>
  );
};

export default User;
