import { useRef } from 'react';
import 'react-data-grid/lib/styles.css';
import ReactDataGrid, { DataGridHandle } from 'react-data-grid';
import './datagrid.css';

export const EmptyDataGrid = () => {
  const gridRef = useRef<DataGridHandle>(null);
  return (
    <ReactDataGrid
      ref={gridRef}
      columns={[]}
      rows={[]}
      style={{ blockSize: '100%', resize: 'both' }}
      renderers={{
        noRowsFallback: (
          <div className="empty-grid">
            <h5 className="empty-grid-message">Empty Grid</h5>
          </div>
        ),
      }}
    />
  );
};
