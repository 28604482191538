import { useState, useRef, useEffect, useContext } from 'react';
// https://github.com/adazzle/react-data-grid/issues/3463
import 'react-data-grid/lib/styles.css';
import ReactDataGrid, {
  CopyEvent,
  PasteEvent,
  Column,
  FillEvent,
  RowsChangeData,
  textEditor,
  DataGridHandle,
} from 'react-data-grid';
import {
  GenericObjType,
  ModelMetaType,
  ModelRowNumType,
} from '../../common/types';
import useSuppressor from './components/Temp';
import './datagrid.css';
import * as pager from './pager';
import { FilterFlagContext } from './components/Filter';

export * as pager from './pager';
export * from './components/Select';
export * from './components/Empty';
export * from './components/Filter';
export * from 'react-data-grid';
export * from './utils';

type DataGridProps<TRow extends GenericObjType> = {
  headerRowHeight?: number | undefined;
  columns: Column<TRow>[];
  rows: ModelRowNumType<TRow>[];
  rowsMetaList: ModelMetaType[];
  onPageChange?: React.Dispatch<React.SetStateAction<number>>;
  loadPage?: (pageInx: number) => void;
  selectedPageNum?: number;
  selectedPageRowNum?: number;
  rowHeight?: number;
  rowKeyGetter?: (row: TRow) => string;
  onUpdate?: (updatedRows: TRow[]) => void;
  onRowSelection?: (selectedRows: TRow[]) => void;
  onCellSelection?: (selectedRow: TRow) => void;
  selectedRowFilter?: Partial<{ [k in keyof TRow]: any }>;
  selectedRows?: TRow[];
};

export const textEditorNorm = (props: any) => {
  // throws a warning if not normalized to empty string
  const rowValue = props.row[props.column.key] || '';
  const rowNorm = { ...props.row, [props.column.key]: rowValue };
  const propsNorm = { ...props, row: rowNorm };
  return textEditor(propsNorm);
};

export const DataGrid = <TRow extends GenericObjType>({
  rows,
  rowsMetaList,
  onPageChange = () => {},
  loadPage = () => {},
  selectedPageNum,
  selectedPageRowNum,
  columns,
  rowHeight = 30,
  rowKeyGetter,
  headerRowHeight,
  onUpdate = () => {},
  onRowSelection = () => {},
  onCellSelection = () => {},
  selectedRowFilter,
  selectedRows,
}: DataGridProps<TRow>) => {
  useSuppressor(); // TODO: remove once bug is fixed
  const filterFlag = useContext(FilterFlagContext);
  const gridRef = useRef<DataGridHandle>(null);
  const [selectedRowIds, setSelectedRowIds] = useState(
    new Set(selectedRows?.map((rowItem) => String(rowItem.id)))
  );
  const scrollRowNumRef = useRef(0);
  const scrollColumnInxRef = useRef(3);
  const loadPageQueue = useRef<number[]>([]);

  const handleCopy = (event: CopyEvent<TRow>): void => {
    const { sourceRow, sourceColumnKey } = event;
    if (window.isSecureContext) {
      const columnKeyType = sourceColumnKey as keyof TRow;
      navigator.clipboard.writeText(sourceRow[columnKeyType]);
    }
  };

  const handlePaste = (event: PasteEvent<TRow>): TRow => {
    const { sourceColumnKey, sourceRow, targetColumnKey, targetRow } = event;
    return {
      ...targetRow,
      [targetColumnKey]: sourceRow[sourceColumnKey as keyof TRow],
    };
  };

  const handleFill = (event: FillEvent<TRow>): TRow => {
    const { columnKey, sourceRow, targetRow } = event;
    const columnKeyType = columnKey as keyof TRow;
    return { ...targetRow, [columnKey]: sourceRow[columnKeyType] };
  };

  const handleUpdate = (rows: TRow[], data: RowsChangeData<TRow>) => {
    const updatedIndexes = data.indexes;
    const updatedRows = updatedIndexes.map((idx) => {
      const updatedRow = rows[idx];
      return updatedRow;
    });
    onUpdate(updatedRows);
  };

  const handleCellSelection = (data: TRow) => {
    onCellSelection(data);
  };

  const handleRowSelection = (data: Set<string>) => {
    setSelectedRowIds(data);
    const selectedIds = Array.from(data);
    const updatedRows = rows.filter((row) =>
      selectedIds.includes(String(row.id))
    );
    onRowSelection(updatedRows);
  };

  const handleScroll = (scrollEvent: React.UIEvent<HTMLDivElement>) => {
    const clientRow = pager.calculateRowInx(scrollEvent, rowHeight);
    const currentRow = rows[clientRow.bottomRowInx];
    if (!currentRow) return;
    scrollRowNumRef.current = currentRow.rowNum;
    loadPageQueue.current = pager.handlePageLoad(
      scrollRowNumRef.current,
      rows,
      rowsMetaList,
      loadPageQueue.current,
      loadPage
    );
    pager.handlePageChange(
      scrollRowNumRef.current,
      rows,
      rowsMetaList,
      onPageChange
    );
  };

  useEffect(() => {
    if (!selectedPageNum) return;
    loadPage(Number(selectedPageNum - 1));
  }, [selectedPageNum, loadPage]);

  useEffect(() => {
    if (!selectedPageRowNum) return;
    scrollRowNumRef.current = Number(selectedPageRowNum);
  }, [selectedPageRowNum]);

  useEffect(() => {
    pager.scrollGrid(
      gridRef,
      rows,
      scrollColumnInxRef.current,
      scrollRowNumRef.current
    );
  }, [selectedPageRowNum, rows, rowsMetaList]);

  useEffect(() => {
    setSelectedRowIds(
      new Set(selectedRows?.map((rowItem) => String(rowItem.id)))
    );
  }, [selectedRows]);

  return (
    <ReactDataGrid
      ref={gridRef}
      headerRowHeight={filterFlag ? 115 : headerRowHeight}
      rowKeyGetter={rowKeyGetter}
      columns={columns}
      rows={rows}
      className={'filter-cell fill-grid'}
      rowHeight={rowHeight}
      onRowsChange={handleUpdate}
      selectedRows={selectedRowIds}
      onSelectedRowsChange={handleRowSelection}
      onCopy={handleCopy}
      onPaste={handlePaste}
      onFill={handleFill}
      onScroll={handleScroll}
      style={{ blockSize: 'calc(100% - 26px)', resize: 'both' }}
      onCellClick={(args, event) => {
        event.preventGridDefault();
        scrollColumnInxRef.current = args.column.idx;
        scrollRowNumRef.current = args.row.rowNum;
        handleCellSelection(args.row);
        args.selectCell(true);
      }}
      rowClass={(row) => {
        const isEven = row.rowNum % 2 === 0;
        let isSelected = false;
        if (selectedRowFilter) {
          const [[key, value]] = Object.entries(selectedRowFilter);
          isSelected = row.hasOwnProperty(key) && row[key] === value;
        }
        const evenRowTag = isEven ? 'even-row' : '';
        const oddRowTag = !isEven ? 'odd-row' : '';
        const selectedRowTag = isSelected ? 'selected-row' : '';

        const rowTag = [evenRowTag, oddRowTag, selectedRowTag].join(' ');
        return rowTag;
      }}
      renderers={{
        noRowsFallback: (
          <div className="empty-grid">
            <h5 className="empty-grid-message">Empty Grid</h5>
          </div>
        ),
      }}
    />
  );
};
