import React from 'react';
import Branch from '../../grid/Branch';
import Dialog from '../../../../components/Dialog/Dialog';
import BranchType from '../../../../models/branch';
import './branch.css';

const BranchDialog = ({
  fileId,
  open,
  setOpen,
  onSubmit,
  selectedRowFilter,
  params,
  onSelect,
}: {
  fileId: number;
  open: boolean;
  setOpen: (data: boolean) => void;
  onSubmit?: () => void;
  selectedRowFilter?: Partial<{ [k in keyof BranchType]: any }>;
  params?: { [k: string]: any };
  onSelect?: (selectedData: BranchType) => void;
}) => {
  return (
    <Dialog selector="branch-dialog" open={open} setOpen={setOpen}>
      <div className="dialog-content">
        <Branch
          fileId={fileId}
          onSelect={onSelect}
          selectedRowFilter={selectedRowFilter}
          params={params}
        ></Branch>
      </div>
      <div className="menu-buttons">
        <button type="button" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
    </Dialog>
  );
};

export default BranchDialog;
