import _ from 'lodash';
import { useState, useMemo } from 'react';
import { GenericObjType, FilterType } from '../../../../../common/types';
import { isNothing } from '../../../../../common/utils';
import { seqOperators } from '../../../../../common/constants';
import { SelectOptions } from '../../Select';
import './condition.css';

const FilterByCondition = <T extends FilterType<GenericObjType>>({
  filters,
  targetColumn,
  setFilters,
  rest,
}: {
  filters: T;
  targetColumn: string;
  setFilters: (currentFilters: T) => void;
  rest?: any;
}) => {
  const baseFilters = useMemo(() => filters || {}, [filters]);
  const defaultFilter = { any: '' };
  const targetFilter = _.get(baseFilters, targetColumn, defaultFilter);
  const currentFilter = Object.entries(targetFilter);
  const [currentOp, currentVal] = currentFilter[0];
  const [filterOp, setFilterOp] = useState({ operator: currentOp });
  const [filterVal, setFilterVal] = useState(currentVal);
  const [submitted, setSubmitted] = useState(false);

  const createSearchParams = (targetFilters: T) => {
    const searchParams = Object.keys(targetFilters).reduce((result, key) => {
      const filterObj = targetFilters[key];
      if (!filterObj) return result;
      const [op, val] = Object.entries(filterObj)[0];
      if (op === 'any') return result;
      return { ...result, [key]: { [op]: val } };
    }, {});
    return searchParams;
  };

  const handleSubmit = () => {
    const gridFilters = {
      ...filters,
      [targetColumn]: { [filterOp.operator]: filterVal },
    };
    const searchParams = createSearchParams(gridFilters);
    setFilters(searchParams as T);
    setSubmitted(true);
  };

  const handleCancel = () => {
    const forceOp = { operator: 'any' };
    const forceVal = '';
    setFilterOp(forceOp);
    setFilterVal(forceVal);
    const gridFilters = {
      ...filters,
      [targetColumn]: { [forceOp.operator]: forceVal },
    };
    const searchParams = createSearchParams(gridFilters);
    setFilters(searchParams as T);
    setSubmitted(false);
  };

  return (
    <div className="filter-by-condition">
      <SelectOptions
        row={filterOp}
        targetColumn={'operator'}
        onRowChange={(updatedValues: any, commitChanges: boolean) => {
          setFilterOp(updatedValues);
        }}
        values={seqOperators.map((op) => ({ operator: op }))}
      ></SelectOptions>
      <input
        {...rest}
        className="filter-value"
        style={{
          inlineSize: '100%',
        }}
        value={filterVal}
        onChange={(e) => {
          setFilterVal(e.target.value);
          const hasValue = !isNothing(e.target.value);
          const isAny = filterOp.operator === 'any';
          if (isAny && hasValue) {
            const assumedOp = { operator: 'eq' };
            setFilterOp(assumedOp);
          }
        }}
        onKeyDown={(e) => {
          if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
            e.stopPropagation();
          }
        }}
        placeholder="value"
      />
      <div className="filter-buttons">
        <button onClick={() => handleCancel()}>cancel</button>
        <button
          className={submitted ? 'selected' : ''}
          onClick={() => handleSubmit()}
        >
          submit
        </button>
      </div>
    </div>
  );
};

export default FilterByCondition;
