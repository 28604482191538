import { AxiosResponse } from 'axios';
import { useState, useEffect } from 'react';
import { GenericObjType } from '../../../../common/types';
import Params from './params/Params';
import Options from './options/Options';
import Headers from '../../components/headers/Headers';
import Response from '../../components/response/Response';
import Url from '../../components/url/Url';
import './row.css';

const MasterRowResource = ({
  fileId,
  branchId,
  tableId,
}: {
  fileId: number;
  branchId: number;
  tableId: number;
}) => {
  const [selectedConfig, setSelectedConfig] = useState('params');
  const [columnParams, setColumnParams] = useState<GenericObjType>({});
  const [columnOptions, setColumnOptions] = useState<GenericObjType>({});
  const [connectionString, setConnectionString] = useState<GenericObjType>({});
  const [response, setResponse] = useState<AxiosResponse>({} as AxiosResponse);
  const resourceVersion = 'api/v1';
  const fileUrl = `files/${fileId}`;
  const tableUrl = `tables/${tableId}`;
  const resourceUrl = `${fileUrl}/${tableUrl}/rows`;

  const handleOnSend = (responseData: AxiosResponse) => {
    setResponse(responseData);
    setSelectedConfig('response');
  };

  useEffect(() => {
    setConnectionString({ ...{ where: columnParams }, ...columnOptions });
  }, [columnParams, columnOptions]);

  return (
    <div className="master-row-resource-container">
      <div className="master-row-resource-url-container">
        <Url
          resourceVersion={resourceVersion}
          resourceUrl={resourceUrl}
          params={connectionString}
          onSend={handleOnSend}
        ></Url>
      </div>
      <div className="master-row-resource-tab-container">
        <div className="master-row-resource-left">
          <button
            className={`master-row-resource params text ${
              selectedConfig === 'params' ? 'selected' : ''
            }`}
            onClick={() => setSelectedConfig('params')}
          >
            Params
          </button>
          <button
            className={`master-row-resource options text ${
              selectedConfig === 'options' ? 'selected' : ''
            }`}
            onClick={() => setSelectedConfig('options')}
          >
            Options
          </button>
          <button
            className={`master-row-resource headers text ${
              selectedConfig === 'headers' ? 'selected' : ''
            }`}
            onClick={() => setSelectedConfig('headers')}
          >
            Headers
          </button>
        </div>
        <div className="master-row-resource-right">
          <button
            className={`master-row-resource result text ${
              selectedConfig === 'response' ? 'selected' : ''
            }`}
            onClick={() => setSelectedConfig('response')}
          >
            Response
          </button>
        </div>
      </div>
      <div className="master-row-resource-content-container text">
        {selectedConfig === 'params' ? (
          <Params
            fileId={fileId}
            branchId={branchId}
            tableId={tableId}
            onChange={setColumnParams}
          ></Params>
        ) : null}
        {selectedConfig === 'options' ? (
          <Options
            fileId={fileId}
            branchId={branchId}
            tableId={tableId}
            onChange={setColumnOptions}
          ></Options>
        ) : null}
        {selectedConfig === 'headers' ? <Headers></Headers> : null}
        {selectedConfig === 'response' ? (
          <Response response={response}></Response>
        ) : null}
      </div>
    </div>
  );
};

export default MasterRowResource;
