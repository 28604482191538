import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableList from '../../../domain/table/list/Table';
import ColumnDialog from '../../../domain/column/dialog/grid/Column';
import type TableType from '../../../models/table';
import './footer.css';

const Footer = ({
  fileId,
  branchId,
  tableId,
}: {
  fileId: number;
  branchId: number;
  tableId?: number;
}) => {
  const navigate = useNavigate();
  const [openColumns, setOpenColumns] = useState(false);
  const handleSelectTable = (currentTable: TableType) => {
    const filePath = `/files/${fileId}`;
    const branchPath = `/branches/${branchId}`;
    const tablePath = `/tables/${currentTable.tableId}`;
    const redirectPath = `/masters${filePath}${branchPath}${tablePath}`;
    navigate(redirectPath);
  };

  return (
    <div className="master-footer-container">
      <div className="footer-left">
        <TableList
          fileId={fileId}
          branchId={branchId}
          selectedRowFilter={{ tableId }}
          onSelectOption={setOpenColumns}
          onSelectTable={handleSelectTable}
        ></TableList>
      </div>
      <div className="footer-center"></div>
      <div className="footer-right"></div>
      {tableId ? (
        <ColumnDialog
          fileId={fileId}
          branchId={branchId}
          tableId={tableId}
          open={openColumns}
          setOpen={setOpenColumns}
        ></ColumnDialog>
      ) : null}
    </div>
  );
};
export default Footer;
