import type DraftTableType from '../../../models/draft-table';
import type { ReducerType, StateType } from '../../../common/state/types';
import { initialState } from '../../../common/state/utils/constant';
import {
  actionOneMap,
  actionResponseMap,
} from '../../../common/state/types';
import {
  dataListLoadedReducer,
  dataLoadedReducer,
  dataFailedReducer,
} from '../../../common/state/reducers';

const draftTableReducer: ReducerType<DraftTableType> = (
  state = {
    active: initialState as StateType<DraftTableType>,
    rollback: initialState as StateType<DraftTableType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.DRAFTTABLEMERGEDIFF_DATALIST_LOADED:
      return dataListLoadedReducer<DraftTableType>({
        state,
        action,
      });
    case actionOneMap.DRAFTTABLEMERGEDIFF_DATA_LOADED:
      return dataLoadedReducer<DraftTableType>({
        state,
        action,
      });
    case actionResponseMap.DRAFTTABLEMERGEDIFF_DATA_FAILED:
      return dataFailedReducer<DraftTableType>({
        state,
        action,
      });
    default:
      return state;
  }
};

export default draftTableReducer;
