import { Dispatch } from 'redux';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import { actionOneMap, actionResponseMap } from '../../common/state/types';
import type FileType from '../../models/file';
import type { StateType } from '../../common/state/types';
import { streamDraft } from '../../common/excel/loader';
import { handleError } from '../alert/alertActions';

const domainSize = process.env.REACT_APP_FILES_PAGE_SIZE;
const defaultSize = process.env.REACT_APP_DEFAULT_PAGE_SIZE;
const batchSize = domainSize || defaultSize;

export const streamUploadDraft =
  (
    fileId: number,
    branchId: number,
    fileData: any
  ): ThunkAction<void, StateType<FileType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    try {
      await streamDraft(fileId, branchId, fileData);
    } catch (err) {
      handleError(dispatch, err);
    }
  };

export const loadFiles =
  (
    params: { [k: string]: any } = {}
  ): ThunkAction<void, StateType<FileType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryParams = {
      ...params,
      page: params.page,
      size: params.size || batchSize,
    };
    const queryPath = `/api/files/`;
    try {
      const res = await axios.get(queryPath, {
        params: queryParams,
        paramsSerializer: (items) => qs.stringify(items),
      });
      dispatch({
        type: actionResponseMap.FILE_DATALIST_LOADED,
        payload: res.data,
        path: { url: queryPath, params: queryParams },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.FILE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const downloadMaster =
  (
    fileId: number
  ): ThunkAction<void, StateType<FileType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = `/api/files/${fileId}/download`;
    try {
      const res = await axios.get(queryPath, { responseType: 'blob' });
      const contentDisposition = res.headers['content-disposition'];
      let fileName = 'download-master.xlsx';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (err) {
      handleError(dispatch, err);
    }
  };

export const downloadDraft =
  (
    fileId: number,
    branchId: number
  ): ThunkAction<void, StateType<FileType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = `/api/files/${fileId}/${branchId}/download`;
    try {
      const res = await axios.get(queryPath, { responseType: 'blob' });
      const contentDisposition = res.headers['content-disposition'];
      let fileName = 'download-draft.xlsx';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (err) {
      handleError(dispatch, err);
    }
  };

export const uploadDraft =
  (
    fileId: number,
    branchId: number,
    fileData: any
  ): ThunkAction<void, StateType<FileType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = `/api/files/${fileId}/${branchId}/upload`;
    const config = {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    };
    try {
      const body: FormData = new FormData();
      body.append('file', fileData);
      await axios.post(queryPath, body, config);
    } catch (err) {
      handleError(dispatch, err);
    }
  };

export const loadFileById =
  (
    fileId: number
  ): ThunkAction<void, StateType<FileType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const queryPath = `/api/files/${fileId}`;
    try {
      const res = await axios.get(queryPath);
      dispatch({
        type: actionOneMap.FILE_DATA_LOADED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.FILE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const addFile =
  (
    payload: FileType
  ): ThunkAction<void, StateType<FileType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payload });
    const queryPath = `/api/files/`;
    try {
      const res = await axios.post(queryPath, body, config);
      dispatch({
        type: actionOneMap.FILE_DATA_ADDED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.FILE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const updateFileById =
  (
    fileId: number,
    payload: FileType
  ): ThunkAction<void, StateType<FileType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const payloadData = { ...payload, id: fileId };
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    const body = JSON.stringify({ data: payloadData });
    const queryPath = `/api/files/${fileId}`;
    try {
      const res = await axios.put(queryPath, body, config);
      dispatch({
        type: actionOneMap.FILE_DATA_UPDATED,
        payload: res.data,
        path: { url: queryPath, params: {} },
      });
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.FILE_DATA_FAILED,
        payload: null,
      });
    }
  };

export const removeFileById =
  (
    fileId: number,
    payload: FileType
  ): ThunkAction<void, StateType<FileType>, null, Action<string>> =>
  async (dispatch: Dispatch) => {
    const payloadData = { ...payload, id: fileId };
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
      data: JSON.stringify({ data: payloadData }),
    };
    const queryPath = `/api/files/${fileId}`;
    try {
      dispatch({
        type: actionOneMap.FILE_DATA_DELETED,
        payload: payload,
        path: { url: queryPath, params: {} },
      });
      await axios.delete(queryPath, config);
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: actionResponseMap.FILE_DATA_FAILED,
        payload: null,
      });
    }
  };
