import React from 'react';
import DraftColumn from '../../grid/DraftColumn';
import Dialog from '../../../../components/Dialog/Dialog';
import './draft-column.css';

const DraftColumnDialog = ({
  fileId,
  branchId,
  tableId,
  open,
  setOpen,
  onSubmit,
  lockTable = false,
}: {
  fileId: number;
  branchId: number;
  tableId: number;
  open: boolean;
  setOpen: (data: boolean) => void;
  onSubmit?: () => void;
  lockTable?: boolean;
}) => {
  return (
    <Dialog selector="draft-column-dialog" open={open} setOpen={setOpen}>
      <div className="dialog-content">
        <DraftColumn
          fileId={fileId}
          branchId={branchId}
          tableId={tableId}
          lockTable={lockTable}
        ></DraftColumn>
      </div>
      <div className="menu-buttons">
        <button type="button" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
    </Dialog>
  );
};

export default DraftColumnDialog;
