import type FileType from '../../models/file';
import type { ReducerType, StateType } from '../../common/state/types';
import { initialState } from '../../common/state/utils/constant';
import {
  actionOneMap,
  actionResponseMap,
  actionFlagMap,
} from '../../common/state/types';
import {
  dataDeletedReducer,
  dataAddedReducer,
  dataUpdatedReducer,
  dataListLoadedReducer,
  dataLoadedReducer,
  dataFailedReducer,
  flagReducer,
} from '../../common/state/reducers';

const fileReducer: ReducerType<FileType> = (
  state = {
    active: initialState as StateType<FileType>,
    rollback: initialState as StateType<FileType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.FILE_DATALIST_LOADED:
      return dataListLoadedReducer<FileType>({
        state,
        action,
      });
    case actionOneMap.FILE_DATA_LOADED:
      return dataLoadedReducer<FileType>({
        state,
        action,
      });
    case actionOneMap.FILE_DATA_ADDED:
      return dataAddedReducer<FileType>({
        state,
        action,
      });
    case actionOneMap.FILE_DATA_UPDATED:
      return dataUpdatedReducer<FileType>({
        state,
        action,
      });
    case actionOneMap.FILE_DATA_DELETED:
      return dataDeletedReducer<FileType>({
        state,
        action,
      });
    case actionResponseMap.FILE_DATA_FAILED:
      return dataFailedReducer<FileType>({
        state,
        action,
      });
    case actionFlagMap.FILE_LOADING:
      return flagReducer<FileType>({
        state,
        action: { ...action, payload: { loading: true } },
      });
    case actionFlagMap.FILE_COMPLETED:
      return flagReducer<FileType>({
        state,
        action: { ...action, payload: { loading: false } },
      });
    default:
      return state;
  }
};

export default fileReducer;
