import _ from 'lodash';
import { useState, useRef } from 'react';
import Header from './header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../components/Alert/Alert';
import SubmitCard from '../../../domain/process-log/submit/card/Submit';
import Submit from '../../../domain/process-log/submit/grid/Submit';
import { isNothing } from '../../../common/utils';
import type ProcessLogType from '../../../models/process-log';
import './review.css';

const Review = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [cardView, setCardView] = useState(true);
  const [submittedStatus] = useState({ where: { status: 'submitted' } });
  const [baseParams, setBaseParams] = useState(submittedStatus);
  const handleOnSelect = (currentSubmit: ProcessLogType) => {
    const filePath = `/files/${currentSubmit.fileId}`;
    const branchPath = `/branches/${currentSubmit.branchId}`;
    const submitPath = `/submits/${currentSubmit.id}`;
    const redirectPath = `/reviews${filePath}${branchPath}${submitPath}`;
    navigate({ pathname: redirectPath });
  };
  const debouncedOnSearch = useRef(
    _.debounce((filterParams) => {
      setBaseParams(filterParams);
    }, 1000)
  ).current;

  const handleOnSearch = (value: any) => {
    if (isNothing(value)) {
      debouncedOnSearch(submittedStatus);
    } else {
      debouncedOnSearch({
        where: { ...submittedStatus.where, message: { substring: value } },
      });
    }
  };
  const handleViewChange = (isCardView: boolean) => {
    setCardView(isCardView);
  };

  return (
    <div className="home-container">
      <Alert></Alert>
      <div className="content-container">
        <div className={`sidebar${sidebarOpen ? '' : ' close'}`}>
          <Sidebar
            open={sidebarOpen}
            onToggle={(open) => setSidebarOpen(open)}
          ></Sidebar>
        </div>
        <div className={`content${sidebarOpen ? '' : ' open'}`}>
          <Header
            onSearch={handleOnSearch}
            onViewChange={handleViewChange}
          ></Header>
          <div className="file-data">
            {cardView ? (
              <SubmitCard
                onSelect={handleOnSelect}
                params={baseParams}
              ></SubmitCard>
            ) : (
              <Submit onSelect={handleOnSelect} params={baseParams}></Submit>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
