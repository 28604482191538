import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { faUser, faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNothing } from '../../../../common/utils';
import { registerUser } from '../../../../state/user/userActions';
import './register.css';

type RegisterType = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const Register = ({
  onCancel,
  onSubmit,
  signIn,
}: {
  onCancel: (data: boolean) => void;
  onSubmit: (data: boolean) => void;
  signIn: (data: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const defaultValue: RegisterType = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  };
  const [inputValue, setInputValue] = useState(defaultValue);
  const [inputError, setInputError] = useState(defaultValue);
  const [validated, setValidated] = useState(false);
  const inputClass = (key: keyof RegisterType) => {
    const hasInput = !isNothing(inputValue[key]);
    const hasError = !isNothing(inputError[key]);
    if (hasInput && hasError) return 'error';
    if (hasInput) return 'success';
    return '';
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const userPayload = _.omit(inputValue, ['confirmPassword']);
    dispatch(registerUser(userPayload));
  };
  const handleValidate = (key: string, value: any) => {
    if (key === 'name') {
      if (isNothing(value)) {
        setInputError({ ...inputError, [key]: 'Name is required' });
      } else {
        setInputError({ ...inputError, [key]: '' });
      }
    }
    if (key === 'email') {
      if (isNothing(value)) {
        setInputError({ ...inputError, [key]: 'Email is required' });
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        setInputError({ ...inputError, [key]: 'Invalid email address' });
      } else {
        setInputError({ ...inputError, [key]: '' });
      }
    }
    if (key === 'password') {
      if (isNothing(value)) {
        setInputError({ ...inputError, [key]: 'Password is required' });
      } else if (value.length < 6) {
        setInputError({
          ...inputError,
          [key]: 'Password must be 6 or more characters',
        });
      } else {
        setInputError({ ...inputError, [key]: '' });
      }
    }
    if (key === 'confirmPassword') {
      if (isNothing(value)) {
        setInputError({ ...inputError, [key]: 'Confirm password is required' });
      } else if (inputValue.password !== value) {
        setInputError({
          ...inputError,
          [key]: 'Password must match',
        });
      } else {
        setInputError({ ...inputError, [key]: '' });
      }
    }
  };

  useEffect(() => {
    const hasError = _.values(inputError).some((item) => !isNothing(item));
    const hasCompleted = _.values(inputValue).every((item) => !isNothing(item));
    setValidated(hasCompleted && !hasError);
  }, [inputError, inputValue]);

  return (
    <>
      <div className="register-container">
        <div className="text title">Create an account</div>
        <form className="register" onSubmit={handleSubmit}>
          <div className="label-container">
            <div className="label-props">
              <FontAwesomeIcon className="icon" icon={faUser} />
              <label htmlFor="name">name</label>
            </div>
          </div>
          <input
            className={`${inputClass('name')} name`}
            type="text"
            name="name"
            placeholder="Enter name"
            onChange={(e) => {
              setInputValue({ ...inputValue, name: e.target.value });
              handleValidate('name', e.target.value);
            }}
          />
          <span className="text error">{inputError.name}</span>
          <div className="label-container">
            <div className="label-props">
              <FontAwesomeIcon className="icon" icon={faEnvelope} />
              <label htmlFor="email">email</label>
            </div>
          </div>
          <input
            className={`${inputClass('email')} email`}
            type="email"
            name="email"
            placeholder="Enter email"
            onChange={(e) => {
              setInputValue({ ...inputValue, email: e.target.value });
              handleValidate('email', e.target.value);
            }}
          />
          <span className="text error">{inputError.email}</span>
          <div className="label-container">
            <div className="label-props">
              <FontAwesomeIcon className="icon" icon={faKey} />
              <label htmlFor="password">password</label>
            </div>
          </div>
          <input
            className={`${inputClass('password')} password`}
            type="password"
            name="password"
            placeholder="Enter password"
            onChange={(e) => {
              setInputValue({ ...inputValue, password: e.target.value });
              handleValidate('password', e.target.value);
            }}
          />
          <span className="text error">{inputError.password}</span>
          <div className="label-container">
            <div className="label-props">
              <FontAwesomeIcon className="icon" icon={faKey} />
              <label htmlFor="confirmPassword">confirm password</label>
            </div>
          </div>
          <input
            className={`${inputClass('confirmPassword')} confirm-password`}
            type="password"
            name="confirmPassword"
            placeholder="Enter password"
            onChange={(e) => {
              e.preventDefault();
              setInputValue({ ...inputValue, confirmPassword: e.target.value });
              handleValidate('confirmPassword', e.target.value);
            }}
          />
          <span className="text error">{inputError.confirmPassword}</span>
          <div className="menu-buttons">
            <button type="button" onClick={() => onCancel(false)}>
              Cancel
            </button>
            <button
              type="submit"
              disabled={!validated}
              onClick={() => onSubmit(false)}
            >
              Submit
            </button>
          </div>
          <div className="sign-in-container">
            <span className="sign-in text">Already have an account?</span>
            <button type="button" onClick={() => signIn(true)}>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Register;
