import type PermissionType from '../../models/permission';
import type { ReducerType, StateType } from '../../common/state/types';
import { initialState } from '../../common/state/utils/constant';
import { actionResponseMap } from '../../common/state/types';
import {
  dataListLoadedReducer,
  dataFailedReducer,
} from '../../common/state/reducers';

const permissionReducer: ReducerType<PermissionType> = (
  state = {
    active: initialState as StateType<PermissionType>,
    rollback: initialState as StateType<PermissionType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.PERMISSION_DATALIST_LOADED:
      return dataListLoadedReducer<PermissionType>({
        state,
        action,
      });
    case actionResponseMap.PERMISSION_DATA_FAILED:
      return dataFailedReducer<PermissionType>({
        state,
        action,
      });
    default:
      return state;
  }
};

export default permissionReducer;
