import _ from 'lodash';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  faFilter,
  faSquarePlus,
  faTrash,
  faPencil,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DataGrid,
  EmptyDataGrid,
  HeaderRenderer,
  FilterContext,
  FilterFlagContext,
  pager,
} from '../../../components/DataGrid/DataGrid';
import { isNothing } from '../../../common/utils';
import { GenericObjType, FilterType } from '../../../common/types';
import {
  loadBranches,
  addBranch,
  updateBranchById,
  removeBranchById,
} from '../../../state/branch/branchActions';
import { loadFileById } from '../../../state/file/fileActions';
import { RootState } from '../../../store';
import type BranchType from '../../../models/branch';
import './branch.css';
import AddDialog from './dialog/add/Add';
import UpdateDialog from './dialog/update/Update';
import RemoveDialog from './dialog/remove/Remove';
import Paginate from '../../../components/Paginate/Paginate';

const Branch = ({
  fileId,
  params,
  onSelect,
  selectedRowFilter,
  onFilter = () => {},
  searchParams = {},
}: {
  fileId: number;
  params?: { [k: string]: any };
  onSelect?: (selectedData: BranchType) => void;
  selectedRowFilter?: Partial<{ [k in keyof BranchType]: any }>;
  searchParams?: GenericObjType;
  onFilter?: (a: GenericObjType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const baseSearchParams = useMemo(() => searchParams || {}, [searchParams]);
  const dispatch = useDispatch();
  const branches = useSelector((state: RootState) => state.branch.active.data);
  const branchesReload = useSelector(
    (state: RootState) => state.branch.active.flags.reload
  );
  const branchesMeta = useSelector(
    (state: RootState) => state.branch.active.meta
  );
  const branchesMetaList = useSelector(
    (state: RootState) => state.branch.active.metaList
  );
  const [selectedRow, setSelectedRow] = useState<BranchType | undefined>(
    undefined
  );
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [forcePage, setForcePage] = useState(0);
  const [selectedPageNum, setSelectedPageNum] = useState(0);
  const [selectedPageRowNum, setSelectedPageRowNum] = useState(0);

  const handleOnSelect = (selectedData: BranchType) => {
    if (_.isEmpty(selectedData)) return;
    if (!onSelect) return;
    onSelect(selectedData);
  };

  const columns = [
    {
      key: 'rowNum',
      name: 'Row',
      width: 50,
    },
    {
      key: 'name',
      name: 'Branch',
      width: 200,
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'name'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
    },
    {
      key: 'status',
      name: 'Status',
      width: 100,
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'status'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
    },
    {
      key: 'statusMessage',
      name: 'Status Message',
      width: 150,
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={'statusMessage'}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
    },
    {
      key: 'action',
      name: 'Action',
      width: 100,
      renderCell: ({ row }: { row: BranchType }) => {
        return (
          <div className="grid-action">
            <button
              className="row-button"
              onClick={() => setOpenRemoveDialog(true)}
              disabled={row.name === 'master'}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
            <button
              className="row-button"
              onClick={() => setOpenUpdateDialog(true)}
              disabled={row.name === 'master'}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
            <button className="row-button" onClick={() => handleOnSelect(row)}>
              <FontAwesomeIcon icon={faFolderOpen} />
            </button>
          </div>
        );
      },
    },
  ];

  const [filterFlag, setFilterFlag] = useState(!isNothing(searchParams));
  const [filters, setFilters] = useState(baseSearchParams);

  const handleAddRecords = (data: BranchType) => {
    dispatch(addBranch(fileId, data));
  };

  const handleUpdateRecords = (data: BranchType) => {
    if (selectedRow) {
      const branchId = Number(selectedRow?.id);
      dispatch(updateBranchById(fileId, branchId, data));
    }
  };

  const handleRemoveRecords = () => {
    if (selectedRow) {
      const branchId = Number(selectedRow?.id);
      dispatch(removeBranchById(fileId, branchId, selectedRow));
    }
  };

  const debouncedOnFilter = useRef(
    _.debounce((currentFilters) => {
      dispatch(
        loadBranches(fileId, {
          ...baseParams,
          where: { ...currentFilters, ...baseParams.where },
        })
      );
    }, 1000)
  ).current;

  const handleFilter = (currentFilters: FilterType<BranchType>) => {
    setFilters(currentFilters);
    debouncedOnFilter(currentFilters);
    onFilter(currentFilters);
  };

  const handleToggleFilter = (isEnabled: boolean) => {
    const currentFilters = isEnabled ? filters : {};
    setFilterFlag(isEnabled);
    handleFilter(currentFilters);
  };

  const handleCellSelection = (data: BranchType) => {
    setSelectedRow(data);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    const currentPageNum = currentPageInx + 1;
    const pageStartRowNum = pager.detectPageStartRowNum(
      currentPageNum,
      branchesMetaList
    );
    setSelectedPageNum(currentPageNum);
    setSelectedPageRowNum(pageStartRowNum);
  };

  const loadPage = useCallback(
    (pageInx: number) => {
      const currentPage = pageInx + 1;
      const pageMeta = _.find(branchesMetaList, { currentPage });
      if (!_.isEmpty(pageMeta)) return pageMeta;
      dispatch(
        loadBranches(fileId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          page: currentPage,
        })
      );
      return pageMeta;
    },
    [dispatch, fileId, baseParams, branchesMetaList, filters]
  );

  useEffect(() => {
    if (branchesReload) {
      dispatch(
        loadBranches(fileId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
        })
      );
    }
  }, [dispatch, fileId, baseParams, filters, branchesReload]);

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(
      loadBranches(fileId, {
        ...baseParams,
        where: { ...filters, ...baseParams.where },
      })
    );
  }, [dispatch, fileId, baseParams, filters]);

  const isEmptyGrid = columns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="branch-container">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="branch-container">
      <FilterFlagContext.Provider value={filterFlag}>
        <FilterContext.Provider value={filters}>
          <div className="grid-toolbar">
            <div className="left-toolbar">
              <button type="button" onClick={() => setOpenAddDialog(true)}>
                <FontAwesomeIcon icon={faSquarePlus} />
                <span className="add icon-label">Add</span>
              </button>
            </div>
            <div className="center-toolbar">
              <Paginate
                totalPages={Number(branchesMeta?.totalPages)}
                onPageClick={handlePageClick}
                forcePage={forcePage}
              />
            </div>
            <div className="right-toolbar">
              <button
                className={`filterable ${filterFlag ? 'selected' : ''}`}
                onClick={() => handleToggleFilter(!filterFlag)}
              >
                <FontAwesomeIcon icon={faFilter} />
              </button>
            </div>
          </div>
          <DataGrid<BranchType>
            rowKeyGetter={(row) => String(row.id)}
            columns={columns}
            rows={branches}
            rowsMetaList={branchesMetaList}
            rowHeight={30}
            onCellSelection={handleCellSelection}
            onPageChange={setForcePage}
            loadPage={loadPage}
            selectedPageNum={selectedPageNum}
            selectedPageRowNum={selectedPageRowNum}
            selectedRowFilter={selectedRowFilter}
          ></DataGrid>
          {openAddDialog ? (
            <AddDialog
              open={openAddDialog}
              setOpen={setOpenAddDialog}
              onSubmit={handleAddRecords}
            ></AddDialog>
          ) : null}
          {openUpdateDialog ? (
            <UpdateDialog
              open={openUpdateDialog}
              setOpen={setOpenUpdateDialog}
              selectedRow={selectedRow}
              onSubmit={handleUpdateRecords}
            ></UpdateDialog>
          ) : null}
          {openRemoveDialog ? (
            <RemoveDialog
              open={openRemoveDialog}
              setOpen={setOpenRemoveDialog}
              selectedRow={selectedRow}
              onSubmit={handleRemoveRecords}
            ></RemoveDialog>
          ) : null}
        </FilterContext.Provider>
      </FilterFlagContext.Provider>
    </div>
  );
};

export default Branch;
