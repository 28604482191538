import type DraftColumnType from '../../../models/draft-column';
import type { ReducerType, StateType } from '../../../common/state/types';
import { initialState } from '../../../common/state/utils/constant';
import { actionResponseMap } from '../../../common/state/types';
import {
  dataListLoadedReducer,
  dataFailedReducer,
} from '../../../common/state/reducers';

const draftColumnReducer: ReducerType<DraftColumnType> = (
  state = {
    active: initialState as StateType<DraftColumnType>,
    rollback: initialState as StateType<DraftColumnType>,
  },
  action
) => {
  switch (action.type) {
    case actionResponseMap.DRAFTCOLUMNMERGEDIFF_DATALIST_LOADED:
      return dataListLoadedReducer<DraftColumnType>({
        state,
        action,
      });
    case actionResponseMap.DRAFTCOLUMNMERGEDIFF_DATA_FAILED:
      return dataFailedReducer<DraftColumnType>({
        state,
        action,
      });
    default:
      return state;
  }
};

export default draftColumnReducer;
