import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateUUID } from '../../../../../common/utils';
import { GenericObjType } from '../../../../../common/types';
import { loadDraftColumns } from '../../../../../state/draft-column/draftColumnActions';
import { loadFileById } from '../../../../../state/file/fileActions';
import { loadBranchById } from '../../../../../state/branch/branchActions';
import { loadDraftTableById } from '../../../../../state/draft-table/draftTableActions';
import { RootState } from '../../../../../store';
import Params from '../../../components/params/Params';

const DraftRowParams = ({
  fileId,
  branchId,
  tableId,
  onChange,
}: {
  fileId: number;
  branchId: number;
  tableId: number;
  onChange?: React.Dispatch<React.SetStateAction<GenericObjType>>;
}) => {
  const cachePrefix = 'connect-draft-row-params';
  const deriveCacheKey = useCallback(() => {
    const cacheBase = generateUUID({ fileId, branchId, tableId }, [
      'fileId',
      'branchId',
      'tableId',
    ]);
    const cacheKey = `${cachePrefix}-${cacheBase}`;
    return cacheKey;
  }, [fileId, branchId, tableId]);

  const dispatch = useDispatch();
  const draftColumns = useSelector(
    (state: RootState) => state.draftColumn.active.data
  );
  const draftColumnsValue = draftColumns.map((draftColumnData) => {
    const { id, rowNum, name, type } = draftColumnData;
    return { id, rowNum, name, type, value: '' };
  });

  const draftColumnsReload = useSelector(
    (state: RootState) => state.draftColumn.active.flags.reload
  );
  const branchesReload = useSelector(
    (state: RootState) => state.branch.active.flags.reload
  );
  const draftColumnsMetaList = useSelector(
    (state: RootState) => state.draftColumn.active.metaList
  );

  useEffect(() => {
    if (branchesReload) {
      dispatch(loadBranchById(fileId, branchId));
    }
  }, [dispatch, fileId, branchId, branchesReload]);

  useEffect(() => {
    if (draftColumnsReload) {
      dispatch(loadDraftColumns(fileId, branchId, tableId));
    }
  }, [dispatch, fileId, branchId, tableId, draftColumnsReload]);

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadBranchById(fileId, branchId));
    dispatch(loadDraftTableById(fileId, branchId, tableId));
    dispatch(loadDraftColumns(fileId, branchId, tableId));
  }, [dispatch, fileId, branchId, tableId]);

  return (
    <Params
      rows={draftColumnsValue}
      rowsMetaList={draftColumnsMetaList}
      deriveCacheKey={deriveCacheKey}
      onChange={onChange}
    ></Params>
  );
};

export default DraftRowParams;
