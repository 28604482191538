import axios from 'axios';
import _ from 'lodash';
import { v5 as uuidv5 } from 'uuid';
import {
  GenericObjType,
  Nullable,
  PageResponseType,
  EmptyType,
} from '../types';
import qs from 'qs';

const setAuthToken = (token: string | undefined) => {
  if (!_.isNil(token) && token !== '') {
    axios.defaults.headers.common['x-auth-token'] = token;
    localStorage.setItem('token', token);
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
    localStorage.removeItem('token');
  }
};

const setLoginContext = (context: string | undefined) => {
  if (!_.isNil(context) && context !== '') {
    axios.defaults.headers.common['x-login-context'] = context;
    localStorage.setItem('context', context);
  } else {
    delete axios.defaults.headers.common['x-login-context'];
    localStorage.removeItem('context');
  }
};

const setLoginGroup = (groupId: number | undefined) => {
  if (groupId) {
    axios.defaults.headers.common['x-login-group'] = groupId;
    localStorage.setItem('group', String(groupId));
  } else {
    delete axios.defaults.headers.common['x-login-group'];
    localStorage.removeItem('group');
  }
};

const isNothing = (value: any) => {
  if (typeof value === 'undefined') return true;
  if (value === 'undefined') return true;
  if (value === '') return true;
  if (_.isNaN(value)) return true;
  if (_.isNil(value)) return true;
  if (!_.isNumber(value) && _.isEmpty(value)) return true;
  return false;
};

const generateFields = (prefixType: string, count = 5) => {
  if (count >= 1000) throw new Error('Text field exceeds limit');
  const textFields = [];
  for (let i = 1; i <= count; i += 1) {
    const prefixZero = _.repeat('0', 3 - _.toString(i).length);
    const fieldNum = `${prefixZero}${i}`;
    const fieldName = `${prefixType}_${fieldNum}`;
    const textField = { id: fieldName, value: fieldName };
    textFields.push(textField);
  }
  return textFields;
};

const generateFieldValues = () => {
  const textFields = generateFields('text', 100);
  const intFields = generateFields('int', 20);
  const decimalFields = generateFields('decimal', 30);
  const dateFields = generateFields('date', 10);

  const fieldList = [
    ...textFields,
    ...intFields,
    ...decimalFields,
    ...dateFields,
  ];
  const fieldValues = fieldList.map((item) => ({ map: item.value }));
  return fieldValues;
};

const upsertOnSortedArray = <T extends object>(
  sortedArray: T[],
  sortedArrayToInsert: T[],
  sortedKey: keyof T
): T[] => {
  // find starting index for insertion
  let startIndex = 0;
  while (
    startIndex < sortedArray.length &&
    sortedArray[startIndex]?.[sortedKey] < sortedArrayToInsert[0]?.[sortedKey]
  ) {
    startIndex++;
  }
  // insert elements sequentially
  let insertIndex = startIndex;
  sortedArrayToInsert.forEach((element) => {
    const existingIndex = sortedArray.findIndex(
      (item) => item?.[sortedKey] === element?.[sortedKey]
    );
    if (existingIndex !== -1) {
      // Replace the existing element with the new element
      sortedArray[existingIndex] = element;
    } else {
      // Insert the new element
      sortedArray.splice(insertIndex, 0, element);
      insertIndex++;
    }
  });
  // seems throwing an error when not expanded
  return [...sortedArray];
};

const delay = (timeout: number) => {
  new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

const attachHeaders = (payload: any) => {
  const headers: GenericObjType = {};
  const headersList = ['x-auth-token', 'x-login-context', 'x-login-group'];
  headers['Content-type'] = 'application/json';
  headersList.forEach((header) => {
    if (_.has(axios.defaults.headers.common, header)) {
      headers[header] = axios.defaults.headers.common[header];
    }
  });
  return { headers, payload };
};

const parseJson = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const parseDiff = (rowValue: any) => {
  const resultValue = { isDiff: false, value: rowValue };
  if (isNothing(rowValue)) return resultValue;
  const rowValueStr =
    typeof rowValue !== 'string' ? JSON.stringify(rowValue) : rowValue;
  const hasAction = rowValueStr.includes('action');
  if (!hasAction) return resultValue;
  const rowObj = parseJson(rowValueStr);
  const isObject = _.isObject(rowObj);
  const hasKey = isObject && _.has(rowObj, 'action');
  if (!isObject || !hasKey) return resultValue;
  return { isDiff: true, value: rowObj };
};

const generateUUID = (obj: object, columns: string[]) => {
  const baseDNSPowerUUID = 'd7e1a5e4-2d5a-546f-a1a3-2fdf0cb4d3c3';
  const emptyString = '';
  const extraSpace = ' ';
  const normAllSpace = (value: string) => _.replace(value, /\s+/g, extraSpace);
  const normDash = (value: string) => _.replace(value, /-/g, extraSpace);
  const normExtraSpace = (value: string) =>
    _.replace(value, /^\s+|\s+$/g, emptyString);
  const normNil = (value: unknown): string => {
    if (_.isNil(value)) return emptyString;
    return _.toString(value);
  };
  const normStringLower = (value: string) => _.toString(value).toLowerCase();

  // order is important here
  const keyValuesList = columns.map((columnName) => {
    const normalize = _.flow([
      normNil,
      normStringLower,
      normDash,
      normAllSpace,
      normExtraSpace,
    ]);
    const originalValue = _.get(obj, columnName, emptyString);
    const normKey = normalize(columnName);
    const normValue = normalize(originalValue);
    return `${normKey}${normValue}`;
  });
  const keyValuesString = keyValuesList.join(emptyString);
  return uuidv5(keyValuesString, baseDNSPowerUUID);
};

const timeSince = (date: Nullable<Date | string>): string => {
  if (!date) return '';
  const targetDate = typeof date === 'string' ? new Date(date) : date;
  const seconds: number = Math.floor(
    (new Date().getTime() - targetDate.getTime()) / 1000
  );
  let interval: number = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
};

const parseParams = (rawParams: GenericObjType | URLSearchParams) => {
  if (rawParams instanceof URLSearchParams) {
    const targetJson = qs.parse(rawParams.toString());
    const searchParams = Object.entries(targetJson).reduce(
      (result: GenericObjType, [key, filterObj]) => {
        const filterObjList = Object.entries(filterObj as GenericObjType);
        const [op, val] = filterObjList[0];
        if (val === 'true' || val === 'false') {
          return { ...result, [key]: { [op]: val === 'true' } };
        } else if (!isNaN(Number(val)) && val.trim() !== '') {
          return { ...result, [key]: { [op]: Number(val) } };
        } else {
          return { ...result, [key]: { [op]: val } };
        }
      },
      {}
    );
    return searchParams;
  }

  const params = Object.entries(rawParams).reduce(
    (result: GenericObjType, [key, value]) => {
      if (value === 'true' || value === 'false') {
        return { ...result, [key]: value === 'true' };
      } else if (!isNaN(value) && value.trim() !== '') {
        return { ...result, [key]: Number(value) };
      } else {
        return { ...result, [key]: value };
      }
    },
    {}
  );
  return params;
};

const scrollAndApply = async <T>(
  service: (...params: any) => Promise<PageResponseType<T>>,
  params: number[],
  query: { [k: string]: any } = {},
  callback: (response: PageResponseType<T>) => Promise<void>
) => {
  let page = query?.page || 1;
  const size = query?.size;
  let response: PageResponseType<T> | EmptyType = {};
  let keepGoing = true;

  /* eslint-disable no-await-in-loop */
  while (keepGoing) {
    if (!_.isEmpty(response?.meta)) {
      page = response?.meta?.currentPage + 1;
    }
    const queryParams = { ...query, page, size };
    response = await service(...params, queryParams);
    const { data, meta } = response;
    if (!_.isEmpty(data)) {
      await callback(response as PageResponseType<T>);
    }
    if (_.isEmpty(data)) keepGoing = false;
    if (_.isEmpty(meta)) keepGoing = false;
    if (meta?.currentPage >= meta?.totalPages) keepGoing = false;
  }
  /* eslint-enable no-await-in-loop */
};

const scrollBatch = async <T>(
  promises: (() => Promise<T[]> | Promise<void>)[],
  parallelSize = 3
): Promise<T[]> => {
  const results: T[] = [];
  for (let i = 0; i < promises.length; i += parallelSize) {
    const promiseBatches = promises.slice(i, i + parallelSize).map((f) => f());
    const processedResults = await Promise.all(promiseBatches);
    processedResults.forEach((result) => {
      if (Array.isArray(result)) {
        results.push(...result);
      }
    });
  }
  return results;
};

// assumed nginx client body is set at 8m limit
// set limit to 5mb * 1024kb/m * 1024bytes/kb
const scrollChunk = <T>(
  jsonArray: T[],
  maxCharactersPerBatch = (1024 * 1024) / 2
) => {
  const batches = [];
  let currentBatch: T[] = [];
  let currentBatchSize = 0;
  jsonArray.forEach((jsonObj) => {
    const jsonString = JSON.stringify(jsonObj);
    const jsonObjSize = jsonString.length;
    if (currentBatchSize + jsonObjSize <= maxCharactersPerBatch) {
      currentBatch.push(jsonObj);
      currentBatchSize += jsonObjSize;
    } else {
      batches.push(currentBatch);
      currentBatch = [jsonObj];
      currentBatchSize = jsonObjSize;
    }
  });
  if (currentBatch.length > 0) {
    batches.push(currentBatch);
  }
  return batches;
};

export {
  setAuthToken,
  setLoginGroup,
  setLoginContext,
  isNothing,
  generateFields,
  generateFieldValues,
  upsertOnSortedArray,
  delay,
  attachHeaders,
  parseJson,
  parseDiff,
  generateUUID,
  timeSince,
  parseParams,
  scrollBatch,
  scrollChunk,
  scrollAndApply,
};
