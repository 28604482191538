import _ from 'lodash';
import {
  faPencil,
  faFilter,
  faSquarePlus,
  faSquareMinus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataGrid,
  textEditorNorm,
  SelectColumn,
  EmptyDataGrid,
  deriveColumnProps,
  createBlankRecords,
  pager,
  HeaderRenderer,
  FilterContext,
  FilterFlagContext,
} from '../../../components/DataGrid/DataGrid';
import { GenericObjType, FilterType } from '../../../common/types';
import { parseDiff, isNothing } from '../../../common/utils';
import { loadDraftColumns } from '../../../state/draft-column/draftColumnActions';
import {
  addDraftRows,
  loadDraftRows,
  removeDraftRows,
  updateDraftRows,
} from '../../../state/draft-row/draftRowActions';
import { loadFileById } from '../../../state/file/fileActions';
import { loadBranchById } from '../../../state/branch/branchActions';
import { loadDraftTableById } from '../../../state/draft-table/draftTableActions';
import { RootState } from '../../../store';
import type DraftRowType from '../../../models/draft-row';
import Paginate from '../../../components/Paginate/Paginate';
import DiffText from '../../../components/DiffText';
import './draftRow.css';

const DraftRow = ({
  fileId,
  branchId,
  tableId,
  params,
  onFilter = () => {},
  searchParams = {},
}: {
  fileId: number;
  branchId: number;
  tableId: number;
  params?: { [k: string]: any };
  searchParams?: GenericObjType;
  onFilter?: (a: GenericObjType) => void;
}) => {
  const baseParams = useMemo(() => params || {}, [params]);
  const baseSearchParams = useMemo(() => searchParams || {}, [searchParams]);
  const dispatch = useDispatch();
  const rows = useSelector((state: RootState) => state.draftRow.active.data);
  const rowsReload = useSelector(
    (state: RootState) => state.draftRow.active.flags.reload
  );
  const branchesReload = useSelector(
    (state: RootState) => state.branch.active.flags.reload
  );
  const rowsMetaList = useSelector(
    (state: RootState) => state.draftRow.active.metaList
  );
  const rowsMeta = useSelector(
    (state: RootState) => state.draftRow.active.meta
  );
  const draftColumns = useSelector(
    (state: RootState) => state.draftColumn.active.data
  );
  const [selectedRows, setSelectedRows] = useState((): DraftRowType[] => []);
  const hasSelectedRows = !_.isEmpty(selectedRows);

  const columnProps = deriveColumnProps(draftColumns);

  const [filterFlag, setFilterFlag] = useState(!isNothing(searchParams));
  const [filters, setFilters] = useState(baseSearchParams);

  const [editable, setEditable] = useState(false);
  const [forcePage, setForcePage] = useState(0);
  const [selectedPageNum, setSelectedPageNum] = useState(0);
  const [selectedPageRowNum, setSelectedPageRowNum] = useState(0);

  const userColumns = columnProps.map((column) => {
    return {
      ...column,
      resizable: true,
      editable: editable,
      renderEditCell: (props: any) => {
        const rowValue = props.row[column.key as keyof DraftRowType];
        const rowDiff = parseDiff(rowValue);
        if (!rowDiff.isDiff) return textEditorNorm(props);
        return textEditorNorm({
          ...props,
          row: {
            ...props.row,
            [column.key]: `${rowDiff.value.before} ${rowDiff.value.after}`,
          },
        });
      },
      headerCellClass: 'filter-cell',
      renderHeaderCell: (p: any) => {
        return (
          <HeaderRenderer
            p={p}
            targetColumn={column.key}
            setFilters={handleFilter}
          ></HeaderRenderer>
        );
      },
      renderCell: ({ row }: { row: DraftRowType }) => {
        const rowValue = row[column.key as keyof DraftRowType];
        const rowDiff = parseDiff(rowValue);
        if (!rowDiff.isDiff) return rowDiff.value;
        return (
          <>
            <DiffText keyValue={rowDiff.value}></DiffText>
          </>
        );
      },
    };
  });
  const columns = [
    SelectColumn,
    {
      key: 'rowNum',
      name: 'Row',
      width: 50,
      frozen: true,
      resizable: true,
    },
    {
      key: 'status',
      name: 'Status',
      width: 100,
      frozen: true,
      resizable: true,
    },
    ...userColumns,
  ];

  const handleAddRecords = () => {
    const onLastPage = forcePage === rowsMeta!.totalPages;
    if (!onLastPage) {
      handlePageClick({ selected: rowsMeta!.totalPages - 1 });
    }
    const blankRecords = createBlankRecords(userColumns, 1);
    dispatch(addDraftRows(fileId, branchId, tableId, blankRecords));
  };

  const handleUpdateRecords = (updatedData: DraftRowType[]) => {
    dispatch(updateDraftRows(fileId, branchId, tableId, updatedData));
  };

  const handleRemoveRecords = () => {
    dispatch(removeDraftRows(fileId, branchId, tableId, selectedRows));
    setSelectedRows([]);
  };

  const handleRowSelection = (selectedData: DraftRowType[]) => {
    setSelectedRows(selectedData);
  };

  const debouncedOnFilter = useRef(
    _.debounce((currentFilters) => {
      dispatch(
        loadDraftRows(fileId, branchId, tableId, {
          ...baseParams,
          where: { ...currentFilters, ...baseParams.where },
        })
      );
    }, 1000)
  ).current;

  const handleFilter = (currentFilters: FilterType<DraftRowType>) => {
    setFilters(currentFilters);
    debouncedOnFilter(currentFilters);
    onFilter(currentFilters);
  };

  const handleToggleFilter = (isEnabled: boolean) => {
    const currentFilters = isEnabled ? filters : {};
    setFilterFlag(isEnabled);
    handleFilter(currentFilters);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPageInx = selectedItem.selected;
    const currentPageNum = currentPageInx + 1;
    const pageStartRowNum = pager.detectPageStartRowNum(
      currentPageNum,
      rowsMetaList
    );
    setSelectedPageNum(currentPageNum);
    setSelectedPageRowNum(pageStartRowNum);
  };

  const loadPage = useCallback(
    (pageInx: number) => {
      const currentPage = pageInx + 1;
      const pageMeta = _.find(rowsMetaList, { currentPage });
      if (!_.isEmpty(pageMeta)) return pageMeta;
      dispatch(
        loadDraftRows(fileId, branchId, tableId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          page: currentPage,
        })
      );
      return pageMeta;
    },
    [dispatch, fileId, branchId, tableId, baseParams, rowsMetaList, filters]
  );

  useEffect(() => {
    if (branchesReload) {
      dispatch(loadBranchById(fileId, branchId));
    }
  }, [dispatch, fileId, branchId, branchesReload]);

  useEffect(() => {
    if (rowsReload) {
      dispatch(
        loadDraftRows(fileId, branchId, tableId, {
          ...baseParams,
          where: { ...filters, ...baseParams.where },
          page: forcePage,
        })
      );
    }
  }, [
    dispatch,
    fileId,
    branchId,
    tableId,
    filters,
    baseParams,
    forcePage,
    rowsReload,
  ]);

  useEffect(() => {
    dispatch(loadFileById(fileId));
    dispatch(loadBranchById(fileId, branchId));
    dispatch(loadDraftTableById(fileId, branchId, tableId));
    dispatch(loadDraftColumns(fileId, branchId, tableId));
    dispatch(
      loadDraftRows(fileId, branchId, tableId, {
        ...baseParams,
        where: { ...filters, ...baseParams.where },
      })
    );
  }, [dispatch, fileId, branchId, tableId, baseParams, filters]);

  const isEmptyGrid = columns.every((item) => {
    const onlyColumns = ['rowNum', 'select-row'];
    return onlyColumns.includes(item.key);
  });

  if (isEmptyGrid)
    return (
      <div className="draft-row-container">
        <EmptyDataGrid></EmptyDataGrid>
      </div>
    );
  return (
    <div className="draft-row-container">
      <FilterFlagContext.Provider value={filterFlag}>
        <FilterContext.Provider value={filters}>
          <div className="grid-toolbar">
            <div className="left-toolbar">
              <button
                type="button"
                disabled={!editable}
                onClick={() => handleAddRecords()}
              >
                <FontAwesomeIcon icon={faSquarePlus} />
                <span className="add icon-label">Add</span>
              </button>
              <button
                type="button"
                disabled={!hasSelectedRows || !editable}
                onClick={() => handleRemoveRecords()}
              >
                <FontAwesomeIcon icon={faSquareMinus} />
                <span className="remove icon-label">Remove</span>
              </button>
            </div>
            <div className="center-toolbar">
              <Paginate
                totalPages={Number(rowsMeta?.totalPages)}
                onPageClick={handlePageClick}
                forcePage={forcePage}
              />
            </div>
            <div className="right-toolbar">
              <button
                className={`filterable ${filterFlag ? 'selected' : ''}`}
                onClick={() => handleToggleFilter(!filterFlag)}
              >
                <FontAwesomeIcon icon={faFilter} />
                <span className="filter icon-label">Filter</span>
              </button>
              <button
                className={`editable ${editable ? 'selected' : ''}`}
                onClick={() => setEditable(!editable)}
              >
                <FontAwesomeIcon icon={faPencil} />
                <span className="edit icon-label">Edit</span>
              </button>
            </div>
          </div>
          <DataGrid<DraftRowType>
            rowKeyGetter={(row) => String(row.id)}
            columns={columns}
            rows={rows}
            rowsMetaList={rowsMetaList}
            onPageChange={setForcePage}
            loadPage={loadPage}
            selectedPageNum={selectedPageNum}
            selectedPageRowNum={selectedPageRowNum}
            rowHeight={30}
            onUpdate={handleUpdateRecords}
            onRowSelection={handleRowSelection}
          ></DataGrid>
        </FilterContext.Provider>
      </FilterFlagContext.Provider>
    </div>
  );
};

export default DraftRow;
